import React, { useState, useRef } from "react";
import ReactToPrint from 'react-to-print';
import { Button } from 'devextreme-react/button';
import TextArea from 'devextreme-react/text-area';
import appInfo from '../../app-info';
import { handleErrors,getPermission } from '../configuration/helpers/globalFunctions';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../contexts/auth";
export default () => {
  const {user} = useAuth();
  let token = user ? user.data.token :'';
  let statusURL = `${appInfo.importURL}ImportXREFData/ShowStatus`;
  let importPostURL = `${appInfo.importURL}ImportXREFData/Import`;
  const [statusText, setStatusText] = useState('');
  const componentRef = useRef();
  const position = { of: '#data-grid-demo' };
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const permission = getPermission(user, "ImportExport", "ImportXREFData");

  const getStatus = async () => {
    setLoadPanelVisible(true);
 
      await fetch(statusURL,
        {
          method: 'get',
          headers: { "Authorization": `Bearer ${token}` }
        })
        .then(response => handleErrors(response))
        .then(result => result.json())
        .then(result => {
          let statusMsg = result.message;
          let finalStatusMsg = statusMsg.replaceAll("/r/", "\n");
          setStatusText(finalStatusMsg);
         setLoadPanelVisible(false);
        }).catch(error => {
          console.warn(error);
        })
        .catch((error) => {
      console.error(error, 'import report fail getting status request');
    })
  }
  const importPost = async () => {
    setLoadPanelVisible(true);
      await fetch(importPostURL,
        {
          method: 'post',
          headers: { "Authorization": `Bearer ${token}` }
        })
        .then(response => handleErrors(response))
        .then(result => result.json())
        .then(result => {
          let statusMsg = result.message;
          let finalStatusMsg = statusMsg.replaceAll("/r/", "\n");
          setStatusText(finalStatusMsg);
          setLoadPanelVisible(false);
        })
        .catch((error) => {
          console.warn(error);
        });
  }
  if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
  return (
    <React.Fragment>
     <h2 className='simpleTitle-header-left-side'>Import XREF Data</h2>
      <form className="form" id="data-grid-demo">
          <div className="dx-fieldset">
            <div className="dx-filed">
              <div className='import-description'> 
                Click the 'import' button to Import the latest data into XREF table from Part_XREF_FGSS.
                If you click the 'Show Status' button you can view and print the errors that ocurred during the last import.
              </div>
            </div>
            <div className="dx-field">
              <Button
                text="  Import"
                icon="export"
                onClick={importPost}
              />
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </div>
            <div className="dx-field">
              <Button
                icon="clock"
                text="  Show Status"
                onClick={getStatus}
              />
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </div>
            <div className="dx-field">
              <ReactToPrint trigger={() =>
                <Button
                  text="  Print"
                  icon="print"
                />}
                content={() => componentRef.current}
              />
            </div>
          </div>
          <div className="dx-fieldset">
            <div className="dx-field">
              <TextArea
                className="dx-field-value"
                height={"600px"}
                width={"700px"}
                id="printComponent"
                value={statusText}
                ref={componentRef}
                readOnly={true}
              >
              </TextArea>
            </div>
          </div>
        </form>
      <LoadPanel
        position={position}
        visible={loadPanelVisible}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
    </React.Fragment>
  )
}

