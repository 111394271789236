import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import FORDAlertMasterGrid from "./FORDAlertMasterGrid";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const permission = getPermission(
		user,
		"ConfigurationMasters",
		"FORDAlertMaster"
	);
	const token= user ? user.data.token : '';
	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("0");
	const [customDataSource, setCustomDataSource] = useState(null);

	useEffect(() => {
		let getPlants = `${appInfo.configURL}FORDAlertMaster/Plants`;
		let getActivePlant = `${appInfo.configURL}FORDAlertMaster/ActivePlant`;
		(async () => {
			
				await fetch(getPlants, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customPlant = result.map((plant) => {
							return {
								id: plant.Id,
								value: plant.Id + " - " + plant.Value,
							};
						});
						setPlants(customPlant);
					})
					.catch ((error) => {
						console.error(error ,"Fail requesting get plants");
					})
			
				await axios
					.get(getActivePlant, {
						headers: {Authorization: `Bearer ${token}`},
					})
					.then((result) => {
						let activePlant = result.data;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
						sessionStorage.setItem("currentPlant", activePlant);
					})
					.catch((error) => {
						console.error("Getting active plant failed request:", error);
					})
		})();
	}, []);

	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}FORDAlertMaster?plantid=${e.value}`,
			})
		);

		sessionStorage.setItem("currentPlant", e.value);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>FORD Alert Master</h2>
					</div>
				</div>
				<div className="header-right-side">
					<SelectBox
						elementAttr={{
							id: "FORDAlertMasterSelector",
						}}
						placeholder="Plant Code"
						items={plants}
						onValueChanged={onValueChanged}
						value={currentPlant}
						displayExpr="value"
						valueExpr="id"
					/>
				</div>
				<FORDAlertMasterGrid
					customDataSource={customDataSource}
					plantId={currentPlant}
				/>
			</div>
		</React.Fragment>
	);
};
