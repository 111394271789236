import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import PartMappingMasterGrid from "./PartMappingMasterGrid";
import CustomStore from "devextreme/data/custom_store";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"PartMappingMaster"
	);

	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("0");
	const [customDataSource, setCustomDataSource] = useState(null);
	const [sourceShortPartNo, setSourceShortPartNo] = useState(null);
	const [destSEWSPartNo, setDestSEWSPartNo] = useState(null);

	const isNotEmpty = (value) =>
		value !== undefined && value !== null && value !== "";

	useEffect(() => {
		let getPlants = `${appInfo.configURL}PartMappingMaster/Plants`;
		let getActivePlant = `${appInfo.configURL}PartMappingMaster/ActivePlant`;
		(async () => {
			try {
				await fetch(getPlants, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customPlant = result.map((plant) => {
							return {
								id: plant.Id,
								value: plant.Id + " - " + plant.Value,
							};
						});
						setPlants(customPlant);
					});
			} catch (error) {
				console.error("Fail requesting Part Mapping get plants");
			}
			try {
				await axios
					.get(getActivePlant, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						let activePlant = result.data;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
					});
			} catch (error) {
				console.error(
					"Part Mapping getting active plant failed request:",
					error
				);
			}
		})();
	}, []);

	function sourceShortPartNumLookup(plantId) {
		return {
			paginate: true,
			pageSize: 5,
			store: new CustomStore({
				key: "id",
				load: (loadOptions) => {
					let params = "&";
					[
						"filter",
						"requireTotalCount",
						"searchValue",
						"searchExpr",
						"select",
						"skip",
						"take",
					].forEach(function (i) {
						if (i in loadOptions && isNotEmpty(loadOptions[i])) {
							params += `${i}=${JSON.stringify(loadOptions[i])}&`;
						}
					});
					params = params.slice(0, -1);
					return axios
						.get(
							`${appInfo.configURL}PartMappingMaster/SourceShortPartNumbersPaginated?plantId=${plantId}${params}`,
							{headers: {Authorization: `Bearer ${token}`}}
						)
						.then((result) => {
							return result.data;
						});
				},
				byKey: (key) => {
					//Do Not Delete By Key method. Deve Express need it not sure for what
				},
				insert: (value) => {
					return new Promise(function (resolve, reject) {
						resolve(value);
					});
				},
				errorHandler: (error) => {
					console.warn(error.message);
				},
			}),
		};
	}
	function destShortPartNumLookup(plantId) {
		return {
			paginate: true,
			pageSize: 5,
			store: new CustomStore({
				key: "id",
				load: (loadOptions) => {
					let params = "&";
					[
						"filter",
						"requireTotalCount",
						"searchValue",
						"searchExpr",
						"skip",
						"take",
					].forEach(function (i) {
						if (i in loadOptions && isNotEmpty(loadOptions[i])) {
							params += `${i}=${JSON.stringify(loadOptions[i])}&`;
						}
					});
					params = params.slice(0, -1);
					return axios
						.get(
							`${appInfo.configURL}PartMappingMaster/DestinationShortPartNumbersPaginated?plantId=${plantId}${params}`,
							{headers: {Authorization: `Bearer ${token}`}}
						)
						.then((result) => {
							return result.data;
						});
				},
				byKey: (key) => {},
				errorHandler: (error) => {
					console.warn(error.message);
				},
			}),
		};
	}
	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}PartMappingMaster?plantid=${e.value}`,
			})
		);
		setSourceShortPartNo(sourceShortPartNumLookup(e.value));
		setDestSEWSPartNo(destShortPartNumLookup(e.value));
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Part Mapping Master</h2>
					</div>
				</div>
				<div className="header-right-side">
					<SelectBox
						elementAttr={{
							id: "XrefSelector",
						}}
						placeholder="Plant Code - Plant Name"
						items={plants}
						onValueChanged={onValueChanged}
						value={currentPlant}
						displayExpr="value"
						valueExpr="id"
					/>
				</div>
				<PartMappingMasterGrid
					customDataSource={customDataSource}
					plantId={currentPlant}
					sourceShortPartNo={sourceShortPartNo}
					destSEWSPartNo={destSEWSPartNo}
				/>
			</div>
		</React.Fragment>
	);
};
