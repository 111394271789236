import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import {Button} from "devextreme-react/button";
import axios from "axios";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import ReturnASNReportGrid from "./ReturnASNReportGrid";
import {alert} from "devextreme/ui/dialog";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	let token = user ? user.data.token :'';
	let formatDate = user ? user.data.userData.formatDate : '';
	const permission = getPermission(user, "MISReports", "ReturnASNReport");
	const [totalCount, setTotalCount] = useState(0);
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const currentDate = new Date();
	/* Instance components in order to reset them when necessary */
	const asnSelectBox = useRef(null);
	const partNumSelectBox = useRef(null);
	const reportRadioBtn = useRef(null);
	const statusRadioBtn = useRef(null);
	const partNumRadioBtn = useRef(null);

	const reportOptions = ["Summary", "Detail"];
	const partNumber = ["Customer", "SEWS"];
	const statusList = ["All", "In Transit", "Received", "Re-inspected"];
	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState();
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [partNumSelected, setPartNumberSelected] = useState(partNumber[0]);
	const [statusSelected, setstatusSelected] = useState(statusList[0]);
	const [ASNNum, setASSNum] = useState(null);
	const [SEWSNum, setSEWSNum] = useState(null);
	const [custPartNumList, setCustPartNumList] = useState();
	const [customDataSource, setCustomDataSource] = useState(null);
	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const [disableFields, setDisableFields] = useState(false);
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);

	const [detailDGColumns, setDetailDGColumns] = useState(false);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [partTitle, setPartTitle] = useState("Cust. Part No.");
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		reportOption: "S",
		sewsPartNo: "",
		ASN: "",
		dateFrom: "",
		dateTo: "",
		inTransitOption: "ALL"
	});
	const reportParamsArr = [
		"plantID",
		"reportOption",
		"sewsPartNo",
		"ASN",
		"dateFrom",
		"dateTo",
		"inTransitOption"
	];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getPlants = `${appInfo.reportURL}ReturnASNReport/Plants`;
		let getSewsPartNum = `${appInfo.reportURL}ReturnASNReport/SEWSPartNumbers`;
		let getASNnum = `${appInfo.reportURL}ReturnASNReport/ASNNumbers`;
		(async () => {
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result)=> handleErrors(result))
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.error(err, "ASN report fail plants request");
				});

				await fetch(getSewsPartNum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						let customSEWS = result.data.map((sews) => {
							return sews.Value;
						});
						setSEWSNum(customSEWS);
					})
					.catch((error) => {
						console.warn(error);
					});
		
				await fetch(getASNnum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => handleErrors(result))
					.then((response) => response.json())
					.then((result) => {
						let customASN = result.data.map((asn) => {
							return asn.Value;
						});
						setASSNum(customASN);
					})
					.catch((error) => {
						console.warn(error);
					});
		})();
	}, []);

	const reportOptionOnChange = (e) => {
		e.promise = setReportOptionSelected(e.value);

		if (e.value == "Summary") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "S"};
			});
			setShowDGColumns(false);
		} else {
			//turn on flag to show extra columns
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "D"};
			});
		}
	};

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		if (reportDetail.dateFrom === null && reportDetail.dateTo === null) {
			alert("[20265] Date from and Date to Cannot Be Blank.");
			return;
		} else {
			let params = "?";
			reportParamsArr.forEach(function (i) {
				if (i in reportDetail && isNotEmpty(reportDetail[i])) {
					params += `${i}=${JSON.stringify(reportDetail[i])}&`;
				}
			});
			params = params.replace(/"/g, "");
			params = params.slice(0, -1);

			setCustomDataSource(
				store({
					url: `${appInfo.reportURL}ReturnASNReport${params}`
				})
			);
			setShowTooltip(false);
			setDisableFields(true);
			setdisableSearchBtn(true);
			setfirstTimeLoading(false);
		}
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "mfgPlant":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, plantID: currentFieldVal};
					});
				}
				break;
			case "shipmentDateFrom":
				console.log(currentFieldVal);
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + 1000 * 3600 * 24 * 89;
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);

				/* Validate the following scenarios: 
                        - FROM date lower than TO date
                        - FROM date <= maxDate
                        - Date range not grather than 90 days
                    */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}
				if (currentFieldVal !== "" || currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "shipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");

				/* Validate the following scenarios: 
                        - TO date grather than FROM date
                        - TO date >= minDate
                        - Date range not grather than 90 days
                    */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);
				if (daysPast_ < -1 || daysPast_ > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "" || currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
			case "shipmentASN":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				}
				break;
			case "partNumberSelect":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, sewsPartNo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, sewsPartNo: currentFieldVal};
					});
				}
				break;
			case "status":
				if (currentFieldVal !== "") {
					setstatusSelected(currentFieldVal);
					if (currentFieldVal == "Received") {
						setReportDetail((oldValues) => {
							return {...oldValues, inTransitOption: "R"};
						});
					} else if (currentFieldVal == "In Transit") {
						setReportDetail((oldValues) => {
							return {...oldValues, inTransitOption: "I"};
						});
					} else if (currentFieldVal == "Received") {
						setReportDetail((oldValues) => {
							return {...oldValues, inTransitOption: "R"};
						});
					} else if (currentFieldVal == "Re-inspected") {
						setReportDetail((oldValues) => {
							return {...oldValues, inTransitOption: "RI"};
						});
					} else if (currentFieldVal == "All") {
						setReportDetail((oldValues) => {
							return {...oldValues, inTransitOption: "ALL"};
						});
					}
				}
				break;
		}
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		setTotalCount(0);
		setCustomDataSource(null);
		setShowTooltip(true);
		setDisableFields(false);
		setdisableSearchBtn(false);
	};
	const onInitialized = (e) => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate + 1000 * 3600 * 24 * 91;
		let maxDate = new Date();
		maxDate.setTime(maxTime);
		setMaxToDate(maxDate);
		setMinToDate(currentDate);
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateFrom: dateFromBox.current.instance
					.option("value")
					.toISOString()
					.split("T")[0]
					.replace(/-/g, "/")
			};
		});
	};
	const onInitializedDateTo = (e) => {
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateTo: dateToBox.current.instance
					.option("value")
					.toISOString()
					.split("T")[0]
					.replace(/-/g, "/")
			};
		});
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	const getSelectValues = async (partType) => {
		let partRequestURL;
		if (partType === "Customer") {
			partRequestURL = `${appInfo.reportURL}ReturnASNReport/CustPartNumbers`;
		} else {
			partRequestURL = `${appInfo.reportURL}ReturnASNReport/SEWSPartNumbers`;
		}
		await axios
			.get(partRequestURL, {headers: {Authorization: `Bearer ${token}`}})
			.then((result) => {
				let customPartNum;
				customPartNum = result.data.map((partNum) => {
					return partNum.value;
				});
				setCustPartNumList(customPartNum);
			})
			.catch((error) => {
				console.warn(error);
			});
	};

	const partNumInit = () => {
		getSelectValues(partNumSelected); //Send the default value = Customer
	};

	const partNumOnValueChange = (e) => {
		e.promise = setPartNumberSelected(e.value);
		e.promise = getSelectValues(e.value); //Make sure to send the correct value
		partNumSelectBox.current.instance.reset();
		setShowTooltip(true);

		if (e.value == "SEWS") {
			setPartTitle("SEWS Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "s"};
			});
		} else {
			setPartTitle("Cust. Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "c"};
			});
		}
	};
	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Return ASN Report</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								layout='horizontal'
								onValueChanged={reportOptionOnChange}
								ref={reportRadioBtn}
								disabled={disableFields}
							/>
						</div>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

						<div className='dx-field'>
							<div className='dx-labels'>Part Number</div>
							<RadioGroup
								items={partNumber}
								value={partNumSelected}
								onValueChanged={partNumOnValueChange}
								onInitialized={partNumInit}
								layout='horizontal'
								disabled={disableFields}
								ref={partNumRadioBtn}
							/>
						</div>
						<span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</span>

						<div className='dx-field'>
							<div className='dx-labels'></div>
							<RadioGroup
								elementAttr={{
									id: "status"
								}}
								items={statusList}
								value={statusSelected}
								layout='horizontal'
								ref={statusRadioBtn}
								onValueChanged={setReportParams}
								disabled={disableFields}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "incompleteShipmentSelect"
								}}
								placeholder='Plant Code - Plant Name'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFields}
								value={currentPlant}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder={partTitle}
								elementAttr={{
									id: "partNumberSelectRenturnAsn"
								}}
								ref={partNumSelectBox}
								showClearButton={true}
								onValueChanged={setReportParams}
								searchEnabled={true}
								items={SEWSNum}
								width='165px'
								disabled={disableFields}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='ASN No.'
								elementAttr={{
									id: "returnASN"
								}}
								onValueChanged={setReportParams}
								ref={asnSelectBox}
								width='160px'
								searchEnabled={true}
								items={ASNNum}
								disabled={disableFields}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								displayFormat={formatDate}
								width='150px'
								disabled={disableFields}
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={currentDate}
								onInitialized={onInitialized}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateTo"
								}}
								ref={dateToBox}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								dateSerializationFormat='yyyy/MM/dd'
								max={maxToDate}
								disabled={disableFields}
								min={minToDate}
								width='140px'
								onInitialized={onInitializedDateTo}></DateBox>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#findButtonInTransit' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button
								icon='clear'
								elementAttr={{
									id: "ANScancelButton"
								}}
								onClick={clearFilters}
							/>
						</div>
					</div>
				</form>

				<ReturnASNReportGrid
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
					detailDGColumns={detailDGColumns}
				/>
			</div>
		</React.Fragment>
	);
};
