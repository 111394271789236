import React, { useState, useEffect, useRef } from 'react';
import SelectBox from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';
import appInfo from '../../app-info';

let token = '';
if (sessionStorage.getItem("currentUser") !== null) {
  let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  token = currentUser.data.token;
}
const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

const ASNstore = new DataSource({
  key: "Id",
  load: (loadOptions) => {
    let params = '&';
    [
      'skip',
      'take',
      'searchExpr',
      'searchValue',
    ].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);
    return fetch(`${appInfo.reportURL}ReturnProductsReport/ASNNumbers?${params}`, {
      method: 'get',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
      .then(response => {
        return response.data;
      });
  }, byKey: (key) => {
    //Do Not Delete By Key method. Deve Express need it not sure for what
  },
});

export default ({ disableFilters, callbackSelectBoxAsn }) => {
  const asn = useRef();
  useEffect(() => {
    window.addEventListener('resetFilters', () => {
      asn.current.instance.reset();
    })
  }, []);
  const onValueChanged = (e) => {
    callbackSelectBoxAsn(e.value);
  }
  return (
    <SelectBox
      placeholder="ASN No."
      elementAttr={{
        id: 'returnShipmentASN'
      }}
      ref={asn}
      onValueChanged={onValueChanged}
      dataSource={ASNstore}
      showClearButton={true}
      valueExpr="Value"
      width='160px'
      searchEnabled={true}
      disabled={disableFilters}
      displayExpr="Value"
    />
  )
}