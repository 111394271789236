import React, {useState, useRef, useCallback} from "react";
import {Link} from "react-router-dom";
import Form, {
	Item,
	RequiredRule,
	Label,
	ButtonItem,
	ButtonOptions,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import {useAuth} from "../../contexts/auth";
import "./login-form.scss";
import {buildMenuTree} from "./build-permissionTree";

export default function () {
	const {signIn} = useAuth();
	const [loading, setLoading] = useState(false);
	const formData = useRef({});

	const onSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			const {username, password} = formData.current;
			const servername = "FGSSCENTRAL";
			const zone = "Central";
			const domain = "NODOMAIN"
			const isFavorite = false;
			const isAdmin = true;
			setLoading(true);
			const result = await signIn(
				username,
				password,
				servername,
				isFavorite,
				zone,
				isAdmin,
				domain
			);

			if (!result.isOk) {
				setLoading(false);
				if (result.message) {
					notify(result.message, "error", 2000);
				}
			}
			if (result.isOk) {
				buildMenuTree(result);
			} else {
				setLoading(false);
			}
		},
		[signIn]
	);

	return (
		<div className="widget-container">
			<form className={"login-form"} onSubmit={onSubmit}>
				<Form formData={formData.current} disabled={loading}>
					<Item
						dataField={"username"}
						editorType={"dxTextBox"}
						editorOptions={userEditorOptions}>
						<RequiredRule message="Username is required" />
						<Label visible={true} />
					</Item>
					<Item
						dataField={"password"}
						editorType={"dxTextBox"}
						editorOptions={passwordEditorOptions}>
						<RequiredRule message="Password is required" />
						<Label visible={false} />
					</Item>
					<Item
						dataField={"isAdmin"}
						editorType={"dxTextBox"}
						editorOptions={isAdminEditorOptions}>
						<Label visible={false} />
					</Item>
					<ButtonItem>
						<ButtonOptions
							width={"100%"}
							type={"default"}
							useSubmitBehavior={true}>
							<span className="dx-button-text">
								{loading ? (
									<LoadIndicator
										width={"24px"}
										height={"24px"}
										visible={true}
									/>
								) : (
									"Login"
								)}
							</span>
						</ButtonOptions>
					</ButtonItem>
					<Item>
						<div className={"link"}>
							<Link to={"/reset-password"}>Forgot password?</Link>
						</div>
					</Item>
					<Item>
						<div className={"link"}>
							<Link to={"/login"}>Back to User Login</Link>
						</div>
					</Item>
				</Form>
			</form>
		</div>
	);
}

const userEditorOptions = {
	stylingMode: "filled",
	placeholder: "Username",
	maskRules: {X: /^[^0-9]+$/},
};
const passwordEditorOptions = {
	stylingMode: "filled",
	placeholder: "Password",
	mode: "password",
};
const adminServer = {
	visible: true,
	value: "FGSSCENTRAL",
	readOnly: true,
};

const isAdminEditorOptions = {
	stylingMode: "filled",
	value: false,
	visible: false,
};
