import React from "react";
import LoadIndicator from "devextreme-react/load-indicator";

export default function IndicatorIcon({isLoaded}) {
	return (
		<span>
			<LoadIndicator visible={!isLoaded} />
			<div className='dx-dropdowneditor-icon' hidden={!isLoaded} />
		</span>
	);
}
