import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import axios from "axios";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import InventoryGridMaster from "./InventoryGridMaster";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [customDataSource, setCustomDataSource] = useState(null);
	const [disablePrintBtn, setDisablePrintBtn] = useState(false);
	let token = user ? user.data.token : '';
	const permission = getPermission(user, "MISReports", "InventoryReport");

	/* Instance components in order to reset them when necessary */

	const locationSelectBox = useRef(null);
	const partNumSelectBox = useRef(null);
	const reportRadioBtn = useRef(null);
	const partNumRadioBtn = useRef(null);

	const reportOptions = ["Summary", "Detail"];
	const partNumber = ["Customer", "SEWS"];
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [partNumSelected, setPartNumberSelected] = useState(partNumber[0]);
	const [custPartNumList, setCustPartNumList] = useState();
	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [locationCodeList, setlocationCodeList] = useState(null);

	const [showDGColumns, setShowDGColumns] = useState(false);
	const [partTitle, setPartTitle] = useState("Cust. Part No.");
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		location: "",
		partNumber: "",
		reportOption: "s",
		partNumberReport: "c"
	});
	const reportParamsArr = ["plantID", "location", "partNumber", "reportOption", "partNumberReport"];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getLocationCodes = `${appInfo.reportURL}InventoryReport/Locations`;
		let getPlants = `${appInfo.reportURL}InventoryReport/Plants`;
		(async () => {
			await fetch(getLocationCodes, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((result) => {
					let customLocation = result.map((location) => {
						return location.value;
					});
					setlocationCodeList(customLocation);
				})
				.catch((error) => {
					console.warn(error);
					console.error("Iventory Report fail get Location codes");
				});
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				});
		})();
	}, []);

	const partNumOnValueChange = (e) => {
		e.promise = setPartNumberSelected(e.value);
		e.promise = getSelectValues(e.value); //Make sure to send the correct value
		partNumSelectBox.current.instance.reset();

		setShowTooltip(true);

		if (e.value == "SEWS") {
			setPartTitle("SEWS Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "s"};
			});
		} else {
			setPartTitle("Cust. Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "c"};
			});
		}
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		setShowTooltip(true);
		setCustomDataSource(null);
		setDisableFilters(false);
	};
	const reportOptionOnChange = (e) => {
		clearFilters();
		e.promise = setReportOptionSelected(e.value);
		if (e.value == "Summary") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "s"};
			});
			setShowDGColumns(false);
		} else {
			// INFO: turn on flag to show columns for "details" option
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "d"};
			});
		}
	};
	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	const partNumInit = () => {
		getSelectValues(partNumSelected); //Send the default value = Customer
	};
	const getSelectValues = async (partType) => {
		let partRequestURL;
		if (partType === "Customer") {
			partRequestURL = `${appInfo.reportURL}InventoryReport/CustumerNumbers`;
		} else {
			partRequestURL = `${appInfo.reportURL}InventoryReport/SEWNumbers`;
		}
		await axios
			.get(partRequestURL, {headers: {Authorization: `Bearer ${token}`}})
			.then((result) => {
				let customPartNum;
				customPartNum = result.data.map((partNum) => {
					return partNum.value;
				});
				setCustPartNumList(customPartNum);
			})
			.catch((error) => {
				console.warn(error);
			});
	};
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);

		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}InventoryReport${params}`
			})
		);
		setShowTooltip(false);
		setfirstTimeLoading(false);
		setDisableFilters(true);
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;
		switch (fieldName) {
			case "LocationSelector":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, location: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, location: ""};
					});
				}
				break;
			case "partNumberSelect":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, partNumber: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, partNumber: ""};
					});
				}
				break;
		}
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Inventory Report</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								layout='horizontal'
								disabled={disableFilters}
								onValueChanged={reportOptionOnChange}
								ref={reportRadioBtn}
							/>
						</div>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<div className='dx-field'>
							<div className='dx-labels'>Part Number</div>
							<RadioGroup
								items={partNumber}
								value={partNumSelected}
								onValueChanged={partNumOnValueChange}
								onInitialized={partNumInit}
								layout='horizontal'
								disabled={disableFilters}
								ref={partNumRadioBtn}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "mfgPlant"
								}}
								placeholder='Plant Code - Plant Name'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={currentPlant}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Location Code'
								elementAttr={{
									id: "LocationSelector"
								}}
								ref={locationSelectBox}
								items={locationCodeList}
								disabled={disableFilters}
								onValueChanged={setReportParams}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder={partTitle}
								elementAttr={{
									id: "partNumberSelect"
								}}
								ref={partNumSelectBox}
								items={custPartNumList}
								onValueChanged={setReportParams}
								showClearButton={true}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableFilters}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button
								icon='clear'
								elementAttr={{
									id: "ANScancelButton"
								}}
								disabled={disablePrintBtn}
								onClick={clearFilters}
							/>
						</div>
					</div>
				</form>
				<InventoryGridMaster
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
