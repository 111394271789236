import React, {useState, useEffect} from "react";
import appInfo from "../../app-info";
import {handleErrors} from "./helpers/globalFunctions";
import {Redirect} from "react-router-dom";
import CustomerHrnLblFormatMstGrid from "./CustomerHrnLblFormatMstGrid";

export default () => {
	const [harnessLabel, setHarnessLabel] = useState(null);
	const [barcodesType, setBarcodeTypes] = useState(null);
	const [tracibilityFlags, setTracibilityFlags] = useState(null);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	useEffect(() => {
		let getHarnessLabelFormat = `${appInfo.configURL}CustomerHarnessLabelFormatMaster/HarnessLabelFormat`;
		let getTracebilityFlags = `${appInfo.configURL}CustomerHarnessLabelFormatMaster/TracebilityFlags`;
		let getBarcodes = `${appInfo.configURL}CustomerHarnessLabelFormatMaster/BarCodeTypes`;
		const abortController = new AbortController();
		(async () => {
			await fetch(getHarnessLabelFormat, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal,
			})
				.then((response) => handleErrors(response))
				.then((result) => result.json())
				.then((result) => {
					setHarnessLabel(result);
				})
				.catch((error) => {
					console.error(
						"Harness Label Format Master fail getting harness label format"
					);
				});

			await fetch(getTracebilityFlags, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal,
			})
				.then((result) => result.json())
				.then((result) => {
					setTracibilityFlags(result);
				})
				.catch((error) => {
					console.error("Harness Label Format Master fail get tracibility");
				});

			await fetch(getBarcodes, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal,
			})
				.then((result) => result.json())
				.then((result) => {
					setBarcodeTypes(result);
				})
				.catch((error) => {
					console.error("Harness Label Format Master fail get barcodes");
				});
			return () => {
				// Abort the request when the component unmounts or when a dependency changes
				abortController.abort();
			};
		})();
	}, []);

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Customer Label Format Master</h2>
					</div>
				</div>

				<CustomerHrnLblFormatMstGrid
					harnessLabel={harnessLabel}
					barcodesType={barcodesType}
					tracibilityFlags={tracibilityFlags}
				/>
			</div>
		</React.Fragment>
	);
};
