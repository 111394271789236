import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import axios from "axios";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import InterPlantShipmentReportGrid from "./InterPlantShipmentReportGrid";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	let token = user ? user.data.token : '';
	let formatDate = user ? user.data.userData.formatDate :'';
	const permission = getPermission(user, "MISReports", "INTERPLANTSHIPMENTREPORT");

	/* Instance components in order to reset them when necessary */
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const asnSelectBox = useRef(null);
	const partNumSelectBox = useRef(null);
	const partNumRadioBtn = useRef(null);

	const partNumber = ["Customer", "SEWS"];
	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState();
	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const currentDate = new Date();

	const d = new Date();
	d.setDate(d.getDate() - 90);
	const cdate = d.toLocaleDateString();

	const [partNumSelected, setPartNumberSelected] = useState(partNumber[0]);
	const [custPartNumList, setCustPartNumList] = useState();
	const [ASNNum, setASSNum] = useState(null);

	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const [partTitle, setPartTitle] = useState("Cust. Part. No.");
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		asnNumber: "",
		custPartNumber: "",
		sewsPartNumber: "",
		dateFrom: "",
		dateTo: ""
	});
	const reportParamsArr = [
		"plantID",
		"asnNumber",
		"custPartNumber",
		"sewsPartNumber",
		"dateFrom",
		"dateTo"
	];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getASNnum = `${appInfo.reportURL}InterPlantShipmentReport/ASNNumbers`;
		(async () => {
				await fetch(getASNnum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => handleErrors(result))
					.then((response) => response.json())
					.then((result) => {
						let customASN = result.data.map((asn) => {
							return asn.value;
						});
						setASSNum(customASN);
					})
					.catch((error) => {
						console.warn(error);
					});
		
			await fetch(`${appInfo.reportURL}InterPlantShipmentReport/Plants`, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.id,
							value: plant.id + " - " + plant.value
						};
					});
					setPlants(customPlant);
				})
				.catch(err => console.warn(err));
		})();
	}, []);

	const partNumOnValueChange = (e) => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		e.promise = setPartNumberSelected(e.value);
		partNumSelectBox.current.instance.reset();
		setShowTooltip(true);

		if (e.value == "SEWS") {
			setPartTitle("SEWS Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "S"};
			});
		} else {
			setPartTitle("Cust. Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "C"};
			});
		}
	};
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}InterPlantShipmentReport${params}`
			})
		);
		setShowTooltip(false);
		setDisableFilters(true);
		setfirstTimeLoading(false);
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "mfgPlant":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, plantID: currentFieldVal};
					});
				}
				break;
			case "shipmentDateFrom":
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + 1000 * 3600 * 24 * 90;
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);

				/* Validate the following scenarios: 
                    - FROM date lower than TO date
                    - FROM date <= maxDate
                    - Date range not grather than 90 days
                */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "shipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");

				/* Validate the following scenarios: 
                    - TO date grather than FROM date
                    - TO date >= minDate
                    - Date range not grather than 90 days
                */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);

				if (daysPast_ < 0 || daysPast_ > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
			case "shipmentASNNo":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, asnNumber: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, asnNumber: currentFieldVal};
					});
				}
				partNumSelectBox.current.instance.reset();

				break;
			case "partNumberSelect":
				if (currentFieldVal !== "") {
					if (partTitle == "SEWS Part. No.") {
						setReportDetail((oldValues) => {
							return {...oldValues, sewsPartNumber: currentFieldVal};
						});
					} else if (partTitle == "Cust. Part. No.") {
						setReportDetail((oldValues) => {
							return {...oldValues, custPartNumber: currentFieldVal};
						});
					}
				} else {
					if (partTitle == "SEWS Part. No.") {
						setReportDetail((oldValues) => {
							return {...oldValues, sewsPartNumber: currentFieldVal};
						});
					} else if (partTitle == "Cust. Part. No.") {
						setReportDetail((oldValues) => {
							return {...oldValues, custPartNumber: currentFieldVal};
						});
					}
				}
				break;
		}
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		setShowTooltip(true);
		setCustomDataSource(null);
		setDisableFilters(false);
	};
	const onInitialized = (e) => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate + 1000 * 3600 * 24 * 91;

		let maxDate = new Date();
		maxDate.setTime(maxTime);

		setMaxToDate(currentDate);
		setMinToDate(cdate);
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateFrom: dateFromBox.current.instance.option("value")
			};
		});
	};
	const onInitializedDateTo = (e) => {
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateTo: dateToBox.current.instance.option("value").toLocaleDateString()
			};
		});
	};
	const onOpenedDropDown = async () => {
		let partRequestURL;
		if (partNumSelected === "Customer") {
			partRequestURL = `${appInfo.reportURL}InterPlantShipmentReport/CustPartNumbers?`;
		} else {
			partRequestURL = `${appInfo.reportURL}InterPlantShipmentReport/SEWSNumbers?`;
		}
		await axios
			.get(
				`${partRequestURL}&plantId=${reportDetail.plantID}&asnNumber=${reportDetail.asnNumber}`,
				{headers: {Authorization: `Bearer ${token}`}}
			)
			.then((result) => {
				let customPartNum;
				customPartNum = result.data.data.map((partNum) => {
					return partNum.value;
				});
				setCustPartNumList(customPartNum);
			})
			.catch((error) => {
				console.warn(error);
			});
	};
	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Inter Plant Shipment Report</h2>
					</div>
				</div>
				<h2 className={"content-block"}></h2>
				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Part Number</div>
							<RadioGroup
								items={partNumber}
								value={partNumSelected}
								onValueChanged={partNumOnValueChange}
								layout='horizontal'
								disabled={disableFilters}
								ref={partNumRadioBtn}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "mfgPlant"
								}}
								placeholder='Plant Code - Plant Name'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={currentPlant}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								displayFormat={formatDate}
								width='180px'
								disabled={disableFilters}
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={cdate}
								onInitialized={onInitialized}
								pickerType='calendar'
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateTo"
								}}
								ref={dateToBox}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								dateSerializationFormat='yyyy/MM/dd'
								max={maxToDate}
								disabled={disableFilters}
								min={minToDate}
								width='150px'
								onInitialized={onInitializedDateTo}
								pickerType='calendar'></DateBox>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='ASN No.'
								elementAttr={{
									id: "shipmentASNNo"
								}}
								disabled={disableFilters}
								onValueChanged={setReportParams}
								ref={asnSelectBox}
								searchEnabled={true}
								items={ASNNum}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder={partTitle}
								elementAttr={{
									id: "partNumberSelect"
								}}
								ref={partNumSelectBox}
								searchEnabled={true}
								disabled={disableFilters}
								items={custPartNumList}
								onValueChanged={setReportParams}
								showClearButton={true}
								onOpened={onOpenedDropDown}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<InterPlantShipmentReportGrid
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
