import React, {useState, useRef, useEffect} from "react";
import Form, {Item, Label} from "devextreme-react/form";

import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import Button from "devextreme-react/button";
import appInfo from "../../app-info";
import axios from "axios";
import {saveAs} from "file-saver";
import {LoadPanel} from "devextreme-react/load-panel";
import notify from "devextreme/ui/notify";
import RadioGroup from "devextreme-react/radio-group";
import { useAuth } from "../../contexts/auth";

export default () => {
	const doiReportParams = [
		"plantId",
		"sewsPartNo",
		"sewsDesignLevel",
		"reportOption",
		"from",
		"to",
		"exportDate",
		"exportTime"
	];
	const currentDate = new Date();
	const {user}= useAuth();
	let token = user.data.token;
	let language = user.data.userData.language;
	let formatDate = user.data.userData.formatDate;

	const d = new Date();
	d.setDate(d.getDate() - 90);
	const nd = new Date();
	nd.setDate(nd.getDate() + 89);
	const cdate = d.toLocaleDateString();
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		sewsPartNumber: "",
		dateFrom: "",
		dateTo: ""
	});

	const [loadPanelVisible, setLoadPanelVisible] = useState(false);
	const [minToDate, setMinToDate] = useState(cdate);
	const [maxToDate, setMaxToDate] = useState(nd);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [plants, setPlants] = useState();
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const typeOptions = ["Reserve", "Scrap", "Reverse"];
	const [typeOptionSelected, setTypeOptionSelected] = useState(typeOptions[0]);
	const [disableFields, setDisableFields] = useState(false);
	const [sewsPartNoList, setSewsPartNoList] = useState();
	const [sewsPartNoSelected, setSewsPartSelected] = useState();
	const [designLevelList, setDesignLevelList] = useState();
	const plantSelectBox = useRef(null);
	const dateToBox = useRef(null);
	const dateFromBox = useRef(null);
	const sewsPartNo = useRef(null);
	const sewsDesignLevel = useRef(null);

	const [planData, setData] = useState({
		labelLocation: "top",
		readOnly: false,
		showColon: true,
		minColWidth: 300,
		colCount: 4
	});

	useEffect(() => {
		(async () => {
			fetch(`${appInfo.doiURL}DOIReport/Plants`, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.warn(err);
				});
		})();
	}, []);

	const ppOnValueChange = (e) => {
		e.promise = setTypeOptionSelected(e.value);
	};

	const clearFilters = () => {
		sewsPartNo.current.instance.reset();
		sewsDesignLevel.current.instance.reset();
		dateFromBox.current.instance.reset();
		dateToBox.current.instance.reset();
		setTypeOptionSelected(typeOptions[0]);
		setPlantIDSelected();
	};

	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "doiDateFrom":
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + 1000 * 3600 * 24 * 185;
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);

				/* Validate the following scenarios: 
                    - FROM date lower than TO date
                    - FROM date <= maxDate
                    - Date range not grather than 90 days
                */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 185) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "doiDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");

				/* Validate the following scenarios: 
                    - TO date grather than FROM date
                    - TO date >= minDate
                    - Date range not grather than 90 days
                */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);

				if (daysPast_ < 0 || daysPast_ > 185) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
		}
	};
	const onSelectionChanged = (e) => {
		setPlantIDSelected(e.selectedItem.id);
	};
	const onInitialized = (e) => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate + 1000 * 3600 * 24 * 91;

		let maxDate = new Date();
		maxDate.setTime(maxTime);

		setMinToDate(cdate);
		setMaxToDate(maxDate);
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateFrom: dateFromBox.current.instance.option("value")
			};
		});
	};
	const onInitializedDateTo = (e) => {
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateTo: dateToBox.current.instance.option("value").toLocaleDateString()
			};
		});
	};

	const onOpenedPartNo = async () => {
		const partNoRequestURL = `${appInfo.doiURL}DOIReport/SewsPartNumbers?skip=0&take=10&requireTotalCount=true&plantID=${plantIDSelected}`;
		await axios
			.get(partNoRequestURL, {headers: {Authorization: `Bearer ${token}`}})
			.then((result) => {
				let partNoResult;
				partNoResult = result.data.map((partNo) => {
					return partNo.value;
				});
				setSewsPartNoList(partNoResult);
			})
			.catch((error) => {
				console.warn(error);
			});
	};

	const onOpenedDesignLevel = async () => {
		const partNoRequestURL = `${appInfo.doiURL}DOIReport/SewsDesignLevels?skip=0&take=10&requireTotalCount=true&plantID=${plantIDSelected}&sewsPartNumber=${sewsPartNoSelected}`;

		await axios
			.get(partNoRequestURL, {headers: {Authorization: `Bearer ${token}`}})
			.then((result) => {
				let partNoResult;
				partNoResult = result.data.map((partNo) => {
					return partNo.value;
				});
				setDesignLevelList(partNoResult);
			})
			.catch((error) => {
				console.warn(error);
			});
	};
	const onChangePartNo = (e) => {
		setSewsPartSelected(e.value);
	};

	const hideLoadPanel = () => {
		setLoadPanelVisible(false);
	};

	const sumbitAction = async () => {
		/* Get current date and time to send endpoint */
		let today = new Date();

		let hour = today.getHours();
		let minutes = today.getMinutes();
		let monthReport = today.getMonth() + 1;
		let yearReport = today.getUTCFullYear();
		let dayReport = today.getDate();
		let time = `${hour}:${minutes}`;
		let fullDateReport = `${yearReport}/${monthReport}/${dayReport}`;

		const data = {
			plantId: plantIDSelected,
			sewsPartNo: encodeURIComponent(sewsPartNo.current.instance.option("value")),
			sewsDesignLevel: sewsDesignLevel.current.instance.option("value"),
			reportOption: typeOptionSelected,
			from: dateFromBox.current.instance.option("value"),
			to: reportDetail.dateTo,
			exportDate: fullDateReport,
			exportTime: time
		};

		let fileName = `DOIReport_${new Date().toISOString().split("T")[0].replace(/-/g, "")}.xlsx`;

		/* Build URL before send  */
		const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
		let params = "?";
		doiReportParams.forEach(function (i) {
			if (i in data && isNotEmpty(data[i])) {
				params += `${i}=${JSON.stringify(data[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let exportURL = `${appInfo.doiURL}DOIReport/Export${params}`;

		getDownloadFile(exportURL, token, fileName);
	};
	const getDownloadFile = async (url, token, fileName) => {
		setLoadPanelVisible(true);
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => response.data)
			.then((buffer) => {
				hideLoadPanel();
				if (buffer.size > 0) {
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					return notify("[20024]No records found", "warning", 2000);
				}
			});
	};

	return (
		<React.Fragment>
			<div className={"content-block"}>
				<h2>DOI Report</h2>

				<div className='dx-field'>
					<Button
						icon='export'
						text='  Export'
						disabled={disableSearchBtn}
						onClick={sumbitAction}
					/>
				</div>
				<div className='dx-field'>
					<Button
						icon='remove'
						text='  Cancel'
						elementAttr={{id: "cancelButton"}}
						onClick={clearFilters}
					/>
				</div>
				<div className='dx-field'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>View</label>
					</div>
				</div>

				<div id='form-report'>
					<div className='widget-container'>
						<Form
							id='formPlantMaster'
							labelLocation={planData.labelLocation}
							minColWidth={planData.minColWidth}
							colCount={planData.colCount}
							width={planData.width}
							validationGroup='PlantData'>
							<Item dataField='plantId' visible={false} />
							<Item dataField='plant_id' colSpan={4}>
								<SelectBox
									elementAttr={{
										id: "plantInventorySelect"
									}}
									width='310px'
									valueExpr='id'
									displayExpr='value'
									placeholder='Plant ID'
									searchEnabled={true}
									ref={plantSelectBox}
									items={plants}
									value={plantIDSelected}
									onSelectionChanged={onSelectionChanged}
									disabled={disableFields}
									showClearButton={true}
								/>
							</Item>
							<Item dataField='SEWS Part No.' colSpan={2}>
								<SelectBox
									placeholder='SEWS Part No.'
									elementAttr={{
										id: "LocationSelector"
									}}
									width='310px'
									ref={sewsPartNo}
									showClearButton={true}
									onValueChanged={onChangePartNo}
									searchEnabled={true}
									items={sewsPartNoList}
									onOpened={onOpenedPartNo}
								/>
							</Item>
							<Item dataField='SEWS Design Level' colSpan={2}>
								<SelectBox
									placeholder='SEWS Design Level'
									elementAttr={{
										id: "LocationSelector"
									}}
									width='310px'
									ref={sewsDesignLevel}
									showClearButton={true}
									searchEnabled={true}
									onOpened={onOpenedDesignLevel}
									items={designLevelList}
								/>
							</Item>
							<Item dataField='date_from' colSpan={2}>
								<Label text='Date From' />
								<DateBox
									elementAttr={{
										id: "doiDateFrom"
									}}
									ref={dateFromBox}
									onValueChanged={setReportParams}
									useMaskBehavior={true}
									placeholder='Date From'
									displayFormat={formatDate}
									width='180px'
									dateSerializationFormat='yyyy/MM/dd'
									defaultValue={cdate}
									onInitialized={onInitialized}
									disabled={disableFields}
									pickerType='calendar'
								/>
							</Item>
							<Item dataField='date_to' colSpan={2}>
								<Label text='To' />
								<DateBox
									elementAttr={{
										id: "doiDateTo"
									}}
									ref={dateToBox}
									defaultValue={currentDate}
									onValueChanged={setReportParams}
									useMaskBehavior={true}
									placeholder='To'
									displayFormat={formatDate}
									dateSerializationFormat='yyyy/MM/dd'
									max={maxToDate}
									min={minToDate}
									width='150px'
									onInitialized={onInitializedDateTo}
									disabled={disableFields}
									pickerType='calendar'></DateBox>
							</Item>
							<Item dataField='type' colSpan={3}>
								<RadioGroup
									items={typeOptions}
									onValueChanged={ppOnValueChange}
									value={typeOptionSelected}
									layout='horizontal'
								/>
							</Item>
						</Form>
					</div>
				</div>
			</div>
			<LoadPanel
				visible={loadPanelVisible}
				onHiding={hideLoadPanel}
				showIndicator={true}
				shading={true}
				showPane={true}
			/>
		</React.Fragment>
	);
};
