import React, {useState, useRef, useEffect} from "react";
import Form, {Item, Label} from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import Button from "devextreme-react/button";
import appInfo from "../../app-info";
import axios from "axios";
import {saveAs} from "file-saver";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {LoadPanel} from "devextreme-react/load-panel";
import notify from "devextreme/ui/notify";
import RadioGroup from "devextreme-react/radio-group";
import {useAuth} from "../../contexts/auth";

export default () => {
	const rootUrl = {
		url: `${appInfo.reportURL}LabelPrintedButNotShipped/Export`
	};
	const {user} = useAuth();
	const permission = getPermission(user, "MISReports", "ShipmentReport");
	const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");
	let token = user ? user.data.token : '';
	let language = user ? user.data.userData.language : '';
	let formatDate = user ? user.data.userData.formatDate: '';
	const labelPrintedParams = [
		"plantID",
		"reportOption",
		"dateFrom",
		"dateTo",
		"exportDate",
		"exportTime"
	];
	const [plants, setPlants] = useState(null);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [activePlant, setActivePlant] = useState(null);
	const [loadPanelVisible, setLoadPanelVisible] = useState(false);
	const [printDate, setPrintDate] = useState(new Date());
	const [printDate2, setPrintDate2] = useState(new Date());
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const typeOptions = ["Harness", "Box", "Pallet"];
	const [typeOptionSelected, setTypeOptionSelected] = useState(typeOptions[0]);
	/* Instance components in order to reset them when necessary */
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);

	const [planData, setData] = useState({
		labelLocation: "top",
		readOnly: false,
		showColon: true,
		minColWidth: 300,
		colCount: 4
	});

	useEffect(() => {
		let getPlants = `${appInfo.reportURL}LabelPrintedButNotShipped/Plants`;
		(async () => {
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => handleErrors(result))
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.error(err, "ASN report fail plants request");
				});
			let getActivePlant = `${appInfo.reportURL}LabelPrintedButNotShipped/ActivePlant`;

			await fetch(getActivePlant, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					setPlantIDSelected(result.Id);
					setActivePlant(result.Id);
				})
				.catch((err) => {
					console.warn(err);
				});
		})();
	}, []);

	const onInitialized = (e) => {
		setPrintDate(dateFromBox.current.instance.option("value"));
		let newDay = new Date(dateFromBox.current.instance.option("value"));
		newDay.setDate(newDay.getDate() + 182);
		setPrintDate2(newDay);
	};

	const ppOnValueChange = (e) => {
		e.promise = setTypeOptionSelected(e.value);
	};

	const clearFilters = () => {
		dateFromBox.current.instance.reset();
		dateToBox.current.instance.reset();
		setPlantIDSelected(activePlant);
		setTypeOptionSelected(typeOptions[0]);
	};
	const hideLoadPanel = () => {
		setLoadPanelVisible(false);
	};
	const onVal = (e) => {
		let newFrom = new Date(dateFromBox.current.instance.option("value"));
		let newTo = new Date(dateToBox.current.instance.option("value"));

		if (newFrom <= newTo) {
			setdisableSearchBtn(false);
		} else {
			setdisableSearchBtn(true);
		}

		setPrintDate(dateFromBox.current.instance.option("value"));

		let newDay = new Date(dateFromBox.current.instance.option("value"));
		newDay.setDate(newDay.getDate() + 182);
		setPrintDate2(newDay);
	};

	const sumbitAction = async () => {
		/* Get current date and time to send endpoint */
		let today = new Date();
		let hour = today.getHours();
		let minutes = today.getMinutes();
		let seconds = today.getSeconds();
		let monthReport = today.getMonth() + 1;
		let yearReport = today.getUTCFullYear();
		let dayReport = today.getDate();
		let time = `${hour}:${minutes}:${seconds}`;
		let fullDateReport = `${yearReport}/${monthReport < 10 ? "0" + monthReport : monthReport}/${
			dayReport < 10 ? "0" + dayReport : dayReport
		}`;
		const data = {
			plantID: plantIDSelected,
			reportOption: typeOptionSelected.toUpperCase(),
			dateFrom: dateFromBox.current.instance.option("value"),
			dateTo: dateToBox.current.instance.option("value"),
			exportDate: fullDateReport,
			//exportTime: encodeURIComponent(time)
			exportTime: time
		};

		let fileName = `${typeOptionSelected}LblPrintedButNotShipped_${new Date()
			.toISOString()
			.split("T")[0]
			.replace(/-/g, "")}.xlsx`;

		/* Build URL before send  */
		const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
		let params = "?";
		labelPrintedParams.forEach(function (i) {
			if (i in data && isNotEmpty(data[i])) {
				params += `${i}=${JSON.stringify(data[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let exportURL = `${rootUrl.url}${params}`;

		getDownloadFile(exportURL, token, fileName);
	};

	const getDownloadFile = async (url, token, fileName) => {
		setLoadPanelVisible(true);
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => response.data)
			.then((buffer) => {
				hideLoadPanel();
				if (buffer.size > 0) {
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					return notify("[20024]No records found", "warning", 2000);
				}
			})
			.catch(err => console.warn(err));
	};
	const onValueChanged = (e) => {
		setPlantIDSelected(e.value);
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div className={"content-block"}>
				<h2>Label Printed but not Shipped-Report</h2>

				<div className='dx-field'>
					<Button
						icon='export'
						text='  Export'
						disabled={disableSearchBtn}
						onClick={sumbitAction}
					/>
				</div>
				<div className='dx-field'>
					<Button
						icon='remove'
						text='  Cancel'
						elementAttr={{id: "cancelButton"}}
						onClick={clearFilters}
					/>
				</div>
				<div className='dx-field'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>View</label>
					</div>
				</div>

				<div id='form-report'>
					<div className='widget-container'>
						<Form
							id='formPlantMaster'
							labelLocation={planData.labelLocation}
							minColWidth={planData.minColWidth}
							colCount={planData.colCount}
							width={planData.width}
							validationGroup='PlantData'>
							<Item dataField='plantId' visible={false} />
							<Item dataField='plant_id' colSpan={4}>
								<SelectBox
									elementAttr={{
										id: "incompleteShipmentSelect"
									}}
									placeholder='Plant Code - Plant Name'
									items={plants}
									displayExpr='value'
									valueExpr='id'
									onValueChanged={onValueChanged}
									value={plantIDSelected}
								/>
							</Item>
							<Item dataField='date_from' colSpan={2}>
								<Label text='Date From' />
								<DateBox
									defaultValue={currentDate}
									type='date'
									width='244px'
									elementAttr={{
										id: "LocationSelector"
									}}
									displayFormat={formatDate}
									pickerType='calendar'
									ref={dateFromBox}
									useMaskBehavior={true}
									placeholder='Date From'
									dateSerializationFormat='yyyy/MM/dd'
									onValueChanged={onVal}
								/>
							</Item>
							<Item dataField='date_to' colSpan={2}>
								<Label text='To' />
								<DateBox
									defaultValue={currentDate}
									type='date'
									width='244px'
									elementAttr={{
										id: "LocationSelector"
									}}
									displayFormat={formatDate}
									ref={dateToBox}
									placeholder='To'
									dateSerializationFormat='yyyy/MM/dd'
									min={printDate}
									max={printDate2}
									onValueChanged={onVal}
									onInitialized={onInitialized}
									pickerType='calendar'
								/>
							</Item>
							<Item dataField='type' colSpan={3}>
								<RadioGroup
									items={typeOptions}
									onValueChanged={ppOnValueChange}
									value={typeOptionSelected}
									layout='horizontal'
								/>
							</Item>
						</Form>
					</div>
				</div>
			</div>
			<LoadPanel
				visible={loadPanelVisible}
				onHiding={hideLoadPanel}
				showIndicator={true}
				shading={true}
				showPane={true}
			/>
		</React.Fragment>
	);
};
