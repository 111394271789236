import React from "react";
export default function ItemStatus(args) {
	return (
		<div className="custom-item">
			{args.args.enabled === true ? 
				<i className="dx-icon-isnotblank enabled"></i>
			 : 
				<i className="dx-icon-isnotblank disabled"></i>
			}
			<div className="product-name">{args.args.name}</div>
		</div>
	);
}
