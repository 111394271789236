import React from 'react';

function gridCellData(gridData) {
  let row = {
    remaining: gridData.data.remaining,
    shipped:gridData.data.shipped
  }
  return row;
}

export default function ShippedColumn(cellData) {
  let {shipped, remaining} = gridCellData(cellData);
  return (
    <div className={remaining < 0 ? 'dec': remaining ===0?''  : 'inc'}>
      <div className="diff">{shipped}</div>
    </div>
  );
}
