import axios from "axios";
import {alert} from "devextreme/ui/dialog";

async function updatePrimaryColumn(url, token) {
	await axios
		.get(url, {headers: {Authorization: `Bearer ${token}`}})
		.then((result) => {
			sessionStorage.removeItem("defaultValueCarrier");
			sessionStorage.setItem(
				"defaultValueCarrier",
				JSON.stringify(result.data)
			);
		})
		.catch((err) => console.warn("Error updating primary column"));
}
async function updatePrimaryDestinationColumn(url, token) {
	await axios
		.get(url, {headers: {Authorization: `Bearer ${token}`}})
		.then((result) => {
			sessionStorage.removeItem("defaultValueDestination");
			sessionStorage.setItem(
				"defaultValueDestination",
				JSON.stringify(result.data)
			);
		})
		.catch((err) => console.warn("Error updating primary destination"));
}

function getPermission(currentUser, section, module) {
	if (currentUser) {
		let permission = {};
		let rawPermission = currentUser.data.permissionTree
			.filter((item) => item.prograM_DES_CLEAN == section)[0]
			.treeMenuChild.filter(
				(subitem) => subitem.prograM_DES_CLEAN == module
			)[0];

		if (rawPermission) {
			permission.read = rawPermission.procesS_FLG != "Y" ? false : true;
			permission.create = rawPermission.adD_ALLOW != "Y" ? false : true;
			permission.update = rawPermission.modifY_ALLOW != "Y" ? false : true;
			permission.delete = rawPermission.deletE_ALLOW != "Y" ? false : true;
		}
		return permission;
	}
}

async function processBatchRequest(url, token, changes, component) {
	await sendBatchRequest(url, token, changes);
	if (component) {
		//keep this for simple component editing like: system params, destination, xref config and xref maintenance
		await component.refresh(true);
		await component.cancelEditData();
	}
}

async function sendBatchRequest(url, token, changes) {
	await axios({
		method: "PUT",
		url: url,
		data: changes,
		headers: {Authorization: `Bearer ${token}`},
	})
		.then((result) => {
			alert(
				"<i class='dx-icon-check' id='check-icon'></i><br/><br/>Saved Successfully"
			);
			return {
				status: result.data.outputDesc,
			};
		})
		.catch((error) => {
			if (error.response) {
				let message = error.response.data;
				alert(
					"<i class='dx-icon-close' id='cancel-icon'></i><br/><br/>" + message
				);
			} else if (error.request) {
				// The request was made but no response was received
				console.warn(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.warn("Error", error.message);
			}
		});
}

function setMode(value) {
	document.getElementById("modeLabel").innerText = value;
}

function getMode() {
	return document.getElementById("modeLabel").innerText;
}
function handleErrors(response) {
	if (!response.ok) {
		if (response.status === 401) {
			sessionStorage.removeItem("currentUser");
			window.location.reload();
			let url = window.location.origin + "/#/login";
			window.location.href = url;
		}
		throw Error(response.statusText);
	}
	return response;
}
export {
	getPermission,
	processBatchRequest,
	updatePrimaryColumn,
	updatePrimaryDestinationColumn,
	setMode,
	getMode,
	handleErrors,
};
