import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import DockMasterGrid from "./DockMasterGrid";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"DockMaster"
	);

	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("0");
	const [customDataSource, setCustomDataSource] = useState(null);

	useEffect(() => {
		let getPlants = `${appInfo.configURL}DockMaster/Plants`;
		let getActivePlant = `${appInfo.configURL}DockMaster/ActivePlant`;
		(async () => {
			try {
				await fetch(getPlants, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customPlant = result.map((plant) => {
							return {
								id: plant.Id,
								value: plant.Id + " - " + plant.Value,
							};
						});
						setPlants(customPlant);
					});
			} catch (error) {
				console.error("Fail in request getting plants");
			}
			try {
				await axios
					.get(getActivePlant, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						let activePlant = result.data;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
						sessionStorage.setItem("currentPlant", activePlant);
					});
			} catch (error) {
				console.error("Getting active plant failed request:", error);
			}
		})();
	}, []);

	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}DockMaster?plantid=${e.value}`,
			})
		);
		sessionStorage.setItem("currentPlant", e.value);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Dock Master</h2>
					</div>
				</div>
				<div className="header-right-side">
					<SelectBox
						elementAttr={{
							id: "DockMasterSelector",
						}}
						placeholder="Plant Code"
						items={plants}
						displayExpr="value"
						valueExpr="id"
						onValueChanged={onValueChanged}
						value={currentPlant}
					/>
				</div>
				<DockMasterGrid
					customDataSource={customDataSource}
					plantId={currentPlant}
				/>
			</div>
		</React.Fragment>
	);
};
