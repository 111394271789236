import React from "react";

export default function ShipmentPlantItem(data) {
	return (
		<div className="custom-item">
			<div className="product-name">
				{data.plantId} - {data.value}
			</div>
		</div>
	);
}
