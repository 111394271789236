import React, { useState, useRef, useCallback } from 'react';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { changePassword } from '../../api/auth';
import { useAuth } from '../../contexts/auth';

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'New Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };


export default function (props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const { recoveryCode } = useParams();
  const { signIn } = useAuth();
  const location = useLocation();
  const uname = location.state.detail;
  const servername = location.state.servername;
  const message = location.state.message;
  const userNameEditorOptions = { disabled: true, value: uname };
  const oldPassword = { stylingMode: 'filled', placeholder: message }

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password } = formData.current;
    const { oldPassword } = formData.current;
    const { userName } = formData.current;

    setLoading(true);

    const result = await changePassword(password, oldPassword, userName, servername);
    setLoading(false);

    if (result.isOk) {
      const singInRes = await signIn(userName, password, servername);
      if (singInRes.isOk) {
        sessionStorage.setItem("currentUser", JSON.stringify(singInRes));
        notify('Password has been changed succesfully');
        return history.push('/home');
      }
      
    } else {
      notify(result.message, 'error', 2000);
      
    }

  }, []);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );
  const redirectLogin = () => {
    let url = window.location.origin + '/#/login';
    window.location.href = url;
  }

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          editorType={'dxTextBox'}
          editorOptions={userNameEditorOptions}
          dataField={'userName'}
        >
          <RequiredRule message="Old Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'oldPassword'}
          editorType={'dxTextBox'}
          editorOptions={oldPassword}
        >
          <RequiredRule message="Old Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Continue'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            height={'auto'}
            onClick={redirectLogin}
          >
            <span className="dx-button-text">Cancel</span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}