import React, {useState, useRef} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export,
	RequiredRule,
	AsyncRule
} from "devextreme-react/data-grid";
import {getPermission} from "../configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import {Button} from "devextreme-react/button";
import {confirm, alert} from "devextreme/ui/dialog";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, firstTimeLoading, params, stateUp}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	const [totalCount, setTotalCount] = useState(0);
	let finalMsg = "";
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;
	let HarnessSNoAdded = [];

	const permission = getPermission(currentUser, "ReworkReinspection", "NoboxRelabeling");
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	function asyncValidation(cellParams) {
		return new Promise((resolve, reject) => {
			fetch(
				`${appInfo.reworkURL}NoBoxRelabeling/IsValid/?plantId=${params.plantId}&boxSNO=${params.boxSno}&harnessOption=${params.HarnessOption}&sourceHarness=${params.sourceHarness}&destHarness=${cellParams.value}`,
				{
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				}
			)
				.then((response) => response.json())
				.then((result) => {
					resolve({
						isValid: result.isValid,
						message: result.message
					});
				});
		});
	}

	const onRowUpdating = async (e) => {
		let mergeData = {...e.oldData, ...e.newData};
		mergeData.id = mergeData.id.toString();
		e.newData = mergeData;
		e.newData.PlantId = params.plantId;
		e.newData.BoxSNO = params.boxSno;
		e.newData.QTY = params.quantity;
		e.newData.SourceHarness = params.sourceHarness;
		e.newData.HarnessOption = params.HarnessOption;
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};

	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
	};

	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	function setCellValue(newData, value) {
		//validate if the short part No is correct
		let datagridItems = dataGrid.current.instance.getDataSource().items(); //check if there's short part no in the datagrid - datasource
		let shortPartNoExist = datagridItems.some((item) => item.shortPartNo !== null);
		let currentShortPartNo = "";

		 let getShortPartNo = params.sourceHarness === 'Mexico' ? value.substring(4, 8) : value.substring(0, 4);
		if (shortPartNoExist) currentShortPartNo = datagridItems[0].shortPartNo;

		if (shortPartNoExist && currentShortPartNo === getShortPartNo) {
			//if there's short part no in datasource then...
			//it doesn't exist any short part so wherever value is correct
			newData.destHarnessSno = value;
			newData.shortPartNo = getShortPartNo;
		}
		if (shortPartNoExist && currentShortPartNo !== getShortPartNo) {
			alert("[20131] Short Part No. should be same.");
			newData.destHarnessSno = value;
			newData.shortPartNo = null;
		}

		if (!shortPartNoExist && HarnessSNoAdded.length === 0) {
			//if there's no short part no in datasource then...
			//it doesn't exist any short part so wherever value is correct
			newData.destHarnessSno = value;
			newData.shortPartNo = getShortPartNo;
		} else if (!shortPartNoExist && HarnessSNoAdded.length !== 0) {
			//if short part no does not exist it means user will set new short part no. and we need to check if it doesn't exist
			//This logic avoid user cannot repeat Harness SNo while editing
			newData.destHarnessSno = value;
			let currentChanges;
			if(params.sourceHarness === 'Mexico'){
				currentChanges = HarnessSNoAdded.map((harness) => {
					return harness.destHarnessSno.substring(4, 8);
				});
			}else{
				currentChanges = HarnessSNoAdded.map((harness) => {
					return harness.destHarnessSno.substring(0, 4);
				});
			}

			let matchShortPart = currentChanges.some((sp) => sp === getShortPartNo);

			if (matchShortPart) {
				newData.shortPartNo = getShortPartNo;
			} else if (!matchShortPart && getShortPartNo !== "") {
				alert("[20131] Short Part No. should be same.");
				newData.shortPartNo = null;
			}
		}
	}
	const onRowPrepared = (e) => {
		if (e.rowType === "data" && e.data.destHarnessSno !== null && !e.modified) {
			stateUp(true);
		}
		if (e.rowType === "data" && e.data.destHarnessSno !== "" && e.modified) {
			if (HarnessSNoAdded.length > 0) {
				const repeatedKeyandValue = HarnessSNoAdded.some(
					(harness) => harness.id === e.data.id && harness.destHarnessSno === e.data.destHarnessSno
				); //check if row already exist
				const foundRepeatValue = HarnessSNoAdded.some(
					(harness) => harness.destHarnessSno === e.data.destHarnessSno
				); //check if the value exist
				const repetedKey = HarnessSNoAdded.some((harness) => harness.id === e.data.id);
				if (foundRepeatValue && !repeatedKeyandValue) {
					alert("[20007] Record Already Exist!");
					e.component.cellValue(e.rowIndex, "destHarnessSno", "");
					e.component.focus();
				}
				if (!foundRepeatValue && repetedKey) {
					//if the destHarnessSno is new but the key already exist, just update the destHarnessSno
					HarnessSNoAdded.map((item) => {
						if (item.id === e.data.id) {
							item.destHarnessSno = e.data.destHarnessSno;
						}
					});
				}
				if (!foundRepeatValue && !repetedKey) {
					HarnessSNoAdded.push(e.data);
				}
			} else {
				HarnessSNoAdded.push(e.data);
			}
		}
	};
	const onEditCanceled = () => {
		HarnessSNoAdded = [];
	};
	return (
		<DataGrid
			elementAttr={{
				id: "InventoryGridContainer"
			}}
			ref={dataGrid}
			showBorders={true}
			dataSource={customDataSource}
			allowColumnResizing={true}
			columnResizingMode='widget'
			columnMinWidth={100}
			onRowUpdating={onRowUpdating}
			onRowUpdated={onRowUpdated}
			onSaving={onSaving}
			onSaved={onSaved}
			onRowPrepared={onRowPrepared}
			onContentReady={getTotalCount}
			onEditCanceled={onEditCanceled}>
			<Toolbar>
				<Item location='after'>
					<div className={"total-label"}>
						<span>Total Records: </span>
						<label>{totalCount}</label>
					</div>
				</Item>
				<Item location='after'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>{dataGridSettings.mode}</label>
					</div>
				</Item>
				<Item>
					<Button
						icon={IconFilter}
						className='dx-toolbar-item'
						hint='Clean Filters'
						onClick={clearFiltersDG}
					/>
				</Item>
				<Item name='revertButton' />
				<Item name='saveButton' />
			</Toolbar>
			<Selection mode='single' />
			<RemoteOperations groupPaging={true} />
			<Export enabled={true} />
			<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
			<Editing
				mode='batch'
				startEditAction={dataGridSettings.startEditAction}
				allowAdding={permission.create}
				allowUpdating={permission.update}
				texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
				refreshMode='full'
			/>
			<Column dataField='id' caption='No.' visible={false} />
			<Column
				dataField='destHarnessSno'
				caption='Harness SNo'
				alignment='center'
				allowEditing={true}
				setCellValue={setCellValue}>
				<AsyncRule ignoreEmptyValue={true} validationCallback={asyncValidation}></AsyncRule>
				<RequiredRule />
			</Column>
			<Column
				dataField='sewsPartNo'
				caption='SEWS Part No.'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='sewsDesignLevel'
				caption='SEWS Design Level'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='shortPartNo'
				caption='Short Part No.'
				alignment='center'
				allowEditing={false}>
				<RequiredRule />
			</Column>
			<Column dataField='custCode' caption='Cust. code' alignment='center' allowEditing={false} />
			<Paging defaultPageSize={10} defaultPageIndex={0} />
			<Pager
				visible={true}
				allowedPageSizes={allowedPageSizes}
				displayMode='full'
				showPageSizeSelector={dataGridSettings.showPageSizeSelector}
				showInfo={dataGridSettings.showInfo}
				showNavigationButtons={dataGridSettings.showNavButtons}
			/>
			<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
		</DataGrid>
	);
};
