import React, {useState, useRef, useEffect} from "react";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	Item,
	ColumnFixing,
	RemoteOperations,
	Scrolling,
	Selection,
	Export
} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import saveAs from "file-saver";
import {CheckBox} from "devextreme-react/check-box";
import appInfo from "../../app-info";
import axios from "axios";
import notify from "devextreme/ui/notify";
import {alert} from "devextreme/ui/dialog";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, token, firstTimeLoading, disableExport}) => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	const rootUrl = {
		urlPS: `${appInfo.reportURL}PrintShipmentReport/ExportPS`,
		urlPL: `${appInfo.reportURL}PrintShipmentReport/ExportPL`,
		urlDL: `${appInfo.reportURL}PrintShipmentReport/ExportDL`
	};
	const progessReportParams = ["ASN", "exportDate"];
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	const [totalCount, setTotalCount] = useState(0);
	const [asnNumber, setAsnNumber] = useState(null);
	const [packingList, setPackingList] = useState(true);
	const [summaryPackingList, setSummaryPackingList] = useState(true);
	const [deliveryList, setDeliveryList] = useState(true);

	let language = currentUser ? currentUser.data.userData.language : '';
	let formatDate = currentUser ? currentUser.data.userData.formatDate + " HH:mm" : '';

	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	useEffect(() => {
		window.addEventListener("cleanFileds", () => {
			setTotalCount(0);
		});
	}, []);
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onSelectionChanged = (e) => {
		setAsnNumber(e.selectedRowsData[0].asnNumber);
	};
	const sumbitAction = async () => {
		let totalRecordsDG = dataGrid.current.instance.getDataSource().items().length;

		if (totalRecordsDG > 1 && asnNumber === null) {
			return alert("Select an ASN No.");
		} else if (totalRecordsDG == 1) {
			/* 
        INFO: if totalRecordsDG is NOT grather than 1,
       it means DG has only one record, therefore select the first item in the datasource
      */
			let rowValue = dataGrid.current.instance.getDataSource().items();
			let asnVal = rowValue[0].asnNumber;
			exportFunction(asnVal);
		} else if (totalRecordsDG > 1 && asnNumber != null) {
			let asnVal = asnNumber;
			exportFunction(asnVal);
		}
	};

	const exportFunction = (asnVal) => {
		/* Get current date and time to send endpoint */

		const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");

		const data = {
			ASN: asnVal,
			exportDate: currentDate
		};

		/* Build URL before send  */
		const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
		let params = "?";
		progessReportParams.forEach(function (i) {
			if (i in data && isNotEmpty(data[i])) {
				params += `${i}=${JSON.stringify(data[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let exportURLPS = `${rootUrl.urlPS}${params}`;
		let exportURLPL = `${rootUrl.urlPL}${params}`;
		let exportURLDL = `${rootUrl.urlDL}${params}`;

		if (packingList) {
			let fileName = `PackingList_${new Date().toISOString().split("T")[0].replace(/-/g, "")}.xlsx`;
			getDownloadFile(exportURLPL, token, fileName);
		}
		if (summaryPackingList) {
			let fileName = `PackingListSummary_${new Date()
				.toISOString()
				.split("T")[0]
				.replace(/-/g, "")}.xlsx`;
			getDownloadFile(exportURLPS, token, fileName);
		}
		if (deliveryList) {
			let fileName = `DeliveryList_${new Date()
				.toISOString()
				.split("T")[0]
				.replace(/-/g, "")}.xlsx`;
			getDownloadFile(exportURLDL, token, fileName);
		}
	};
	const onClickCheckbox = (e) => {
		let elementID = e.element.id;
		if (elementID === "pl") {
			setPackingList(e.value);
		}
		if (elementID === "spl") {
			setSummaryPackingList(e.value);
		}
		if (elementID === "dl") {
			setDeliveryList(e.value);
		}
	};

	const getDownloadFile = async (url, token, fileName) => {
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => {
				return response.data;
			})
			.then((buffer) => {
				//hideLoadPanel();
				if (buffer.size > 0) {
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					return notify("[20024]No records found", "warning", 2000);
				}
			})
			.catch(async (e) => {
				console.warn(e);
			});
	};
	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "harnessGridContainer"
				}}
				ref={dataGrid}
				showBorders={true}
				dataSource={customDataSource}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				onContentReady={getTotalCount}
				onSelectionChanged={onSelectionChanged}>
				<Toolbar>
					<Item location='before'>
						<CheckBox
							text='Packing List    '
							value={packingList}
							onValueChanged={onClickCheckbox}
							id='pl'
						/>
					</Item>
					<Item location='before'>
						<CheckBox
							text='Summary Packing List    '
							value={summaryPackingList}
							onValueChanged={onClickCheckbox}
							id='spl'
						/>
					</Item>
					<Item location='before'>
						<CheckBox
							text='Delivery List'
							value={deliveryList}
							onValueChanged={onClickCheckbox}
							id='dl'
						/>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>{dataGridSettings.mode}</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFiltersDG}
						/>
					</Item>
					<Item>
						<Button icon='xlsxfile' onClick={sumbitAction} disabled={disableExport} />
					</Item>
				</Toolbar>
				<ColumnFixing enabled={true} />
				<Selection mode='single' />
				<RemoteOperations groupPaging={true} />
				<Export enabled={true} />
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<Column dataField='id' caption='No.' visible={false} />
				<Column dataField='asnNumber' caption='ASN No.' />
				<Column dataField='transportationInformation' caption='Truck Info' />
				<Column dataField='carrier' caption='Carrier Name' />
				<Column
					dataField='dateTimeStamp'
					dataType='datetime'
					caption='Date Time Stamp'
					format={formatDate}
				/>
				<Column dataField='dockNm' caption='Dock Name' />
				<Column dataField='employeeId' caption='Employee Id.' />

				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};
