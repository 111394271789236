import React, { useState, useRef, useEffect } from "react";
import Form, { Item, Label } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import appInfo from '../../app-info';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert, confirm } from "devextreme/ui/dialog";
import notify from 'devextreme/ui/notify';
import { handleErrors, getPermission } from "../configuration/helpers/globalFunctions";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../contexts/auth";

export default () => {
  const {user} = useAuth();
  let token = user ? user.data.token : '';
  const permission = getPermission(user, "ImportExport", "RetransmitASNtoCustoms");

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [disableConfirmBtn, setdisableConfirmBtn] = useState(false);
  const [asnNumber, setAsnNumber] = useState(null);
  const [asnNumberSelected, setASNnumberSelected] = useState(null);
  /* Instance components in order to reset them when necessary */
  const asnNumberField = useRef(null);

  const [planData, setData] = useState({
    labelLocation: 'top',
    readOnly: false,
    showColon: true,
    minColWidth: 300,
    colCount: 4,
  });

  useEffect(() => {
    (async () => {
      let getASNnumber = `${appInfo.importURL}RetransmitASNToCustoms/AsnNumbers`;

        await fetch(getASNnumber,
          {
            method: 'get',
            headers: { "Authorization": `Bearer ${token}` }
          })
          .then(result => handleErrors(result))
          .then(result => result.json())
          .then(result => {
            setAsnNumber(result.data);
          })
          .catch((error) => {
        console.error(error, 'Retransmit ASN failed getting ASN numbers');
      });
    })();
  }, []);


  const clearFilters = () => {
    asnNumberField.current.instance.reset();
  }
  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  }

  const sumbitAction = async () => {
    if (asnNumberSelected === null || asnNumberSelected === '') {
      alert('[20001] Cannot Be Blank.');
    } else {
      const dialogResult = await confirm("Are you sure you want to retransmit ASN?");
      if (dialogResult) {
        setLoadPanelVisible(true);
        setdisableConfirmBtn(true);
        fetch(`${appInfo.importURL}RetransmitASNToCustoms/Retransmit?asnNumber=${asnNumberSelected}`,
          {
            method: 'post',
            headers: {
              "Authorization": `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
          .then(response => {
            hideLoadPanel();
            setdisableConfirmBtn(false);
            alert(response.message);
          })
          .catch((error) => {
            console.warn(error);
            notify('504 Gateway Time-out', 'error', 2000);
            hideLoadPanel();
            setdisableConfirmBtn(false);
          });
      }
    }
  }
  const onValueChanged = (e) => {
    setASNnumberSelected(e.value);
  }
  if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
  return (
    <React.Fragment>
      <div className={'content-block'}>
        <h2>Retransmit ASN to Customs</h2>
        <div className="dx-field">
          <Button
            icon="export"
            text="   Confirm"
            disabled={disableConfirmBtn}
            onClick={sumbitAction}
          />
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
        <div className="dx-field">
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <Button
            icon="remove"
            text="  Cancel"
            disabled={disableConfirmBtn}
            onClick={clearFilters}
          />
        </div>
        <div className="dx-field">
          <div className={'total-label'}>
            <span> Mode: </span>
            <label id='modeLabel' >View</label>
          </div>
        </div>

        <div id="form-report">
          <div className="widget-container">
            <Form
              id="formPlantMaster"
              labelLocation={planData.labelLocation}
              minColWidth={planData.minColWidth}
              colCount={planData.colCount}
              width={planData.width}
              validationGroup="PlantData">
              <Item dataField="ASN Number" visible={false} />
              <Item dataField="asnNumber" colSpan={4}>
                <SelectBox
                  elementAttr={{
                    id: 'plantInventorySelect'
                  }}
                  ref={asnNumberField}
                  placeholder='ASN Number'
                  items={asnNumber}
                  displayExpr="value"
                  valueExpr="value"
                  onValueChanged={onValueChanged}
                  value={asnNumberSelected}
                />
              </Item>
            </Form>
          </div>
        </div>
      </div>
      <LoadPanel
        visible={loadPanelVisible}
        onHiding={hideLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
    </React.Fragment>
  )
}
