import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import LabelPrinterMasterGrid from "./LabelPrinterMasterGrid";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"LabelPrinterMaster"
	);
	const [printerList, setPrinterList] = useState(null);
	const [currentPrinter, setCurrentPrinter] = useState("");
	const [customDataSource, setCustomDataSource] = useState(null);
	const [showModelType, setShowModelType] = useState(true);

	useEffect(() => {
		let getPrinters = `${appInfo.configURL}LabelPrinterMaster/PrinterTypes`;

		(async () => {
			try {
				await fetch(getPrinters, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customPrint = result.map((printer) => {
							return printer.printerType;
						});
						setPrinterList(customPrint);
						setCurrentPrinter(customPrint[0]);
					})
					.catch((error) => {
						console.warn(error);
					});
			} catch (error) {
				console.error("Label Printer Master fail get default request");
			}
		})();
	}, []);

	const onValueChanged = (e) => {
		if (e.value == "ZEBRA") {
			setShowModelType(false);
		} else {
			setShowModelType(true);
		}
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}LabelPrinterMaster?printerType=${e.value}`,
			})
		);
		setCurrentPrinter(e.value);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Label Printer Master</h2>
					</div>
				</div>
				<div className="header-right-side">
					<SelectBox
						placeholder="Printer"
						elementAttr={{
							id: "LabelPrinterSelector",
						}}
						name="printerItem"
						items={printerList}
						value={currentPrinter}
						onValueChanged={onValueChanged}
					/>
					/
				</div>
				<LabelPrinterMasterGrid
					customDataSource={customDataSource}
					showModelType={showModelType}
					currentPrinter={currentPrinter}
				/>
			</div>
		</React.Fragment>
	);
};
