import React, {useRef, useState, useCallback} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	ColumnFixing,
	Toolbar,
	Item,
	Pager,
	Export,
	Scrolling,
	Sorting,
	RequiredRule,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import {alert, confirm} from "devextreme/ui/dialog";
import CheckBox from "devextreme-react/check-box";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, plantId}) => {
	const [totalCount, setTotalCount] = useState(0);
	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	let finalMsg = "";
	let canceled;
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"DockMaster"
	);
	const [plantList, setPlantList] = useState(null);

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard",
	});

	/*  Init configuration  */
	const getTotalCount = () => {
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
	};

	const onEditCanceled = (e) => {
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `Dock Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Dock Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], {type: "application/octet-stream"}),
					buildTitle
				);
			});
		});
		e.cancel = true;
	};
	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist.<br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};

	const onRowUpdating = (e) => {
		e.newData.plantid = plantId;
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowInserting = (e) => {
		e.data.plantid = plantId;
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);


	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "dockMasterMainDataGrid",
				}}
				ref={dataGrid}
				dataSource={customDataSource}
				allowColumnResizing={true}
				columnResizingMode="nextColumn"
				columnAutoWidth={true}
				columnMinWidth={100}
				showBorders={true}
				remoteOperations={true}
				onContentReady={getTotalCount}
				onExporting={onExporting}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}>
				<Toolbar>
					<Item location="after">
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location="after">
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id="modeLabel">View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className="dx-toolbar-item"
							hint="Clean Filters"
							onClick={clearFilters}
						/>
					</Item>
					<Item name="exportButton" />
					<Item name="addRowButton" />
					<Item name="revertButton" />
					<Item name="saveButton" />
					<Item name="searchPanel" />
				</Toolbar>
				<ColumnFixing enabled={true} />
				<Sorting mode="multiple" />
				<FilterRow
					visible={true}
					applyFilter={{key: "auto", name: "Immediately"}}
				/>
				<SearchPanel visible={true} width={240} placeholder="Search..." />
				<Export enabled={true} />
				<Editing
					mode="batch"
					startEditAction={dataGridSettings.startEditAction}
					allowAdding={permission ? permission.create : false}
					allowUpdating={permission ? permission.update : false}
					allowDeleting={permission ? permission.delete : false}
					texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
					refreshMode="full"
				/>
				<Column
					dataField="id"
					caption="No"
					visible={false}
					allowEditing={false}
					allowFiltering={false}
				/>
				<Column
					dataField="dockName"
					caption="Dock Name"
					allowEditing={true}
					editorOptions={{maxLength: 15}}>
					<RequiredRule />
				</Column>
				<Column
					dataField="truckInfo"
					caption="Truck Information"
					allowEditing={false}
				/>
				<Column
					dataField="openDateTime"
					caption="Open Date Time"
					allowEditing={false}
				/>
				<Column dataField="openedBy" caption="Opened By" allowEditing={false} />
				<Column
					dataField="lastActionOn"
					caption="Last Action On"
					allowEditing={false}
				/>
				<Column
					dataField="status"
					caption="Status"
					cellRender={cellRender}
					allowEditing={false}
				/>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode="full"
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};
function cellRender(data) {
	let status = null;
	status = data.data.status;
	return <CheckBox value={status} />;
}
