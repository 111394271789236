import React, {useState, useRef, useCallback, useEffect} from "react";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	ColumnFixing,
	Export
} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import IconFilter from "../../components/filter.svg";
import {setMode, getMode} from "../configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import axios from "axios";
import notify from "devextreme/ui/notify";
import {saveAs} from "file-saver";

export default ({
	showDGColumns,
	customDataSource,
	firstTimeLoading,
	reportDetail,
	disableSearchBtn
}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const [totalCount, setTotalCount] = useState(0);
	const dataGrid = useRef(null);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let language = currentUser ? currentUser.data.userData.language : '';
	const reportParamsArr = [
		"plantID",
		"reportOption",
		"partNumberOption",
		"partNumber",
		"ASN",
		"dateFrom",
		"dateTo"
	];
	useEffect(() => {
		window.addEventListener("resetFilters", () => {
			setTotalCount(0);
		});
	}, []);
	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";

	const onExporting = async () => {
		/* Get current date and time to send endpoint */
		dataGrid.current.instance.beginCustomLoading();
		let today = new Date();
		let fileName;
		let monthReport = today.getMonth() + 1;
		let yearReport = today.getUTCFullYear();
		let dayReport = today.getDate();

		fileName =
			language === "ES"
				? (fileName = `Return products Report ${dayReport}-${monthReport}-${yearReport}.xlsx`)
				: (fileName = `Return Products Report ${monthReport}-${dayReport}-${yearReport}.xlsx`);
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let exportURL = `${appInfo.reportURL}ReturnProductsReport/Export${params}`;
		downloadReport(exportURL, token, fileName);
	};

	const downloadReport = async (url, token, fileName) => {
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => response.data)
			.then((buffer) => {
				if (buffer.size > 0) {
					dataGrid.current.instance.endCustomLoading();
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					dataGrid.current.instance.endCustomLoading();
					return notify("[20024]No records found", "warning", 2000);
				}
			})
			.catch(async (e) => {
				let statusCode = e.response.status;
				let responseObj = await e.response.data.text();
				let filteredMessage = responseObj.replace(/{"message":"/g, "");
				return alert(filteredMessage.replace(/"}/g, ""));
			});
	};

	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const onEditCanceled = (e) => {
		setMode("View");
	};
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	return (
		<DataGrid
			elementAttr={{
				id: "InventoryGridContainer"
			}}
			dataSource={customDataSource}
			ref={dataGrid}
			showBorders={true}
			allowColumnResizing={true}
			columnResizingMode='nextColumn'
			columnAutoWidth={true}
			columnMinWidth={100}
			onContentReady={getTotalCount}
			onOptionChanged={onOptionChanged}
			onEditCanceled={onEditCanceled}>
			<Toolbar>
				<Item location='after'>
					<div className={"total-label"}>
						<span>Total Records: </span>
						<label>{totalCount}</label>
					</div>
				</Item>
				<Item location='after'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>{dataGridSettings.mode}</label>
					</div>
				</Item>
				<Item>
					<Button
						icon={IconFilter}
						className='dx-toolbar-item'
						hint='Clean Filters'
						onClick={clearFiltersDG}
					/>
				</Item>
				<Item>
					<Button
						icon='xlsxfile'
						className='dx-toolbar-item'
						hint='Export'
						onClick={onExporting}
						disabled={!disableSearchBtn}
					/>
				</Item>
			</Toolbar>
			<ColumnFixing enabled={true} />
			<Selection mode='single' />
			<RemoteOperations groupPaging={true} />
			<Export enabled={true} />
			<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
			<Column dataField='id' caption='No.' visible={false} />
			<Column dataField='sewsPartNo' caption='SEWS Part No.' />
			<Column dataField='sewsDesignLevel' caption='SEWS Design Level' />
			<Column dataField='custPartNumber' caption='Cust. Part No.' />
			<Column dataField='custDesignLevel' caption='Customer DL' />
			<Column dataField='supplierCode' caption='Supplier Code' visible={showDGColumns} />
			<Column dataField='customerCodeDetail' caption='Cust. Code' visible={showDGColumns} />
			<Column dataField='mfgPlant' caption='Mfg. Plant'></Column>
			<Column dataField='boxSerialNumber' caption='Box Serial No.' visible={showDGColumns} />
			<Column dataField='qty' caption='Qty' visible={showDGColumns} />
			<Column dataField='customerCodeSummary' caption='Customer' visible={!showDGColumns}></Column>
			<Column
				dataField='carryOver'
				caption='Carry over from prev. Year'
				visible={!showDGColumns}></Column>
			<Column dataField='returned' caption='Returned' visible={!showDGColumns}></Column>
			<Column dataField='reShipped' caption='Re-Shipped' visible={!showDGColumns}></Column>
			<Paging defaultPageSize={10} defaultPageIndex={0} />
			<Pager
				visible={true}
				allowedPageSizes={allowedPageSizes}
				displayMode='full'
				showPageSizeSelector={dataGridSettings.showPageSizeSelector}
				showInfo={dataGridSettings.showInfo}
				showNavigationButtons={dataGridSettings.showNavButtons}
			/>
			<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
		</DataGrid>
	);
};
