import React, {useState, useRef, useEffect} from "react";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	ColumnFixing,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export
} from "devextreme-react/data-grid";
import store from "../../components/hooks/store";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import {alert, confirm} from "devextreme/ui/dialog";
import {LoadPanel} from "devextreme-react/load-panel";
import IconFilter from "../../components/filter.svg";

export default () => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const [totalCount, setTotalCount] = useState(0);
	const [customDataSource, setCustomDataSource] = useState(null);
	const [disableSearchBtn, setdisableSearchBtn] = useState(true);
	const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);
	const [disableConfirmAllBtn, setConfirmAllBtn] = useState(null);

	const dataGrid = useRef(null);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let formatDate = currentUser ? currentUser.data.userData.formatDate : '';
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(currentUser, "ImportExport", "RetransmitASNCentraltoCustoms");
	const [loadPanelVisible, setLoadPanelVisible] = useState(false);

	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	/* Instance components in order to reset them when necessary */
	const dateBox = useRef(null);
	const currentDate = new Date();

	const [showTootlTip, setShowTooltip] = useState(true);
	const [dateValue, setDateVal] = useState();
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const sendReportRequest = () => {
		let dateSelected = JSON.stringify(dateValue);
		dateSelected = dateSelected.replace(/"/g, "");
		setCustomDataSource(
			store({
				url: `${appInfo.importURL}RetransmitASNCentralToCustoms/AsnNumbers?date=${dateSelected}`
			})
		);
		setDisableConfirmBtn(false);
		setShowTooltip(false);
		setfirstTimeLoading(false);
	};

	const clearFilters = () => {
		setDateVal(currentDate);
		setShowTooltip(true);
		setDisableConfirmBtn(false);
		setCustomDataSource({
			url: `${appInfo.reportURL}RetransmitASNCentralToCustoms`
		});
		sessionStorage.removeItem("totalCount");
		sessionStorage.setItem("totalCount", 0); //reset totalcount
	};
	const getDate = (e) => {
		setDateVal(e.value);
		if (e.value !== null) {
			setdisableSearchBtn(false);
		}
	};
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const confirmAll = async () => {
		const dialogResult = await confirm("[5661] Are you sure you want to retransmit all ASN?");
		if (dialogResult) {
			let dateSelected = JSON.stringify(dateValue);
			dateSelected = dateSelected.replace(/"/g, "");
			changeLoadPanelStatus(true);
			setDisableConfirmBtn(true);
			await fetch(
				`${appInfo.importURL}RetransmitASNCentralToCustoms/Retransmit?asnNumber=&date=${dateSelected}&all=true`,
				{
					method: "post",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json"
					}
				}
			)
				.then((response) => response.json())
				.then((response) => {
					changeLoadPanelStatus(false);
					alert(response.message);
				});
		}
	};
	const confirmAction = async () => {
		let selectedRows = dataGrid.current.instance.getSelectedRowsData();
		if (selectedRows.length === 0) {
			alert("Please select ASN Number");
		} else {
			const dialogResult = await confirm("[5661] Are you sure you want to retransmit ASN?");
			if (dialogResult) {
				changeLoadPanelStatus(true);
				setDisableConfirmBtn(true);

				let getASNnumbers = selectedRows.map((row) => {
					return row.asnNumber;
				});
				let asnNumberString = getASNnumbers.toString();

				await fetch(
					`${appInfo.importURL}RetransmitASNCentralToCustoms/Retransmit?date=&all=false&asnNumber=${asnNumberString}`,
					{
						method: "post",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json"
						}
					}
				)
					.then((response) => response.json())
					.then((response) => {
						changeLoadPanelStatus(false);
						alert(response.message);
					})
					.catch(err => console.warn(err));
			}
		}
	};
	const changeLoadPanelStatus = (boolValue) => {
		if (!boolValue) {
			setCustomDataSource({
				url: `${appInfo.reportURL}RetransmitASNCentralToCustoms`
			});
			dateBox.current.instance.reset();
			sessionStorage.setItem("totalCount", 0); //reset totalcount
		}
		setLoadPanelVisible(boolValue);
	};
	const onSelectionChanged = (e) => {
		if (e.selectedRowKeys.length > 0) {
			setConfirmAllBtn(true);
		} else {
			setConfirmAllBtn(false);
		}
	};
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Retransmit ASN (Central) to Customs</h2>
					</div>
				</div>
				<form className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>ASN Date</div>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								ref={dateBox}
								useMaskBehavior={true}
								placeholder='(None)'
								displayFormat={formatDate}
								width='200px'
								value={dateValue}
								dateSerializationFormat='yyyy/MM/dd'
								onValueChanged={getDate}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#findButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<LoadPanel visible={loadPanelVisible} showIndicator={true} shading={true} showPane={true} />
				<DataGrid
					elementAttr={{
						id: "RetransmitContainer"
					}}
					dataSource={customDataSource}
					ref={dataGrid}
					showBorders={true}
					columnResizingMode='widget'
					columnMinWidth={100}
					allowColumnResizing={true}
					onContentReady={getTotalCount}
					onSelectionChanged={onSelectionChanged}>
					<Toolbar>
						<Item location='before'>
							<div>
								<Button
									icon='checklist'
									className='dx-toolbar-item'
									hint='Confirm'
									text='Confirm'
									disabled={disableConfirmBtn}
									onClick={confirmAction}
								/>
							</div>
						</Item>
						<Item location='before'>
							<div>
								<Button
									icon='selectall'
									className='dx-toolbar-item'
									disabled={disableConfirmAllBtn}
									hint='Confirm All'
									text='Confirm All'
									onClick={confirmAll}
								/>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location='after'>
							<div className={"total-label"}>
								<span> Mode: </span>
								<label>{dataGridSettings.mode}</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className='dx-toolbar-item'
								hint='Clean Filters'
								onClick={clearFiltersDG}
							/>
						</Item>
					</Toolbar>
					<ColumnFixing enabled={true} />
					<Selection mode='multiple' allowSelectAll={false} showCheckBoxesMode='always' />
					<RemoteOperations groupPaging={true} />
					<Export enabled={true} />
					<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
					<Column dataField='id' caption='ID' visible={false} />
					<Column dataField='plantCode' caption='Plant Code' />
					<Column dataField='asnNumber' caption='ASN Number' />
					<Column dataField='shipDate' caption='Ship Date' dataType='date' format={formatDate} />
					<Paging defaultPageSize={10} defaultPageIndex={0} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode='full'
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
