import axios from "axios";
import appInfo from "../app-info";
import notify from "devextreme/ui/notify";

export async function signIn(
	username,
	password,
	servername,
	isFavorite,
	zone,
	isAdmin,
	domain
) {
	let userLogin = null;
	let status = false;
	try {
		await axios({
			method: "post",
			url: `${appInfo.authURL}authentication/token`,
			data: {
				username: username,
				password: password,
				servername: servername,
				isFavorite: isFavorite,
				zone: zone,
				isAdmin: isAdmin,
				domain: domain
			},
		}).then((res) => {
			userLogin = res.data;
			res.data.token === null ? (status = false) : (status = true);
			/* Include languages and format Date before return userData */
			if(res.data.token!== null){
				let tempLang = navigator.language;
				let langFormat = tempLang.split("-");
				let formatDate;
				userLogin.userData.language = langFormat[0].toUpperCase();
				userLogin.userData.language === "ES" ? (formatDate = "dd/MM/yyyy") : (formatDate = "MM/dd/yyyy");
				userLogin.userData.formatDate = formatDate;
			}
	
			
		});

		return {
			isOk: status,
			data: userLogin,
		};
	} catch (e) {
		let message = e.response.data.message;
		if (e.response.data.message) {
			notify(message, "error", 3000);
		}
		return {
			isOk: false,
			message: message,
		};
	}
}

export async function getUser() {
	try {
		let userData = JSON.parse(sessionStorage.getItem("currentUser"));
		
		return {
			isOk: true,
			data: userData,
		};
	} catch {
		return {
			isOk: false,
		};
	}
}

export async function createAccount(username, password) {
	try {
		// Send request

		return {
			isOk: true,
		};
	} catch {
		return {
			isOk: false,
			message: "Failed to create account",
		};
	}
}

export async function changePassword(
	password,
	oldPassword,
	username,
	servername
) {
	let resetObject = {
		username: username,
		newPassword: password,
		oldPassword: oldPassword,
		confirmPassword: password,
		servername: servername,
	};
	try {
		// Send request
		fetch(`${appInfo.authURL}authentication/password/change`, {
			method: "post",
			body: JSON.stringify(resetObject),
			headers: {"Content-Type": "application/json"},
		})
			.then((result) => {
				if (result.ok) {
					return {
						status: result.data,
						isOk: result.ok,
					};
				}

				if (!result.ok) {
					return result.text().then((text) => {
						notify(
							text.replace(/{"message":"/g, "").replace(/"}/g, ""),
							"error",
							2000
						);
					});
				}
			})
			.catch((error) => console.warn(error));

		return {
			isOk: true,
		};
	} catch {
		return {
			isOk: false,
			message: "Failed to change password",
		};
	}
}

export async function resetPassword(username, servername) {
	let user = {username: username, servername: servername};

	try {
		//Send request
		fetch(`${appInfo.authURL}authentication/password/reset`, {
			method: "post",
			body: JSON.stringify(user),
			headers: {"Content-Type": "application/json"},
		})
			.then((result) => {
				return {
					status: result.data,
				};
			})
			.catch((error) => console.warn(error));

		return {
			isOk: true,
		};
	} catch {
		return {
			isOk: false,
			message: "Failed to reset password",
		};
	}
}
