import React, {useState, useEffect, useRef} from "react";
import DataGrid, {
	Column,
	Paging,
	ColumnFixing,
	FilterRow,
	Pager,
	RemoteOperations,
	Toolbar,
	Item,
	Scrolling,
	Selection
} from "devextreme-react/data-grid";
import {NumberBox} from "devextreme-react/number-box";
import DateBox from "devextreme-react/date-box";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";
import {Button} from "devextreme-react/button";
import axios from "axios";
import appInfo from "../../app-info";
import {alert} from "devextreme/ui/dialog";
import {Redirect} from "react-router-dom";
import {getPermission} from "../configuration/helpers/globalFunctions";
import Box from "devextreme-react/box";

export default ({customDataSource, plantId}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	const [disablePrintBtn, setDisablePrintBtn] = useState(true);

	let allFieldsActive = false;
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	let activePlant = sessionStorage.getItem("currentPlant");
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(currentUser, "DemandPrinting", "BoxLabelPrinting");

	const [printers, setPrinters] = useState(null);
	const [enableSpeed, setEnableSpeed] = useState(false);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	const [customQtyBox, setCustomQtyBox] = useState(0);
	const [customLabelCopies, setcustomLabelCopies] = useState(0);
	const [customNumLabel, setCustomNumLabel] = useState(0);
	const [printDate, setPrintDate] = useState(new Date());
	const [totalCount, setTotalCount] = useState(0);
	const [enableNumCopies, setEnableNumCopies] = useState(false);
	const [extraInput, setExtraInput] = useState(null);
	const [showExtraInput, setShowExtraInput] = useState(false);

	const [boxDetail, setBoxDetail] = useState();
	const [customSpeed, SetCustomSpeed] = useState(2);
	const [printFieldStatus, setPrintFieldStatus] = useState({
		labelSelected: false,
		qtyInBox: true,
		noOfLabel: true,
		labelCopies: true,
		prodDate: true,
		speed: true,
		printer: false,
		extraInput: true
	});
	const [printDetails, setPrintDetails] = useState({
		PrinterName: "",
		QuantityInBox: 0,
		NumberOfLabels: 0,
		NumberOfCopies: 0,
		PlantId: activePlant,
		Speed: 2,
		ProductionDate: "",
		KEYVALUE: "",
		LabelFormat: "",
		BroadCastCd: "",
		ExtraInput: ""
	});

	useEffect(() => {
		let getPrinters = `${appInfo.demandURL}BoxLabelPrinting/Printers`;
		(async () => {
				await axios
					.get(getPrinters, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						let customPrint = result.data.map((print) => {
							return print.value;
						});
						setPrinters(customPrint);
					}).catch((err) => {
				console.error(err, "Box printers fail request");
			})

			dataGrid.current.instance.deselectAll();
			setPrintFieldStatus((oldState) => {
				return {...oldState, labelSelected: false};
			});
			setBoxDetail();
			setPrintDetails((oldState) => {
				return {
					...oldState,
					PlantId: activePlant, //plantId
					KEYVALUE: ""
				};
			});
		})();
	}, []);
	useEffect(() => {
		allFieldsActive = true;
		for (let field in printFieldStatus) {
			if (printFieldStatus[field] == false) {
				allFieldsActive = false;
			}
		}
		setDisablePrintBtn(!allFieldsActive);
	}, [printFieldStatus]);
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const onInitialized = (e) => {
		setPrintDetails((oldState) => {
			return {...oldState, ProductionDate: printDate};
		});
		enablePrintBtn(e);
	};

	const onSelectionChanged = async (e) => {
		if (e.selectedRowsData.length > 0) {
			//Make sure selectedRowsData has data selected
			let rawArray = [
				"plantId",
				"sewsPartNo",
				"sewsDesginLevel",
				"seqNo",
				"sewsstscd",
				"shortPartNo",
				"modelYear",
				"customerCode",
				"backKanban",
				"custPartNo",
				"custSTSCd",
				"labelFormat"
			];
			let getDetailsURL = `${appInfo.demandURL}BoxLabelPrinting/GetDetail`;
			let rowSelected = e.selectedRowsData[0];
			let params = `?plantId=${plantId}&`;
			setEnableSpeed(rowSelected.enableSpeed);
			const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
			let setSpeed = rowSelected !== undefined ? parseInt(rowSelected.speed) : 2;
			SetCustomSpeed(setSpeed); //update Speed Field
			setEnableNumCopies(rowSelected.enablePrintCopy);

			if (rowSelected.inputName !== "") {
				setShowExtraInput(true);
				setExtraInput(rowSelected.inputName);
				setPrintFieldStatus((oldState) => {
					return {...oldState, extraInput: true};
				});
			}
			for (let i in rowSelected) {
				if (rawArray.indexOf(i) != -1 && isNotEmpty(rowSelected[i])) {
					params += `${i}=${rowSelected[i]}&`;
				}
			}
			// INFO: This request fill the second DG
			await axios({
				method: "get",
				url: `${getDetailsURL}${params}`,
				body: rowSelected,
				headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json"}
			})
				.then((result) => {
					if (result.data[0]) {
						setBoxDetail(result.data);
						setPrintDetails((oldState) => {
							return {
								...oldState,
								QuantityInBox: parseInt(result.data[0].piecesperbox),
								KEYVALUE: result.data[0].keyvalue
							};
						});
						setCustomQtyBox(result.data[0].piecesperbox);
					}
				})
				.catch((error) => console.warn(error));

			//INFO: this fill the printer details

			setPrintFieldStatus((oldState) => {
				return {...oldState, labelSelected: true};
			});
			setcustomLabelCopies(rowSelected.labelCopies);

			setPrintDetails((oldState) => {
				return {
					...oldState,
					NumberOfCopies: parseInt(rowSelected.labelCopies),
					BroadCastCd: rowSelected.broadCastCd,
					LabelFormat: rowSelected.labelFormat
				};
			});
		}
	};
	const enablePrintBtn = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;
		switch (fieldName) {
			case "qtyInBox":
				if (currentFieldVal != "") {
					let qtyBoxType = parseInt(currentFieldVal);
					setPrintFieldStatus((oldState) => {
						return {...oldState, qtyInBox: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, QuantityInBox: qtyBoxType};
					});
					setCustomQtyBox(currentFieldVal);
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, qtyInBox: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, QuantityInBox: currentFieldVal};
					});
					setCustomQtyBox(currentFieldVal);
				}
				break;
			case "noOfLabel":
				if (currentFieldVal != "") {
					let noLabelType = parseInt(currentFieldVal);
					setPrintFieldStatus((oldState) => {
						return {...oldState, noOfLabel: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, NumberOfLabels: noLabelType};
					});
					setCustomNumLabel(currentFieldVal);
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, noOfLabel: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, NumberOfLabels: currentFieldVal};
					});
					setCustomNumLabel(currentFieldVal);
				}
				break;
			case "labelCopies":
				if (currentFieldVal != "") {
					let labelType = parseInt(currentFieldVal);
					setPrintFieldStatus((oldState) => {
						return {...oldState, labelCopies: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, NumberOfCopies: labelType};
					});
					setcustomLabelCopies(currentFieldVal);
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, labelCopies: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, NumberOfCopies: currentFieldVal};
					});
					setcustomLabelCopies(currentFieldVal);
				}
				break;
			case "prodDate":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodDate: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ProductionDate: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodDate: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ProductionDate: currentFieldVal};
					});
				}
				break;
			case "speed":
				if (currentFieldVal != "") {
					let speedtype = parseInt(currentFieldVal);
					setPrintFieldStatus((oldState) => {
						return {...oldState, speed: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, Speed: speedtype};
					});
					SetCustomSpeed(currentFieldVal);
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, speed: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, Speed: currentFieldVal};
					});
					SetCustomSpeed(currentFieldVal);
				}
				break;
			case "printer":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, printer: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, PrinterName: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, printer: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, PrinterName: currentFieldVal};
					});
				}
				break;
			case "extraInput":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, extraInput: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ExtraInput: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, extraInput: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ExtraInput: currentFieldVal};
					});
				}
				break;
		}
	};
	const sendPrintDetails = async (e) => {
		let token = JSON.parse(sessionStorage.getItem("currentUser"));
		token = token ? token.data.token : '';
		let printDesc = printDetails == undefined ? {} : JSON.stringify(printDetails);
		let printURL = `${appInfo.demandURL}BoxLabelPrinting/Print`;

		await axios({
			method: "POST",
			url: printURL,
			data: printDesc,
			headers: {
				Authorization: `Bearer ${token}`,
				"Accept-Language": `${language}`,
				"Content-Type": "application/json"
			}
		})
			.then((result) => {
				alert(result.data);
			})
			.catch((error) => {
				if (error.response) {
					let validations = error.response.data.errors;
					let message = "";
					if (validations !== undefined) {
						Object.keys(validations).map((field) => {
							let fieldValues = validations[field];
							message += fieldValues[0] + "<br/> ";
						});
						alert("<i class='dx-icon-close' id='cancel-icon'></i><br/><br/>" + message);
					}
				}
			});
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "boxGridContainer"
				}}
				ref={dataGrid}
				dataSource={customDataSource}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				onContentReady={getTotalCount}
				onSelectionChanged={onSelectionChanged}>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>{dataGridSettings.mode}</label>
						</div>
					</Item>
				</Toolbar>
				<Selection mode='single' />
				<RemoteOperations groupPaging={true} />
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<Column
					dataField='id'
					caption='No.'
					visible={false}
					cssClass='cell-bold small-space'
				/>
				<Column dataField='sewsPartNo' cssClass='cell-bold small-space' caption='SEWS Part No.' />
				<Column
					dataField='sewsDesginLevel'
					cssClass='cell-bold small-space'
					caption='Design Change'
				/>
				<Column dataField='seqNo' cssClass='cell-bold small-space' caption='Seq No' />
				<Column dataField='sewsstscd' cssClass='cell-bold small-space' caption='STS' />
				<Column dataField='shortPartNo' cssClass='cell-bold small-space' caption='Short Part No.' />
				<Column dataField='modelYear' cssClass='cell-bold small-space' caption='Year' />
				<Column dataField='customerCode' cssClass='cell-bold small-space' caption='Cust. Code' />
				<Column dataField='backKanban' cssClass='cell-bold small-space' caption='Extra Info' />
				<Column dataField='custPartNo' cssClass='cell-bold small-space' caption='Cust. Part No.' />
				<Column dataField='custSTSCd' cssClass='cell-bold small-space' caption='STS' />
				<Column dataField='labelFormat' cssClass='cell-bold small-space' caption='Label Format' />
				<Column dataField='broadCastCd' cssClass='cell-bold small-space' caption='Broad Cast Cd' />
				<Column dataField='color' cssClass='cell-bold small-space' caption='Color' />
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
			</DataGrid>

			<DataGrid
				elementAttr={{
					id: "boxGridDetailsContainer"
				}}
				dataSource={boxDetail}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				onContentReady={getTotalCount}>
				<ColumnFixing enabled={true} />
				<Column dataField='id' caption='No' visible={false} cssClass='cell-bold' width={100} />
				<Column dataField='custDesignLevel' cssClass='cell-bold' caption='Customer DL' />
				<Column dataField='custDesc' cssClass='cell-bold' caption='Customer Description' />
				<Column dataField='lblHrPartNo' cssClass='cell-bold' caption='Label HR PN' />
				<Column dataField='lblBarPartNo' cssClass='cell-bold' caption='Label BC PN' />
				<Column dataField='lblHrDesign' cssClass='cell-bold' caption='Label HR DL' />
				<Column dataField='lbBarDesign' cssClass='cell-bold' caption='Label BC DL' />
			</DataGrid>
			<Box direction='row' height={105} className={"printing-details"}>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						&nbsp; &nbsp; Quantity in Box
						<NumberBox
							min={0}
							max={9999}
							showSpinButtons={true}
							value={customQtyBox}
							width='110px'
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "qtyInBox"
							}}
						/>
					</div>
				</Item>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						No. of Labels
						<NumberBox
							min={0}
							max={999999}
							showSpinButtons={true}
							width='110px'
							onValueChanged={enablePrintBtn}
							value={customNumLabel}
							elementAttr={{
								id: "noOfLabel"
							}}
						/>
					</div>
				</Item>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						Label Copies
						<NumberBox
							value={customLabelCopies}
							min={0}
							max={999999}
							showSpinButtons={true}
							width='110px'
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "labelCopies"
							}}
							disabled={!enableNumCopies}
						/>
					</div>
				</Item>
				<Item ratio={0} baseSize={180}>
					<div className='rect demo-dark'>
						Production Date
						<DateBox
							defaultValue={new Date()}
							type='date'
							width='140px'
							onInitialized={onInitialized}
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "prodDate"
							}}
							displayFormat=' dd/MM/yyyy'
							pickerType='calendar'
							min={printDate}
						/>
					</div>
				</Item>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						&nbsp;&nbsp; Speed
						<NumberBox
							value={customSpeed}
							min={2}
							max={10}
							showSpinButtons={true}
							width='110px'
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "speed"
							}}
							disabled={!enableSpeed}
						/>
					</div>
				</Item>
				{showExtraInput ? (
					<Item ratio={0} baseSize={180}>
						<div className='extraInput'>
							{extraInput}
							<TextBox
								items={printers}
								width='150px'
								onValueChanged={enablePrintBtn}
								elementAttr={{
									id: "extraInput"
								}}
							/>
						</div>
					</Item>
				) : null}
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						Printer
						<SelectBox
							items={printers}
							width='150px'
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "printer"
							}}
						/>
					</div>
				</Item>

				<Item ratio={1} baseSize={150}>
					<div className='rect demo-dark'>
						<Button
							icon='print'
							text='Print'
							elementAttr={{
								id: "printButton"
							}}
							onClick={sendPrintDetails}
							disabled={disablePrintBtn}
						/>
					</div>
				</Item>
			</Box>
		</React.Fragment>
	);
};
