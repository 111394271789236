import React, { useState, useEffect, useRef } from 'react';
import SelectBox from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';
import appInfo from '../../app-info';

let token = '';
if (sessionStorage.getItem("currentUser") !== null) {
  let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  token = currentUser.data.token;
}
const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

const custPartNoStore = new DataSource({
  key: "Id",
  load: (loadOptions) => {
    let params = '&';
    [
      'skip',
      'take',
      'searchExpr',
      'searchValue',
    ].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);
    return fetch(`${appInfo.reportURL}ReturnProductsReport/CustPartNumbers?${params}`, {
      method: 'get',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
      .then(response => {
        return response.data;
      });
  }, byKey: (key) => {
    //Do Not Delete By Key method. Deve Express need it not sure for what
  },
});
const sewsPartNoStore = new DataSource({
  key: "Id",
  load: (loadOptions) => {
    let params = '&';
    [
      'skip',
      'take',
      'searchExpr',
      'searchValue',
    ].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);
    return fetch(`${appInfo.reportURL}ReturnProductsReport/SEWSPartNumbers?${params}`, {
      method: 'get',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => response.json())
      .then(response => {
        return response.data;
      });
  }, byKey: (key) => {
    //Do Not Delete By Key method. Deve Express need it not sure for what
  },
});
export default ({ showSelectBox, callbackSelectBoxPartNum, disableFilters }) => {
  const cust = useRef();
  const sews = useRef();
  useEffect(() => {
    window.addEventListener('resetFilters', () => {
      if (showSelectBox) {
        cust.current.instance.reset();
      } else {
        sews.current.instance.reset();
      }
    })
  }, []);
  const onValueChange = (e) => {
    callbackSelectBoxPartNum(e.value);
  }
  return (
    <div className="dx-field">
      <SelectBox
        placeholder="Cust. Part No."
        elementAttr={{
          id: 'custPartNo'
        }}
        ref={cust}
        onValueChanged={onValueChange}
        dataSource={custPartNoStore}
        showClearButton={true}
        valueExpr="Value"
        searchEnabled={true}
        disabled={disableFilters}
        displayExpr="Value"
        width='170px'
        visible={showSelectBox}
      />
      <SelectBox
        placeholder="SEWS Part. No."
        elementAttr={{
          id: 'sewsPartNo'
        }}
        ref={sews}
        valueExpr="Value"
        displayExpr="Value"
        width='175px'
        dataSource={sewsPartNoStore}
        disabled={disableFilters}
        onValueChanged={onValueChange}
        showClearButton={true}
        searchEnabled={true}
        visible={!showSelectBox}
      />
    </div>
  )
}