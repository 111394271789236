import React, {useState, useRef, useCallback, useMemo, useEffect} from "react";
import {Link} from "react-router-dom";
import Form, {
	SimpleItem,
	Item,
	RequiredRule,
	Label,
	ButtonItem,
	ButtonOptions,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import {useAuth} from "../../contexts/auth";
import appInfo from "../../app-info";
import "./login-form.scss";
import DataSource from "devextreme/data/data_source";
import {Template} from "devextreme-react/core/template";
import ConditionalIcon from "./conditionalIcon";
import ItemStatus from "./server-status";
import {buildMenuTree} from "./build-permissionTree";

export default function () {
	const {signIn} = useAuth();
	const [loading, setLoading] = useState(false);
	const formData = useRef({});
	const [serverNames, setServerNames] = useState(null);
	const [domainNames, setDomainNames] = useState([]);
	const [selectedDomain, setSelectedDomain]= useState();
	useEffect(() => {
		(async () => {
			await fetch(`${appInfo.authURL}authentication/domains`,
				{
					method:'get'
				})
				.then((response)=> response.json())
				.then((response) => {
					let names = response.map((dom)=> dom.name);
					setDomainNames(names);
				})
		})();	
	},[]);
	const userEditorOptions = {
		stylingMode: "filled",
		placeholder: "Username",
		maskRules: {X: /^[^0-9]+$/},
		onValueChanged: async (e) => {
			await fetch(`${appInfo.authURL}authentication/servernames?username=${e.value}`,
				{
					method: "get",
				})
				.then((result) => result.json())
				.then((result) => {
					setServerNames(result);
				});
			await fetch(`${appInfo.authURL}authentication/domainname?username=${e.value}`,
				{
					method: "get",
				})
				.then((result) => result.json())
				.then((result)=> {
				if(result.name){
					setSelectedDomain(result.name);
				}else{
					setSelectedDomain("NODOMAIN");
				}
			})
		},
	};
	const checkBoxOptions = {
		text: "Save this server in favorites",
		defaultValue: false,
	};
	const fromPregroupedData = new DataSource({
		store: {
			type: "array",
			data: serverNames,
			key: "id",
		},
		group: "category",
	});

	const renderConditionIcon = (props) => {
		return <ConditionalIcon category={props.key} />;
	};
	const statusIcon = (args) => {
		return <ItemStatus args={args} />;
	};
	const serverDropDownOptions = {
		dataSource: fromPregroupedData,
		displayExpr: "name",
		valueExpr: "name",
		grouped: true,
		groupTemplate: "conditionalIcon",
		itemTemplate: "statusIcon",
		searchEnabled: true,
		onSelectionChanged: (e) => {
			formData.current.zone = e.selectedItem.zone;
		},
	};
	const domainDropDownOptions = {
		items: domainNames,
		searchEnabled: true,
		value:selectedDomain,
	};
	const onSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			const {username, password, servername, zone, isAdmin, domain} = formData.current;
			let {isFavorite} = formData.current;
			if (!isFavorite) {
				isFavorite = false;
			}
			setLoading(true);
			setSelectedDomain(domain);
			const result = await signIn(
				username,
				password,
				servername,
				isFavorite,
				zone,
				isAdmin,
				domain
			);

			if (result.isOk) {
				buildMenuTree(result);
			} else {
				setLoading(false);
			}
		},
		[signIn]
	);

	return (
		<form className={"login-form"} onSubmit={onSubmit}>
			<Form formData={formData.current} disabled={loading}>
				<Item
					dataField={"username"}
					editorType={"dxTextBox"}
					editorOptions={userEditorOptions}>
					<RequiredRule message="Username is required" />
					<Label visible={false} />
				</Item>
				<Item
					dataField={"password"}
					editorType={"dxTextBox"}
					editorOptions={passwordEditorOptions}>
					<RequiredRule message="Password is required" />
					<Label visible={false} />
				</Item>
				<SimpleItem
					editorType="dxSelectBox"
					dataField={"servername"}
					editorOptions={serverDropDownOptions}>
					<RequiredRule message="Server name is required" />
					<Template name="conditionalIcon" render={renderConditionIcon} />
					<Template name="statusIcon" render={statusIcon} />
				</SimpleItem>
				<SimpleItem
					dataField="isFavorite"
					editorType="dxCheckBox"
					editorOptions={checkBoxOptions}>
					<Label visible={false} />
				</SimpleItem>
				<SimpleItem
					editorType="dxSelectBox"
					dataField={"domain"}
					editorOptions={domainDropDownOptions}>
					<RequiredRule message="Domain is required" />
				</SimpleItem>
				<Item
					dataField={"isAdmin"}
					editorType={"dxTextBox"}
					editorOptions={isAdminEditorOptions}>
					<Label visible={false} />
				</Item>
				<ButtonItem>
					<ButtonOptions
						width={"100%"}
						type={"default"}
						useSubmitBehavior={true}>
						<span className="dx-button-text">
							{loading ? (
								<LoadIndicator width={"24px"} height={"24px"} visible={true} />
							) : (
								"Login"
							)}
						</span>
					</ButtonOptions>
				</ButtonItem>
				<Item>
					<div className={"link"}>
						<Link to={"/reset-password"}>Forgot password?</Link>
					</div>
				</Item>
				<Item>
					<div className={"link"}>
						<Link to={"/login-admin"}>
							<span className="dx-icon-preferences icon"></span>Manage Servers
						</Link>
					</div>
				</Item>
			</Form>
		</form>
	);
}

const passwordEditorOptions = {
	stylingMode: "filled",
	placeholder: "Password",
	mode: "password",
};
const isAdminEditorOptions = {
	stylingMode: "filled",
	value: false,
	visible: false,
};
