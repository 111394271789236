import React, {useState, useRef} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	ColumnFixing,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export,
	AsyncRule,
	RequiredRule
} from "devextreme-react/data-grid";
import {getPermission, setMode, getMode} from "../configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import {Button} from "devextreme-react/button";
import {confirm, alert} from "devextreme/ui/dialog";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, firstTimeLoading, params, stateUp}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	const [totalCount, setTotalCount] = useState(0);
	let finalMsg = "";
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser.data.userData.language;
	let token = currentUser.data.token;
	let customMsg = "";
	let HarnessSNoAdded = [];
	const permission = getPermission(currentUser, "ReworkReinspection", "NoboxRelabeling");
	let formatDate = currentUser.data.userData.formatDate;
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});

	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const onRowUpdating = async (e) => {
		let mergeData = {...e.oldData, ...e.newData};
		mergeData.id = mergeData.id.toString();
		e.newData = mergeData;
		e.newData.PlantId = params.plantId;
		e.newData.QTY = params.quantity;
		e.newData.boxLabelNew = params.boxLabelNew;
		e.newData.boxLabelOld = params.boxLabelOld;
		e.newData.HarnessOption = params.optionHarness;
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
	};

	function asyncValidation(cellParams) {
		if (cellParams.value == null) {
			return;
		}
		let customURL = `?plantId=${params.plantId}&boxLabelOld=${params.boxLabelOld}&boxLabelNew=${params.boxLabelNew}&harnessOption=${params.optionHarness}&destHarness=${cellParams.value}`;
		return new Promise((resolve, reject) => {
			fetch(`${appInfo.reworkURL}BoxRelabeling/IsValid/${customURL}`, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((response) => response.json())
				.then((result) => {
					resolve({
						isValid: result.isValid,
						message: result.message
					});
				});
		});
	}
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onRowPrepared = (e) => {
		if (e.rowType === "data" && e.data.destHarnessSno !== null && !e.modified) {
			stateUp(true);
		}
		if (e.rowType === "data" && e.data.destHarnessSno !== "" && e.modified) {
			if (HarnessSNoAdded.length > 0) {
				const repeatedKeyandValue = HarnessSNoAdded.some(
					(harness) => harness.id === e.data.id && harness.destHarnessSno === e.data.destHarnessSno
				); //check if row already exist
				const foundRepeatValue = HarnessSNoAdded.some(
					(harness) => harness.destHarnessSno === e.data.destHarnessSno
				); //check if the value exist
				const repetedKey = HarnessSNoAdded.some((harness) => harness.id === e.data.id);
				if (foundRepeatValue && !repeatedKeyandValue) {
					alert("[20007] Record Already Exist!");
					e.component.cellValue(e.rowIndex, "destHarnessSno", "");
					e.component.focus();
				}
				if (!foundRepeatValue && repetedKey) {
					//if the destHarnessSno is new but the key already exist, just update the destHarnessSno
					HarnessSNoAdded.map((item) => {
						if (item.id === e.data.id) {
							item.destHarnessSno = e.data.destHarnessSno;
						}
					});
				}
				if (!foundRepeatValue && !repetedKey) {
					HarnessSNoAdded.push(e.data);
				}
			} else {
				HarnessSNoAdded.push(e.data);
			}
		}
	};
	return (
		<DataGrid
			elementAttr={{
				id: "InventoryGridContainer"
			}}
			ref={dataGrid}
			showBorders={true}
			dataSource={customDataSource}
			allowColumnResizing={true}
			columnResizingMode='nextColumn'
			columnAutoWidth={true}
			columnMinWidth={100}
			onContentReady={getTotalCount}
			onRowUpdating={onRowUpdating}
			onRowUpdated={onRowUpdated}
			onSaving={onSaving}
			onSaved={onSaved}
			onRowPrepared={onRowPrepared}>
			<Toolbar>
				<Item location='after'>
					<div className={"total-label"}>
						<span>Total Records: </span>
						<label>{totalCount}</label>
					</div>
				</Item>
				<Item location='after'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>{dataGridSettings.mode}</label>
					</div>
				</Item>
				<Item>
					<Button
						icon={IconFilter}
						className='dx-toolbar-item'
						hint='Clean Filters'
						onClick={clearFiltersDG}
					/>
				</Item>
				{/* <Item name="addRowButton" /> */}
				<Item name='revertButton' />
				<Item name='saveButton' />
			</Toolbar>
			<ColumnFixing enabled={true} />
			<Selection mode='single' />
			<RemoteOperations groupPaging={true} />
			<Export enabled={true} />
			<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
			<Editing
				mode='batch'
				startEditAction={dataGridSettings.startEditAction}
				allowUpdating={permission.update}
				texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
				refreshMode='full'
			/>
			<Column dataField='id' caption='No.' visible={false} alignment='center' allowEditing={false} />
			<Column
				dataField='destHarnessSno'
				caption='Harness SNo'
				alignment='center'
				allowEditing={true}>
				<AsyncRule validationCallback={asyncValidation}></AsyncRule>
				<RequiredRule />
			</Column>
			<Column
				dataField='sewsPartNo'
				caption='SEWS Part No.'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='sewsDesignLevel'
				caption='SEWS Design Level'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='shortPartNo'
				caption='Short Part No.'
				alignment='center'
				allowEditing={false}
			/>
			<Column dataField='custCode' caption='Cust. code' alignment='center' allowEditing={false} />
			<Paging defaultPageSize={10} defaultPageIndex={0} />
			<Pager
				visible={true}
				allowedPageSizes={allowedPageSizes}
				displayMode='full'
				showPageSizeSelector={dataGridSettings.showPageSizeSelector}
				showInfo={dataGridSettings.showInfo}
				showNavigationButtons={dataGridSettings.showNavButtons}
			/>
			<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
		</DataGrid>
	);
};
