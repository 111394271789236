import React, {useState, useRef, useEffect} from "react";
import DataGrid, {
	Column,
	Paging,
	Pager,
	Toolbar,
	Item,
	FilterRow,
	ColumnFixing,
	RemoteOperations,
	Scrolling,
	Export,
	MasterDetail
} from "devextreme-react/data-grid";
import IconFilter from "../../components/filter.svg";
import {Button} from "devextreme-react/button";
import {exportDataGrid} from "devextreme/excel_exporter";
import {Workbook} from "exceljs";
import saveAs from "file-saver";
import OverShipmentDetails from "./OverShipmentDetails";
import RemainingColumn from "./additionalComponents/remainingColumn";
import ShippedColumn from "./additionalComponents/shippedColumn";

export default ({customDataSource, firstTimeLoading}) => {
	const date = new Date();
	const [totalCount, setTotalCount] = useState(0);
	const allowedPageSizes = [10, 30, 50, "all"];
	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';

	const dataGrid = useRef(null);
	useEffect(() => {
		window.addEventListener("cleanFileds", () => {
			setTotalCount(0);
		});
	}, []);
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};

	const customizeText = (cellInfo) => {
		let localValue = cellInfo.value;
		if (localValue) {
			localValue = localValue.toUpperCase();
			return localValue;
		}
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;

		let dateFormatted;
		language === "ES"
			? (dateFormatted = `${day}-${month}-${year}`)
			: (dateFormatted = `${month}-${day}-${year}`);
		const buildTitle = `OverShipmentTrace_${dateFormatted}.xlsx`;

		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Report");

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 6, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"}
					};
				}
				console.log(gridCell);
				if(gridCell.column.dataField==='shipped' && gridCell.data && gridCell.data.remaining > 0){
					excelCell.font = { color: { argb: 'FF0000FF' } }; //blue
				}
				if(gridCell.column.dataField==='shipped' && gridCell.data && gridCell.data.remaining < 0 ){
					excelCell.font = { color: { argb: 'FF0000' } }; //red
				}
				if(gridCell.column.dataField==='remaining' && gridCell.value < 0){
					excelCell.font = { color: { argb: 'FF0000' } }; //red
				}
				if(gridCell.column.dataField==='remaining' && gridCell.value > 0 ){
					excelCell.font = { color: { argb: 'FF0000FF' } }; //blue
				}
			}
		}).then((cellRange) => {
			/* Header */
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 1, 2, 8);

			headerRow.getCell(1).value = "Over Shipment Trace Report";
			headerRow.getCell(1).font = {name: "Segoe UI Light", size: 20};
			headerRow.getCell(1).alignment = {horizontal: "center"};

			const detailsRow = worksheet.getRow(4);
			detailsRow.getCell(2).value = "Date: " + dateFormatted;
			detailsRow.getCell(3).value = "Time: " + fullTime;
			// Footer
			const footerRowIndex = cellRange.to.row + 2;
			const footerRow = worksheet.getRow(footerRowIndex);
			worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 10);

			footerRow.getCell(1).value = "END OF REPORT";
			footerRow.getCell(1).font = {color: {argb: "BFBFBF"}, italic: true, size: 16};
			footerRow.getCell(1).alignment = {horizontal: "center"};

			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "InventoryGridContainer"
				}}
				dataSource={customDataSource}
				ref={dataGrid}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				onExporting={onExporting}
				onContentReady={getTotalCount}>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFiltersDG}
						/>
					</Item>
					<Item name='exportButton' />
				</Toolbar>
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<ColumnFixing enabled={true} />
				<Export enabled={true} />
				<RemoteOperations groupPaging={true} />
				<Column
					dataField='sewsPartNo'
					caption='SEWS Part No.'
					customizeText={customizeText}
				/>
				<Column
					dataField='sewsDesignLevel'
					caption='SEWS Design Level'
					customizeText={customizeText}
					editorOptions={{maxLength: 40}}
					allowEditing={true}
				/>
				<Column dataField='custGroup' caption='Cust. Group' />
				<Column dataField='customerID' caption='Customer ID' />
				<Column dataField='wharehouse' caption='Wharehouse' />
				<Column dataField='planInv' caption='Plan(Plan+Inv)' />
				<Column
					dataField='shipped'
					caption='Shipped'
					dataType='number'
					cellRender={ShippedColumn}
					alignment='left'
				/>
				<Column dataField='reOpenASNQty' caption='Re-Open ASN Qty' />
				<Column
					dataField='remaining'
					caption='Remaining'
					dataType='number'
					alignment='left'
					cellRender={RemainingColumn}
				/>
				<Column dataField='onPallet' caption='On Pallet' />
				<Column dataField='onTruck' caption='On Truck' />
				<Column dataField='plantId' visible={false} />
				<Column dataField='month' visible={false} />
				<Column dataField='year' visible={false} />
				<MasterDetail enabled={true} component={OverShipmentDetails} />
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
			</DataGrid>
		</React.Fragment>
	);
};
