import React, {useState, useRef, useCallback} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	Pager,
	Scrolling,
	Toolbar,
	Item,
	FilterRow,
	ColumnFixing,
	Export,
	SearchPanel
} from "devextreme-react/data-grid";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import {confirm, alert} from "devextreme/ui/dialog";
import CheckBox from "devextreme-react/check-box";
import Button from "devextreme-react/button";
import IconFilter from "../../components/filter.svg";
import {Workbook} from "exceljs";
import saveAs from "file-saver";
import {exportDataGrid} from "devextreme/excel_exporter";
import appInfo from "../../app-info";

export default ({customDataSource, plantId, token}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	let getIHS = "";
	let canceled = false;
	let finalMsg = "";
	const dataGrid = useRef(null);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	const permission = getPermission(currentUser, "ConfigurationMasters", "XREFMaintenance");
	const [totalCount, setTotalCount] = useState(0);

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});

	const onEditCanceled = (e) => {
		setMode("View");
	};

	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};

	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onRowUpdating = (e) => {
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `XREF Maintenance ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `XREF Maintenance ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};

	async function fillColumns(newData, value, currentRowData) {
		if (!value) {
			newData.verifY_IHS_DATA = value;
			newData.ihscaD_PRODUCT_NUMBER = "";
			newData.ihscaD_DESIGN_LEVEL = "";
		} else {
			let response;
			getIHS = encodeURI(
				`${appInfo.configURL}XREFMaintenance/GetIHSData?plantid=${plantId}&sewsPartNo=${currentRowData.sewS_PART_NO}&sewsDesignLevel=${currentRowData.sewS_DESIGN_LEVEL}`
			);

			await fetch(getIHS, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((res) => res.json())
				.then((res) => {
					response = res;
				})
				.catch((err) => console.warn(err));
			if (response.message) {
				newData.verifY_IHS_DATA = value;
				newData.ihscaD_PRODUCT_NUMBER = response.message;
				newData.ihscaD_DESIGN_LEVEL = response.message;
			} else {
				newData.verifY_IHS_DATA = value;
				newData.ihscaD_PRODUCT_NUMBER = response[0].ihscaD_PRODUCT_NUMBER;
				newData.ihscaD_DESIGN_LEVEL = response[0].ihscaD_DESIGN_LEVEL;
			}
		}
	}
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "xrefMainDataGrid"
				}}
				ref={dataGrid}
				dataSource={customDataSource}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnMinWidth={120}
				columnAutoWidth={true}
				remoteOperations={true}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onContentReady={getTotalCount}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}
				onExporting={onExporting}>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFilters}
						/>
					</Item>
					<Item name='revertButton' />
					<Item name='saveButton' />
					<Item name='exportButton' />
					<Item name='searchPanel' />
				</Toolbar>
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<SearchPanel visible={true} width={240} placeholder='Search...' />
				<Export enabled={true} />
				<ColumnFixing enabled={true} />
				<Editing
					mode='batch'
					allowUpdating={true}
					selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
					startEditAction={dataGridSettings.startEditAction}
					texts={{saveAllChanges: "", cancelAllChanges: ""}}
				/>
				<Column
					dataField='id'
					caption='No'
					visible={false}
					cssClass='cell-bold'
					allowEditing={false}
					allowFiltering={false}
				/>
				<Column
					dataField='sewS_PART_NO'
					caption='SEWS Part No.'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='shorT_PART_NO'
					width={74}
					caption='Short Part No.'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='sewS_DESIGN_LEVEL'
					width={78}
					caption='SEWS Design Level'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='sewS_STS_CD'
					width={74}
					caption='SEWS Status Code'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='cust_sts_cd'
					width={74}
					caption='Cust. STS'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='misC1_CHAR_CUST'
					caption='MISC1 CUST'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='locatioN_CD'
					caption='Location (Site Code)'
					editorOptions={{maxLength: 6}}
					allowEditing={permission ? permission.update : false}
				/>
				<Column dataField='hrnS_LABEL'  
					caption='Scan Harness' 
					allowEditing={permission ? permission.update : false}>
					<CheckBox />
				</Column>
				<Column
					dataField='boX_CNT_LIST'
					caption='Print Box Content List'
					allowEditing={permission ? permission.update : false}>
					<CheckBox />
				</Column>
				<Column
					dataField='verifY_BOXES'
					caption='Verify Pallet Box Count'
					allowEditing={permission ? permission.update : false}>
					<CheckBox />
				</Column>
				<Column dataField='activE_FLAG'
					caption='Active Flag' 
					allowEditing={permission ? permission.update : false}>
					<CheckBox />
				</Column>
				<Column
					dataField='verifY_IHS_DATA'
					caption='Consider IHS Part'
					setCellValue={fillColumns}
					allowEditing={permission ? permission.update : false}>
					<CheckBox />
				</Column>
				<Column dataField='ihscaD_PRODUCT_NUMBER' caption='IHSCAD Part No' allowEditing={false} />
				<Column dataField='ihscaD_DESIGN_LEVEL' caption='IHSCAD Design' allowEditing={false} />
				<Column
					dataField='verifY_COMPONENT_DATA'
					caption='Component'
					allowEditing={permission ? permission.update : false}>
					<CheckBox></CheckBox>
				</Column>
				<Column
					dataField='verifY_SCAN_HRAN_PALLET_DATA'
					caption='Plt-Scan Hrns'
					allowEditing={permission ? permission.update : false}>
					<CheckBox></CheckBox>
				</Column>
				<Column
					dataField='validatE_INSPT'
					caption='Validate Inspection'
					allowEditing={permission ? permission.update : false}>
					<CheckBox></CheckBox>
				</Column>
				<Column dataField='qA_CERT' 
					caption='QA Cert' 
					allowEditing={permission ? permission.update : false}>
					<CheckBox></CheckBox>
				</Column>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
			</DataGrid>
		</React.Fragment>
	);
};
