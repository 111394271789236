import React, {useState, useEffect, useRef} from "react";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	ColumnFixing,
	Pager,
	RemoteOperations,
	Toolbar,
	Item,
	Scrolling,
	Selection,
	Label
} from "devextreme-react/data-grid";
import {NumberBox} from "devextreme-react/number-box";
import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import CheckBox from "devextreme-react/check-box";
import {Button} from "devextreme-react/button";
import {alert} from "devextreme/ui/dialog";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import Box from "devextreme-react/box";

export default ({customDataSource, plantId}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const [disablePrintBtn, setDisablePrintBtn] = useState(true);
	let allFieldsActive = false;
	const dataGrid = useRef(null);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(currentUser, "DemandPrinting", "HarnessLabelPrinting");
	let activePlant = sessionStorage.getItem("currentPlant");
	const [printLot, setPrintLot] = useState(true);
	const [lotNo, setLotNo] = useState();
	const [printDate, setPrintDate] = useState(new Date());
	const [totalCount, setTotalCount] = useState(0);
	const [printers, setPrinters] = useState(null);
	const [showProdLineLot, setShowProdLineLot] = useState(false);
	const [enableSpeed, setEnableSpeed] = useState(true);
	const [showBrodCastColor, setShowBrodCastColor] = useState(false);
	const [printFieldStatus, setPrintFieldStatus] = useState({
		labelSelected: false,
		labelQty: false,
		printer: false,
		speed: true,
		prodLine: true,
		prodLot: true,
		prodDate: true
	});
	const [customSpeed, SetCustomSpeed] = useState(0);
	const [printDetails, setPrintDetails] = useState({
		CBPlantId: activePlant,
		ClusterPrinterName: "",
		ClusterProdDate: "",
		LabelQty: null,
		LotNo: null,
		PrintLot: true,
		ProductionLine: "",
		ProductionLot: "",
		SEWSPartNo: "",
		SEWSDesginLevel: "",
		SEQNo: "",
		SEWSSTSCD: "",
		ShortPartNo: "",
		ProductLabel: "",
		BackKanban: "",
		CustomerCode: "",
		BroadCastCd: "",
		ShortPartNoPrint: "",
		Identifier: "",
		Speed: "0"
	});

	useEffect(() => {
		let getPrinters = `${appInfo.demandURL}HarnessLabelPrinting/Printers`;
		let getProdLine = `${appInfo.demandURL}HarnessLabelPrinting/systemparameters/ProductionLineLot`;
		let getDisplayBroadColor = `${appInfo.demandURL}HarnessLabelPrinting/DisplayColorAndBroadcast`;
		(async () => {
				await fetch(getPrinters, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customPrint = result.map((print) => {
							return print.value;
						});
						setPrinters(customPrint);
					})
					.catch((err)=>{
				console.error(err, "Harness printers fail request");
			})

			dataGrid.current.instance.deselectAll();

			setPrintFieldStatus((oldState) => {
				return {...oldState, labelSelected: false};
			});
			
				await axios
					.get(getProdLine, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						let parameterVal = result.data[0].parameter_Value;
						if (parameterVal.toUpperCase() === "YES" || parameterVal.toLowerCase() === "yes") {
							setShowProdLineLot(true);
							setPrintFieldStatus((oldState) => {
								return {...oldState, prodLine: false, prodLot: false};
							});
						}
					})
					.catch((err) => {
				console.error(err," prod line lot fail request");
			})
			
				await axios
					.get(getDisplayBroadColor, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						setShowBrodCastColor(result.data);
					})
					.catch((err) => {
				console.error(err, "Show Broadcast color - fail request");
			})
		})();
	}, []);
	useEffect(() => {
		allFieldsActive = true;
		for (let field in printFieldStatus) {
			if (printFieldStatus[field] == false) {
				allFieldsActive = false;
			}
		}
		setDisablePrintBtn(!allFieldsActive);
	}, [printFieldStatus]);

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});

	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const printLotOnValueChanged = (e) => {
		setPrintLot(!e.previousValue);
	};
	const printDateOnValueChanged = (e) => {
		let fw = getWeekNumber(e.value).toString();
		setLotNo(fw);
		fw = parseInt(fw);
		setPrintDetails((oldState) => {
			return {...oldState, LotNo: fw};
		});
		enablePrintBtn(e);
	};
	const getWeekNumber = (d) => {
		let firstDayOfWeek = 0;

		let start = new Date(d.getFullYear(), 0, 0);
		let diff = d - start + (start.getTimezoneOffset() - d.getTimezoneOffset()) * 60 * 1000;
		let oneDay = 1000 * 60 * 60 * 24;
		let day = Math.floor(diff / oneDay);

		let n1 = day - 1;
		let n2 = (d.getDay() - (n1 % 7) - firstDayOfWeek + 14) % 7;

		return Math.ceil((n1 + n2) / 7);
	};
	const onInitialized = (e) => {
		let fw = getWeekNumber(printDate).toString();
		fw = parseInt(fw);
		setPrintDetails((oldState) => {
			return {...oldState, ClusterProdDate: printDate, LotNo: fw};
		});
		enablePrintBtn(e);
		setLotNo(fw);
	};
	const onSelectionChanged = (e) => {
		if (printDetails.CBPlantId === null) {
			setPrintDetails((oldState) => {
				return {...oldState, CBPlantId: activePlant};
			});
		}
		if (e.selectedRowsData.length > 0) {
			//Make sure selectedRowsData has data selected
			let rowSelected = e.selectedRowsData[0];
			SetCustomSpeed(rowSelected.speed);
			setEnableSpeed(rowSelected.enableSpeed);
			setPrintFieldStatus((oldState) => {
				return {...oldState, labelSelected: true};
			});
			setPrintFieldStatus((oldState) => {
				return {...oldState, speed: true};
			});
			setPrintDetails((oldState) => {
				return {
					...oldState,
					BackKanban: rowSelected.backKanban,
					BroadCastCd: rowSelected.broadCastCd,
					CustomerCode: rowSelected.customerCode,
					Identifier: rowSelected.identifier,
					ProductLabel: rowSelected.productLabel,
					SEQNo: rowSelected.seqNo,
					SEWSDesginLevel: rowSelected.sewsDesginLevel,
					SEWSPartNo: rowSelected.sewsPartNo,
					SEWSSTSCD: rowSelected.sewsstscd,
					ShortPartNo: rowSelected.shortPartNo,
					ShortPartNoPrint: rowSelected.shortPartNoPrint,
					CustPartNo: rowSelected.custPartNo,
					Speed: rowSelected.speed
				};
			});
		}
	};
	const enablePrintBtn = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;
		switch (fieldName) {
			case "labelQty":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, labelQty: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, LabelQty: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, labelQty: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, LabelQty: currentFieldVal};
					});
				}

				break;
			case "printer":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, printer: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ClusterPrinterName: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, printer: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ClusterPrinterName: currentFieldVal};
					});
				}
				break;
			case "speed":
				if (currentFieldVal != "") {
					let speedtype = parseInt(currentFieldVal);
					setPrintFieldStatus((oldState) => {
						return {...oldState, speed: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, Speed: speedtype};
					});
					SetCustomSpeed(currentFieldVal);
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, speed: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, Speed: currentFieldVal};
					});
					SetCustomSpeed(currentFieldVal);
				}
				break;
			case "prodLine":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodLine: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ProductionLine: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodLine: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ProductionLine: currentFieldVal};
					});
				}
				break;
			case "prodLot":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodLot: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ProductionLot: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodLot: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ProductionLot: currentFieldVal};
					});
				}
				break;
			case "prodDate":
				if (currentFieldVal != "") {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodDate: true};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ClusterProdDate: currentFieldVal};
					});
				} else {
					setPrintFieldStatus((oldState) => {
						return {...oldState, prodDate: false};
					});
					setPrintDetails((oldState) => {
						return {...oldState, ClusterProdDate: currentFieldVal};
					});
				}
				break;
			default:
				console.warn("This option is not configured: ", fieldName);
				break;
		}
	};
	const sendPrintDetails = async (e) => {
		let token = JSON.parse(sessionStorage.getItem("currentUser"));
		token = token.data.token;

		let printDesc = JSON.stringify(printDetails);
		let printURL = `${appInfo.demandURL}HarnessLabelPrinting/Print`;

		await axios({
			method: "POST",
			url: printURL,
			data: printDesc,
			headers: {
				Authorization: `Bearer ${token}`,
				"Accept-Language": `${language}`,
				"Content-Type": "application/json"
			}
		})
			.then((result) => {
				alert(result.data);
			})
			.catch((error) => {
				let validations;
				if (error.response) {
					if (error.response.data.errors) {
						validations = error.response.data.errors;
						let message = "";
						Object.keys(validations).map((field) => {
							let fieldValues = validations[field];
							message += fieldValues[0] + "<br/> ";
						});
						alert("<i class='dx-icon-close' id='cancel-icon'></i><br/><br/>" + message);
					}
					if (error.response.data.message) {
						validations = error.response.data.message;
						alert("<i class='dx-icon-close' id='cancel-icon'></i><br/><br/>" + validations);
					}
				}
			});
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "harnessGridContainer"
				}}
				ref={dataGrid}
				dataSource={customDataSource}
				showBorders={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				allowColumnResizing={true}
				onContentReady={getTotalCount}
				onSelectionChanged={onSelectionChanged}>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>{dataGridSettings.mode}</label>
						</div>
					</Item>
				</Toolbar>
				<ColumnFixing enabled={true} />
				<Selection mode='single' />
				<RemoteOperations groupPaging={true} />
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<Column
					dataField='id'
					caption='No.'
					visible={false}
					cssClass='cell-bold small-space'
				/>
				<Column dataField='sewsPartNo' cssClass='cell-bold small-space' caption='SEWS Part No.' />
				<Column
					dataField='sewsDesginLevel'
					cssClass='cell-bold small-space'
					caption='Design Change'
				/>
				<Column dataField='seqNo' cssClass='cell-bold small-space' caption='Seq No' />
				<Column dataField='sewsstscd' cssClass='cell-bold small-space' caption='STS' />
				<Column dataField='shortPartNo' cssClass='cell-bold small-space' caption='Short Part No.' />
				<Column dataField='productLabel' cssClass='cell-bold small-space' caption='Label Format' />
				<Column dataField='backKanban' cssClass='cell-bold small-space' caption='Extra Info' />
				<Column dataField='custPartNo' cssClass='cell-bold small-space' caption='Cust. Part No.' />
				<Column dataField='customerCode' cssClass='cell-bold small-space' caption='Cust. Code' />
				<Column
					dataField='broadCastCd'
					cssClass='cell-bold small-space'
					caption='Broad Cast Cd'
					visible={showBrodCastColor}
				/>
				<Column
					dataField='color'
					cssClass='cell-bold small-space'
					caption='Color'
					visible={showBrodCastColor}
				/>
				<Column
					dataField='shortPartNoPrint'
					cssClass='cell-bold small-space'
					caption='Short Part No. Print'
				/>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
			</DataGrid>

			<Box direction='row' height={95} className={"printing-details"}>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						&nbsp; &nbsp;Label Quantity
						<NumberBox
							min={0}
							max={2000}
							showSpinButtons={true}
							showClearButton={true}
							width='100px'
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "labelQty"
							}}
						/>
					</div>
				</Item>
				<Item ratio={0} baseSize={200}>
					<div className='rect demo-light'>
						Printer *
						<SelectBox
							items={printers}
							width='170px'
							elementAttr={{
								id: "printer"
							}}
							onValueChanged={enablePrintBtn}
							searchEnabled={true}></SelectBox>
					</div>
				</Item>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						&nbsp;&nbsp; Speed *
						<NumberBox
							value={customSpeed}
							min={0}
							max={10}
							showSpinButtons={true}
							width='100px'
							onValueChanged={enablePrintBtn}
							elementAttr={{
								id: "speed"
							}}
							disabled={!enableSpeed}></NumberBox>
					</div>
				</Item>
				{showProdLineLot ? (
					<Item ratio={0} baseSize={150}>
						<div className='rect demo-dark'>
							Prod. Line
							<TextBox
								width='100px'
								maxLength='25'
								onValueChanged={enablePrintBtn}
								elementAttr={{
									id: "prodLine"
								}}
							/>
						</div>
					</Item>
				) : null}
				{showProdLineLot ? (
					<Item ratio={0} baseSize={150}>
						<div className='rect demo-dark'>
							Prod Lot:
							<TextBox
								width='100px'
								maxLength='15'
								onValueChanged={enablePrintBtn}
								elementAttr={{
									id: "prodLot"
								}}
							/>
						</div>
					</Item>
				) : null}
				<Item ratio={0} baseSize={180}>
					<div className='rect demo-dark'>
						Prod. Date
						<DateBox
							defaultValue={printDate}
							type='date'
							width='150px'
							onValueChanged={printDateOnValueChanged}
							onInitialized={onInitialized}
							elementAttr={{
								id: "prodDate"
							}}
							displayFormat=' dd/MM/yyyy'
						/>
					</div>
				</Item>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						&nbsp;&nbsp; Lot No.
						<TextBox value={lotNo} readOnly={true} width='110px' elementAttr={{class: "lotNo"}} />
					</div>
				</Item>
				<Item ratio={0} baseSize={150}>
					<div className='rect demo-dark'>
						Print Lot <br />
						<br />
						<CheckBox value={printLot} onValueChanged={printLotOnValueChanged} />
					</div>
				</Item>
				<Item ratio={2} baseSize={120}>
					<div className='rect demo-dark'>
						<Button
							icon='print'
							text='Print'
							elementAttr={{
								id: "printButton"
							}}
							disabled={disablePrintBtn}
							onClick={sendPrintDetails}
						/>
					</div>
				</Item>
			</Box>
		</React.Fragment>
	);
};
