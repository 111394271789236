import React from "react";

export default function ConditionalIcon({category}) {
	if (category === "Favorites") {
		return (
			<div>
				<span className="dx-icon-favorites icon"></span>&nbsp;{" "}
				<div style={{display: "inline"}}>{category}</div>
			</div>
		);
	} else {
		return <div>{category}</div>;
	}
}
