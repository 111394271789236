import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import ComponentCodeMasterGrid from "./ComponentCodeMasterGrid";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"ComponentCodeMaster"
	);

	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("0");
	const [customDataSource, setCustomDataSource] = useState(null);
	const [qaApproveGroup, setQaApproveGroup] = useState(null);
	const [deApproveGroup, setDeApproveGroup] = useState(null);

	useEffect(() => {
		let getPlants = `${appInfo.configURL}ComponentCodeMaster/Plants`;
		let getActivePlant = `${appInfo.configURL}ComponentCodeMaster/ActivePlant`;
		const abortController = new AbortController();
		(async () => {
			await fetch(getPlants, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal,
			})
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value,
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.error("Fail requesting get plants");
				});

			await axios
				.get(getActivePlant, {headers: {Authorization: `Bearer ${token}`}})
				.then((result) => {
					let activePlant = result.data;
					activePlant = activePlant.trim();
					setcurrentPlant(activePlant);
				})
				.catch((error) => {
					console.error("Getting active plant failed request:", error);
				});

			await axios
				.get(appInfo.configURL + "ComponentCodeMaster/ApprovalGroupCodes", {
					headers: {Authorization: `Bearer ${token}`},
				})
				.then((result) => {
					let approvalCodes = result.data;
					setQaApproveGroup(approvalCodes.qaApproveGroup);
					setDeApproveGroup(approvalCodes.deApproveGroup);
				})
				.catch((error) => {
					console.error("Getting approval Group Codes failed request:", error);
				});
			return () => {
				// Abort the request when the component unmounts or when a dependency changes
				abortController.abort();
			};
		})();
	}, []);

	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}ComponentCodeMaster?plantid=${e.value}`,
			})
		);

		sessionStorage.setItem("currentPlant", e.value);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Component Code Master</h2>
					</div>
				</div>
				<div className="header-right-side">
					<SelectBox
						elementAttr={{
							id: "ComponentCodeMasterSelector",
						}}
						placeholder="Plant Code"
						items={plants}
						onValueChanged={onValueChanged}
						value={currentPlant}
						displayExpr="value"
						valueExpr="id"
					/>
				</div>

				<ComponentCodeMasterGrid
					customDataSource={customDataSource}
					plantId={currentPlant}
					qaApproveGroup={qaApproveGroup}
					deApproveGroup={deApproveGroup}
				/>
			</div>
		</React.Fragment>
	);
};
