import React, {useState, useRef, useEffect, useCallback} from "react";
import store from "../../components/hooks/store";

import {
	getPermission,
	handleErrors
} from "../../pages/configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import ShipmentPlanCheckMasterGrid from "./ShipmentPlanCheckMasterGrid";

export default () => {
	const [customDataSource, setCustomDataSource] = useState(store({
		url: `${appInfo.shippingURL}ShipmentPlanCheckMaster`
	}));
	
	const [shipToWhseList, setShipToWhseList] = useState(null);
	const [adhereceType, setAdhereceType] = useState(null);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;
	const permission = getPermission(currentUser, "ShippingPlan", "ShipmentPlanCheckMaster");

	useEffect(() => {
		(async () => {
			let getShipToWhse = `${appInfo.shippingURL}ShipmentPlanCheckMaster/ShipToWhse`;
			let getAdhereceType = `${appInfo.shippingURL}ShipmentPlanCheckMaster/AdhereceType`;
			try {
				await fetch(getShipToWhse, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						setShipToWhseList(result.data);
					});
			} catch (error) {
				console.error("ShipmentPlanCheckMaster fail getting Ship To Whse");
			}
			try {
				await fetch(getAdhereceType, {
					method: "get",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then((response) => response.json())
					.then((result) => {
						setAdhereceType(result.data);
					});
			} catch (error) {
				console.error("ShipmentPlanCheckMaster fail getting Ship To Whse");
			}
		})();
	}, []);

	if (!permission.read) {
		return <Redirect to={"/home"} />;
	}
	
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Shipment Plan Check Master</h2>
					</div>
				</div>
				<ShipmentPlanCheckMasterGrid
					shipToWhseList={shipToWhseList}
					adhereceType={adhereceType}
					customDataSource={customDataSource}
				/>

			</div>
		</React.Fragment>
	);
};
