import React, {useState, useEffect, useRef} from "react";
import SelectBox from "devextreme-react/select-box";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import FileUploader from "devextreme-react/file-uploader";
import {Redirect} from "react-router-dom";
import ShipmentPlanMaintenanceSelectBox from "./AdditionalComponents/ShipmentPlanMaintenanceSelectBox";
import ShipmentPlanMaintenanceMasterGrid from "./ShipmentPlanMaintenanceMasterGrid";
import {alert} from "devextreme/ui/dialog";

export default () => {
	const radioOptions = ["Shipment", "Production"];
	const [optionSelected, setOptionSelected] = useState(radioOptions[0]);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;
	const permission = getPermission(currentUser, "ShippingPlan", "ShipmentPlanMaintenanceMaster");

	const [disableAddBtn, setDisableAddBtn] = useState(true);
	const [disableFilters, setDisableFilters] = useState(false);
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [years, setYears] = useState(null); //keep the years list
	const [currentYear, setCurrentYear] = useState(null); // this one will set the value in the dropdown
	const [initialYear, setInitialYear] = useState(null); //this one will be used to reset the initial values
	const [months, setMonths] = useState(null); //keep the month list
	const [currentMonth, setCurrentMonth] = useState(null); // this one will set the value in the dropdown
	const [initialMonth, setInitialMonth] = useState(null); //this one will be used to reset the initial values
	const [currentMonthNumber, setCurrentMonthNumber] = useState(null);
	const [customerCodes, setCustomerCodes] = useState(null);
	const [customerGroups, setCustomerGroups] = useState(null);
	const [customDataSource, setCustomDataSource] = useState(null);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const event = new CustomEvent("resetFileUploader");
	//const [activePlant, setActivePlant] = useState(null); // this one will be used in case user remove the filters

	const [reportDetail, setReportDetail] = useState({
		option: "SH",
		plantId: "",
		year: "",
		month: ""
	});
	const reportParamsArr = ["option", "plantId", "year", "month"];

	const onValueChanged = (e) => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		setOptionSelected(e.value);
		window.dispatchEvent(event); // INFO: Trigger the evnt to reset fileUploader
		if (e.value === "Production") {
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, option: "PROD"};
			});
		} else {
			setReportDetail((oldValues) => {
				return {...oldValues, option: "SH"};
			});
			setShowDGColumns(false);
		}
	};

	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			let getYear = `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/Years`;
			let getMonths = `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/Months`;
			let getCustomerCodes = `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/Customers`;
			let getCustomerGroups = `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/CustomerGroups`;
			let getActivePlant = `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/ActivePlant`;

			await fetch(getYear, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((result) => {
					let yearsList = result.data.map((year) => {
						return year.value;
					});
					let currYear = new Date().getFullYear();
					currYear = currYear.toString();
					let yearArrayPosition = yearsList.indexOf(currYear);
					setCurrentYear(yearsList[yearArrayPosition]);
					setInitialYear(yearsList[yearArrayPosition]);
					setYears(yearsList);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanMaintenanceMaster fail getting year list");
					}
				});

			await fetch(getMonths, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => response.json())
				.then((result) => {
					let monthList = result.data.map((month) => {
						return month.value;
					});
					let currMonth = new Date().getMonth();
					setCurrentMonth(monthList[currMonth]);
					setInitialMonth(monthList[currMonth]);
					currMonth = currMonth + 1;
					setCurrentMonthNumber(currMonth);
					setMonths(monthList);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanMaintenanceMaster fail getting month list");
					}
				});

			await fetch(getCustomerCodes, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => response.json())
				.then((result) => {
					let customerCodeList = result.data.map((code) => {
						return code.value;
					});
					setCustomerCodes(customerCodeList);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanMaintenanceMaster fail getting customer codes");
					}
				});

			await fetch(getCustomerGroups, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`},
				signal: abortController.signal
			})
				.then((response) => response.json())
				.then((result) => {
					let customerGroupList = result.data.map((group) => {
						return group.value;
					});
					setCustomerGroups(customerGroupList);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanMaintenanceMaster fail getting customer groups");
					}
				});

			return () => {
				// Abort the request when the component unmounts or when a dependency changes
				abortController.abort();
			};
		})();
	}, []);
	const callbackDropdownBoxPlant = (plantValue) => {
		setReportDetail((oldValues) => {
			return {...oldValues, plantId: plantValue};
		});
		setPlantIDSelected(plantValue);
	};
	const onChangeYear = (e) => {
		setCurrentYear(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, year: e.value};
		});
	};
	const onChangeMonth = (e) => {
		let monthNum;
		switch (e.value) {
			case "Jan":
				monthNum = "01";
				break;
			case "Feb":
				monthNum = "02";
				break;
			case "Mar":
				monthNum = "03";
				break;
			case "Apr":
				monthNum = "04";
				break;
			case "May":
				monthNum = "05";
				break;
			case "Jun":
				monthNum = "06";
				break;
			case "Jul":
				monthNum = "07";
				break;
			case "Aug":
				monthNum = "08";
				break;
			case "Sep":
				monthNum = "09";
				break;
			case "Oct":
				monthNum = "10";
				break;
			case "Nov":
				monthNum = "11";
				break;
			case "Dec":
				monthNum = "12";
				break;
		}
		if (months !== null) {
			let positonMonth = parseInt(monthNum);
			positonMonth = positonMonth - 1;
			setCurrentMonthNumber(positonMonth);
			setCurrentMonth(months[positonMonth]);
		}

		setReportDetail((oldValues) => {
			return {...oldValues, month: monthNum};
		});
	};
	const setReportParams = (e) => {
		const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.shippingURL}ShipmentPlanMaintenanceMaster${params}`
			})
		);
		setDisableFilters(true);
		setdisableSearchBtn(true);
		setDisableAddBtn(false);
		setfirstTimeLoading(false);
	};

	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		window.dispatchEvent(event);
		setDisableFilters(false);
		setdisableSearchBtn(false);
		setCustomDataSource(null);
	};
	if (!permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Shipment/Production Plan Maintenance Master</h2>
					</div>
				</div>
				<form className={"filter-form"}>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<br />
							<RadioGroup
								items={radioOptions}
								layout='horizontal'
								value={optionSelected}
								onValueChanged={onValueChanged}
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<ShipmentPlanMaintenanceSelectBox
								callbackDropdownBoxPlant={callbackDropdownBoxPlant}
								plantIDSelected={plantIDSelected}
								disableFilters={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Year'
								elementAttr={{
									id: "LocationSelector"
								}}
								disabled={disableFilters}
								searchEnabled={true}
								items={years}
								onValueChanged={onChangeYear}
								value={currentYear}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Month'
								elementAttr={{
									id: "partNumberSelect2"
								}}
								disabled={disableFilters}
								searchEnabled={true}
								onValueChanged={onChangeMonth}
								items={months}
								value={currentMonth}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "findButton"
								}}
								onClick={setReportParams}
								disabled={disableSearchBtn}
							/>
							<Tooltip target='#findButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button
								icon='clear'
								elementAttr={{
									id: "cancelButton"
								}}
								onClick={clearFilters}
							/>
						</div>
					</div>
				</form>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<ShipmentPlanMaintenanceMasterGrid
					customDataSource={customDataSource}
					customerCodes={customerCodes}
					customerGroups={customerGroups}
					plantId={plantIDSelected} 
					showDGColumns={showDGColumns}
					option={reportDetail.option}
					year={currentYear}
					month={currentMonthNumber}
					firstTimeLoading={firstTimeLoading}
					disableAddBtn={disableAddBtn}
				/>
			</div>
		</React.Fragment>
	);
};
