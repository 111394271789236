import React, {
	useState,
	useEffect,
	createContext,
	useContext,
	useCallback,
} from "react";
import {getUser, signIn as sendSignInRequest} from "../api/auth";
import {useHistory} from "react-router-dom";
import {confirm, alert} from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";

function AuthProvider(props) {
	const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		(async function () {
			const result = await getUser();

			if (result.isOk) {
				setUser(result.data);
			}

			setLoading(false);
		})();
	}, []);

	const signIn = useCallback(
		async (username, password, servername, isFavorite, zone, isAdmin, domain) => {
			sessionStorage.setItem("isAdminForm", isAdmin);
			const result = await sendSignInRequest(
				username,
				password,
				servername,
				isFavorite,
				zone,
				isAdmin,
				domain
			);
			if (result.isOk) {
				if (result.data.passwordToBeExpired === true) {
					setUser();
					confirm(`<i>${result.data.message}</i>`, "Confirm changes").then(
						(dialogResult) => {
							if (dialogResult == true) {
								//Save user name in order to display the name in change-password form
								sessionStorage.setItem("username", username);
								sessionStorage.setItem("servername", servername);
								return history.push({
									pathname: "/change-password/1",
									state: {
										detail: username,
										servername: servername,
										message: "Old Password",
									},
								});
							} else {
								setUser(result);
							}
						}
					);
				} else if (result.data.passwordExpired === true) {
					setUser();
					return alert(`<i>${result.data.message}</i>`).then((dialogResult) => {
						if (dialogResult) {
							//Save user name in order to display the name in change-password form
							sessionStorage.setItem("username", username);
							sessionStorage.setItem("servername", servername);
							return history.push({
								pathname: "/change-password/1",
								state: {
									detail: username,
									servername: servername,
									message: "Old Password",
								},
							});
						}
					});
				} else if (
					result.data.passwordExpired === false &&
					result.data.passwordToBeExpired === false &&
					result.data.firstLogon === true
				) {
					setUser();
					return alert(`<i>${result.data.message}</i>`).then((dialogResult) => {
						if (dialogResult) {
							//Save user name in order to display the name in change-password form
							sessionStorage.setItem("username", username);
							sessionStorage.setItem("servername", servername);
							return history.push({
								pathname: "/change-password/1",
								state: {
									detail: username,
									servername: servername,
									message: "Received Code",
								},
							});
						}
					});
				} else {
					setUser(result);
				}
			} else {
				if (result.data.message) {
					notify(result.data.message, "error", 3000);
				}
			}
			return result;
		},
		[]
	);

	const signOut = useCallback(() => {
		sessionStorage.removeItem("currentUser");
		sessionStorage.removeItem("isAdminForm");
		setUser();
	}, []);

	return (
		<AuthContext.Provider value={{user, signIn, signOut, loading}} {...props} />
	);
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export {AuthProvider, useAuth};
