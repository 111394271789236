import CustomStore from 'devextreme/data/custom_store';
import axios from 'axios';
import ArrayStore from 'devextreme/data/array_store';
import { Redirect } from 'react-router-dom';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

const store = (props) => {
  const storeAttr = new ArrayStore({
    key: "id"
  });

  if (sessionStorage.getItem("currentUser") === null) {
    return <Redirect to={'/home'} />
  }

  let token = JSON.parse(sessionStorage.getItem('currentUser'));
  token = token.data.token;

  const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
  const handleErrors = (response) => {
    if (!response.ok) {
      if (response.status === 401) {
        sessionStorage.removeItem('currentUser');
        window.location.reload();
        let url = window.location.origin + '/#/login';
        window.location.href = url;
      }
      response.text().then(text => { alert(text.replace(/{"message":"/g, '').replace(/"}/g, '')); });
      throw Error(response.statusText);
    }
    return response;
  }

  const myStore = new CustomStore({
    key: storeAttr.key(),
    load: (loadOptions) => {
      let params;
      let containsCharac = props.url;
      if (containsCharac.includes('?')) {
        params = '&';
      } else {
        params = '?';
      }
      [
        'filter',
        'requireTotalCount',
        'searchExpr',
        'searchValue',
        'sort',
        'skip',
        'take'
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);

      return fetch(`${props.url}${params}`, {
        method: 'get',
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(response => handleErrors(response))
        .then(response => response.json())
        .then(response => {
          let responseTotalCount;
          if (response.message) {
            notify(response.message, 'warning', 2000);
            responseTotalCount = 0;
            sessionStorage.removeItem("totalCount");
            sessionStorage.setItem("totalCount", responseTotalCount);
            return {
              data: [],
              totalCount: 0
            };
          } else {
            sessionStorage.removeItem("totalCount");
            sessionStorage.setItem("totalCount", response.totalCount);
            return {
              data: response.data,
              totalCount: response.totalCount
            };
          }
        })
        .catch((error) => {
          console.warn(error);
          notify('504 Gateway Time-out', 'error', 2000);
          return {
            data: [],
            totalCount: 0
          };
        });
    },
    // INFO: This is used due we need onsaved event in location, destination and carrier
    update: (key, values) => {
      return axios(props.url, {
        method: 'put',
        data: JSON.stringify(values),
        headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' },
      }).then((response) => {
        return response;
      }).catch((error) => {
        if (error.response) {
          console.warn(error.response.data);
        } else if (error.request) {
          console.warn(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.warn('Error', error.message);
        }
        return error.response;
      });
    },
    insert: (values) => {
      return axios({
        method: 'POST',
        url: props.url,
        data: JSON.stringify(values),
        headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' },
      }).then((response) => {
        return response;
      })
        .catch((error) => {
          if (error.response) {
            console.warn(error.response.data);
          } else if (error.request) {
            console.warn(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.warn('Error', error.message);
          }
          return error.response;
        });
    },
    remove: (values) => {
      return axios(props.url, {
        method: 'delete',
        data: JSON.stringify(values),
        headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' },
      }).then((response) => {
        if (store.deleteResponseMsg && Object.keys(store.deleteResponseMsg).length !== 0) {
          //if message has showed and theres another delete remove the old data 
          store.deleteResponseMsg.data.message += response.data.message;
        } else {
          store.deleteResponseMsg = response;
        }
        return storeAttr.remove(values);
      }).catch((error) => {
        if (error.response) {
          let responseData = error.response.data;
          if (responseData.message !== null || responseData.message !== '') {
            responseData.message = responseData.message.replace('.', ',');
            if (store.deleteResponseMsg && Object.keys(store.deleteResponseMsg).length !== 0) {
              //if message has showed and theres another delete remove the old data 
              store.deleteResponseMsg.data.message += responseData.message;
            } else {
              store.deleteResponseMsg = error.response;
            }
            return storeAttr.remove(values);
          }
          console.warn(responseData);
        } else if (error.request) {
          console.warn(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.warn('Error', error.message);
        }
        return error.response;
      });
    }
  });
  myStore.resetResponseMsg = () => {
    store.deleteResponseMsg = {};
  }
  myStore.getDeleteMsg = () => {
    return store.deleteResponseMsg;
  };

  return myStore;
}

export default store;