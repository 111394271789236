import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import CycleCountReportGrid from "./CycleCountReportGrid";
import {TextBox} from "devextreme-react";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	let token = user ? user.data.token : '';
	let formatDate = user ? user.data.userData.formatDate : '';
	const permission = getPermission(user, "MISReports", "ShipmentReport");

	// /* Instance components in order to reset them when necessary */
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const cycleCountId = useRef(null);
	const [plants, setPlants] = useState(null);

	const reportOptions = ["Summary", "Detail"];
	const [activePlant, setActivePlant] = useState(null); // this one will be used in case user remove the filters
	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const currentDate = new Date();

	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const [showSummaryColumns, setShowSummaryColumns] = useState(true);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		reportOption: "S",
		cycleCountId: "",
		dateFrom: "",
		dateTo: ""
	});
	const reportParamsArr = [
		"plantID",
		"reportOption",
		"cycleCountId",
		"sewsPartNumber",
		"dateFrom",
		"dateTo"
	];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getActivePlant = `${appInfo.reportURL}CycleCountReport/ActivePlant`;
		let getPlants = `${appInfo.reportURL}CycleCountReport/Plants`;
		(async () => {
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => handleErrors(result))
					.then((result) => result.json())
					.then((result) => {
						setReportDetail((oldValues) => {
							return {...oldValues, plantId: result.Id};
						});
						setPlantIDSelected(result.Id);
						setActivePlant(result.Id);
					})
					.catch ((error)=> {
				console.error("PhysicalInventoryReport fail getting active plant");
			});
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.warn(err);
				});
		})();
	}, []);

	const reportOptionOnChange = (e) => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		e.promise = setReportOptionSelected(e.value);
		if (e.value == "Summary") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "S"};
			});
			setShowSummaryColumns(true);
			setShowDGColumns(false);
		} else {
			//turn on flag to show extra columns
			setShowSummaryColumns(false);
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "D"};
			});
		}
	};

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}CycleCountReport${params}`
			})
		);
		setShowTooltip(false);
		setDisableFilters(true);
		setdisableSearchBtn(true);
		setfirstTimeLoading(false);
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "mfgPlant":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, plantID: currentFieldVal};
					});
				}
				break;
			case "cycleCountId":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					setReportDetail((oldValues) => {
						return {...oldValues, cycleCountId: currentFieldVal};
					});
				}
				break;
			case "shipmentDateFrom":
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + 1000 * 3600 * 24 * 91;
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);

				/* Validate the following scenarios: 
                    - FROM date lower than TO date
                    - FROM date <= maxDate
                    - Date range not grather than 90 days
                */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "shipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");

				/* Validate the following scenarios: 
                    - TO date grather than FROM date
                    - TO date >= minDate
                    - Date range not grather than 90 days
                */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);

				if (daysPast_ < -1 || daysPast_ > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
		}
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		setShowTooltip(true);
		setDisableFilters(false);
		setdisableSearchBtn(false);
	};
	const onInitialized = (e) => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate + 1000 * 3600 * 24 * 91;
		let maxDate = new Date();
		maxDate.setTime(maxTime);
		setMaxToDate(maxDate);
		setMinToDate(currentDate);
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateFrom: dateFromBox.current.instance
					.option("value")
					.toISOString()
					.split("T")[0]
					.replace(/-/g, "/")
			};
		});
	};
	const onInitializedDateTo = (e) => {
		setReportDetail((oldValues) => {
			return {
				...oldValues,
				dateTo: dateToBox.current.instance.option("value").toLocaleDateString()
			};
		});
	};
	const onValueChanged = (e) => {
		setPlantIDSelected(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Cycle Count Report</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								onValueChanged={reportOptionOnChange}
								layout='horizontal'
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "mfgPlant"
								}}
								placeholder='Mfg. Plant'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={plantIDSelected}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								displayFormat={formatDate}
								width='150px'
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={currentDate}
								onInitialized={onInitialized}
								disabled={disableFilters}
								pickerType='calendar'
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateTo"
								}}
								ref={dateToBox}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								dateSerializationFormat='yyyy/MM/dd'
								max={maxToDate}
								min={minToDate}
								width='150px'
								onInitialized={onInitializedDateTo}
								disabled={disableFilters}
								pickerType='calendar'></DateBox>
						</div>
						<div className='dx-field'>
							<TextBox
								placeholder='Cycle Count Id'
								elementAttr={{
									id: "cycleCountId"
								}}
								ref={cycleCountId}
								disabled={disableFilters}
								onValueChanged={setReportParams}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<CycleCountReportGrid
					showSummaryColumns={showSummaryColumns}
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
