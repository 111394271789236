import React, {useState, useEffect, useRef, useCallback} from "react";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Editing,
	ColumnFixing,
	Pager,
	SearchPanel,
	RemoteOperations,
	Toolbar,
	Item,
	Scrolling,
	Selection,
	RequiredRule
} from "devextreme-react/data-grid";
import appInfo from "../../app-info";
import {alert, confirm} from "devextreme/ui/dialog";
import {Redirect} from "react-router-dom";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import TextBox from "devextreme/ui/text_box";
import Button from "devextreme-react/button";
import {TextBox as DxTextBox, Button as TextBoxButton} from "devextreme-react/text-box";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, firstTimeLoading}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	let canceled;
	let finalMsg = "";
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language =currentUser ? currentUser.data.userData.language:'';
	let token = currentUser ? currentUser.data.token :'';
	const permission = getPermission(currentUser, "ConfigurationMasters", "LinkServerMaster");

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	const [totalCount, setTotalCount] = useState(0);
	const [detailTotalCount, setDetailTotalCount] = useState(0);
	const [boxDetail, setBoxDetail] = useState(null);

	const getTotalCount = () => {
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
	};
	const onOptionChanged = (e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	};
	const onSelectionChanged = async (e) => {
		if (e.selectedRowsData.length > 0) {
			//Make sure selectedRowsData has data selected
			let rawArray = ["linkName", "serverName", "plantId"];
			let getDetailsURL = `${appInfo.configURL}LinkServerMaster/Detail`;
			let rowSelected = e.selectedRowsData[0];
			let params = `?`;

			const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";

			for (let i in rowSelected) {
				if (rawArray.indexOf(i) != -1 && isNotEmpty(rowSelected[i])) {
					params += `${i}=${rowSelected[i]}&`;
				}
			}
			// INFO: This request fill the second DG
			await fetch(`${getDetailsURL}${params}`, {
				method: "get",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json"
				}
			})
				.then((result) => result.json())
				.then((result) => {
					setDetailTotalCount(result.data.length);
					setBoxDetail(result.data);
				})
				.catch((error) => console.warn(error));
		}
	};
	const onRowUpdating = (e) => {
		e.newData = {...e.oldData, ...e.newData};
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		if (e.changes && e.changes[0].type !== "remove") {
			e.promise = confirm("Are you sure you want to save?").then((response) => {
				e.cancel = !response;
			});
		}
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const onEditCanceled = useCallback((e) => {
		setMode("View");
	}, []);
	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm("Unsaved data exist. <br/> Are you sure you want to cancel?").then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);
	const onEditorPreparing = (e) => {
		if (
			e.parentType === "dataRow" &&
			(e.dataField === "linkName" ||
				e.dataField === "serverName" ||
				e.dataField === "databaseName" ||
				e.dataField === "plantId" ||
				e.dataField === "processInfo" ||
				e.dataField === "extraInfo" ||
				e.dataField === "inspectionPlant" ||
				e.dataField === "dbObjectName" ||
				e.dataField === "remarks")
		) {
			let onValueChanged = e.editorOptions.onValueChanged;
			e.editorOptions.onValueChanged = function (args) {
				e.editorElement.classList.add("uppercase");
				onValueChanged.apply(this, arguments);
			};
		}
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = e.data;
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	function renderCell(data) {
		var options;
		if (permission && permission.update) {
			options = {
				icon: "key",
				stylingMode: "text",
				type: "default",

				onClick() {
					const $textBox = document.getElementById(`password-${data.key}`);
					const textBox = TextBox.getInstance($textBox);
					textBox.option("mode", textBox.option("mode") === "password" ? "text" : "password");
				}
			};
		}

		const onInitialized = function (e) {
			e.component.option("mode", "password");
		};

		return (
			<DxTextBox
				id={`password-${data.key}`}
				value={data.value}
				onInitialized={onInitialized}
				height='35px'>
				{permission.update ? <TextBoxButton name='password' options={options} /> : null}
			</DxTextBox>
		);
	}
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<DataGrid
				dataSource={customDataSource}
				ref={dataGrid}
				elementAttr={{
					id: "LinkServerMGridContainer"
				}}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={120}
				onContentReady={getTotalCount}
				onEditorPreparing={onEditorPreparing}
				onOptionChanged={onOptionChanged}
				onSelectionChanged={onSelectionChanged}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowInserted={onRowInserted}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}>
				<Editing
					mode='row'
					startEditAction={dataGridSettings.startEditAction}
					allowUpdating={permission ? permission.update : false}
					allowAdding={permission ? permission.create : false}
					allowDeleting={permission ? permission.delete : false}
					texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
					refreshMode='full'
				/>
				<ColumnFixing enabled={true} />
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>{dataGridSettings.mode}</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFilters}
						/>
					</Item>
					<Item name='addRowButton' />
					<Item name='exportButton' />
					<Item name='searchPanel' />
				</Toolbar>
				<SearchPanel visible={true} width={240} placeholder='Search...' />
				<Selection mode='single' />
				<RemoteOperations groupPaging={true} />
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<Column
					dataField='id'
					alignment='left'
					cssClass='small-space'
					caption='No.'
					visible={false}
				/>
				<Column
					dataField='linkName'
					cssClass='small-space'
					caption='Link Name'
					editorOptions={{maxLength: 50}}>
					<RequiredRule />
				</Column>
				<Column
					dataField='serverName'
					cssClass='small-space'
					editorOptions={{maxLength: 100}}
					caption='Server Name'
				/>
				<Column
					dataField='databaseName'
					cssClass='small-space'
					editorOptions={{maxLength: 100}}
					caption='Database'
				/>
				<Column
					dataField='userId'
					cssClass='small-space'
					caption='User Id'
					editorOptions={{maxLength: 100}}
				/>
				<Column
					dataField='password'
					cssClass='small-space'
					caption='Password'
					cellRender={renderCell}
					editorOptions={{maxLength: 100}}
				/>
				<Column
					dataField='plantId'
					cssClass='small-space'
					caption='Mfg Plant'
					editorOptions={{maxLength: 50}}
				/>
				<Column
					dataField='processInfo'
					cssClass='small-space'
					caption='Process Info'
					editorOptions={{maxLength: 50}}
				/>
				<Column
					dataField='extraInfo'
					cssClass='small-space'
					caption='Extra Info'
					editorOptions={{maxLength: 400}}
				/>
				<Column
					dataField='inspectionPlant'
					cssClass='small-space'
					caption='Inspection Plan'
					editorOptions={{maxLength: 100}}
				/>
				<Column
					dataField='dbObjectName'
					cssClass='small-space'
					caption='DB Object'
					editorOptions={{maxLength: 100}}
				/>
				<Column
					dataField='remarks'
					cssClass='small-space'
					caption='Remarks'
					editorOptions={{maxLength: 100}}
				/>
				<Column dataField='identifier' cssClass='small-space' visible={false} />
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual' />
			</DataGrid>

			<DataGrid
				elementAttr={{
					id: "boxGridDetailsContainer"
				}}
				dataSource={boxDetail}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='widget'
				columnMinWidth={140}>
				<Column
					dataField='id'
					alignment='left'
					cssClass='small-space'
					caption='No'
					visible={false}
				/>
				<Column dataField='procedureName' cssClass='small-space' caption='Procedure Name' />
				<Column dataField='plantId' cssClass='small-space' caption='Mfg Plant' />
				<Column
					dataField='lastExecutionDate'
					cssClass='small-space'
					dataType='datetime'
					caption='Last Execution Date'
				/>
				<Column dataField='executionStatus' cssClass='small-space' caption='Execution Status' />
			</DataGrid>
			<div className={"total-label-linkServer"}>
				<span>Total Records: </span>
				<label>{detailTotalCount}</label>
			</div>
		</React.Fragment>
	);
};
