import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import axios from "axios";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import ShipmentReportGrid from "./ShipmentReportGrid";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	let token = user ? user.data.token : '';
	let formatDate = user ? user.data.userData.formatDate : '';
	const permission = getPermission(user, "MISReports", "ShipmentReport");

	/* Instance components in order to reset them when necessary */
	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const asnSelectBox = useRef(null);
	const partNumSelectBox = useRef(null);
	const reportRadioBtn = useRef(null);
	const partNumRadioBtn = useRef(null);

	const reportOptions = ["Summary", "Detail"];
	const partNumber = ["Customer", "SEWS"];

	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [partNumSelected, setPartNumberSelected] = useState(partNumber[0]);
	const [custPartNumList, setCustPartNumList] = useState();
	const [fromDateValue, setFromDateValue] = useState(currentDate);
	const [toDateValue, setToDateValue] = useState(currentDate);
	const [ASNNum, setASSNum] = useState(null);
	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const [showDGColumns, setShowDGColumns] = useState(false);
	const [partTitle, setPartTitle] = useState("Cust. Part No.");
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		custPartNo: "",
		ASN: "",
		reportOption: "S",
		partNumberOption: "C",
		dateFrom: fromDateValue,
		dateTo: toDateValue
	});

	const reportParamsArr = [
		"plantID",
		"reportOption",
		"partNumberOption",
		"custPartNo",
		"ASN",
		"dateFrom",
		"dateTo"
	];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getASNnum = `${appInfo.reportURL}ShipmentReport/ASNNumbers`;
		let getPlants = `${appInfo.reportURL}InventoryReport/Plants`;
		(async () => {
				await fetch(getASNnum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => handleErrors(result))
					.then((response) => response.json())
					.then((result) => {
						let customASN = result.data.map((asn) => {
							return asn.Value;
						});
						setASSNum(customASN);
					})
					.catch((error) => {
						console.warn(error);
					});
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				});
		})();
	}, []);

	const partNumOnValueChange = (e) => {
		e.promise = setPartNumberSelected(e.value);
		e.promise = getSelectValues(e.value); //Make sure to send the correct value
		partNumSelectBox.current.instance.reset();
		setShowTooltip(true);

		if (e.value == "SEWS") {
			setPartTitle("SEWS Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "S"};
			});
		} else {
			setPartTitle("Cust. Part. No.");
			setReportDetail((oldValues) => {
				return {...oldValues, partNumberReport: "C"};
			});
		}
	};
	const reportOptionOnChange = (e) => {
		e.promise = setReportOptionSelected(e.value);
		if (e.value == "Summary") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "S"};
			});
			setShowDGColumns(false);
		} else {
			//turn on flag to show extra columns
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "D"};
			});
		}
	};
	const partNumInit = () => {
		getSelectValues(partNumSelected); //Send the default value = Customer
	};
	const getSelectValues = async (partType) => {
		let partRequestURL;
		if (partType === "Customer") {
			partRequestURL = `${appInfo.reportURL}ShipmentReport/CustPartNumbers`;
		} else {
			partRequestURL = `${appInfo.reportURL}ShipmentReport/SEWSNumbers`;
		}
		await axios
			.get(partRequestURL, {headers: {Authorization: `Bearer ${token}`}})
			.then((result) => {
				let customPartNum;
				customPartNum = result.data.data.map((partNum) => {
					return partNum.Value;
				});
				setCustPartNumList(customPartNum);
			})
			.catch((error) => {
				console.warn(error);
			});
	};
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}ShipmentReport${params}`
			})
		);
		setShowTooltip(false);
		setfirstTimeLoading(false);
		setDisableFilters(true);
		setdisableSearchBtn(true);
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "shipmentDateFrom":
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + 1000 * 3600 * 24 * 89;
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);
				setFromDateValue(currentFieldVal);
				/* Validate the following scenarios: 
                    - FROM date lower than TO date
                    - FROM date <= maxDate
                    - Date range not grather than 90 days
                */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "shipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");
				setToDateValue(currentFieldVal);
				/* Validate the following scenarios: 
                    - TO date grather than FROM date
                    - TO date >= minDate
                    - Date range not grather than 90 days
                */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);
				
				if (daysPast_ < -1 || daysPast_ > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
			case "shipmentASN":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, ASN: currentFieldVal};
					});
				}
				break;
			case "partNumberSelect":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, custPartNo: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, custPartNo: currentFieldVal};
					});
				}
				break;
		}
	};
	const clearFilters = () => {
		setShowTooltip(true);
		setDisableFilters(false);
		setdisableSearchBtn(false);
		setCustomDataSource(null);
	};
	const onInitialized = (e) => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate + 1000 * 3600 * 24 * 91;
		let maxDate = new Date();
		maxDate.setTime(maxTime);
		setMaxToDate(maxDate);
		setMinToDate(currentDate);
	};
	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
		//sessionStorage.setItem("currentPlant", e.value);
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Shipment Report</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								layout='horizontal'
								onValueChanged={reportOptionOnChange}
								ref={reportRadioBtn}
								disabled={disableFilters}
							/>
						</div>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

						<div className='dx-field'>
							<div className='dx-labels'>Part Number</div>
							<RadioGroup
								items={partNumber}
								value={partNumSelected}
								onValueChanged={partNumOnValueChange}
								onInitialized={partNumInit}
								layout='horizontal'
								ref={partNumRadioBtn}
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "plantInventorySelect"
								}}
								placeholder='Plant Code - Plant Name'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={currentPlant}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateFrom"
								}}
								value={fromDateValue}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								displayFormat={formatDate}
								width='180px'
								disabled={disableFilters}
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={currentDate}
								onInitialized={onInitialized}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "shipmentDateTo"
								}}
								ref={dateToBox}
								value={toDateValue}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								dateSerializationFormat='yyyy/MM/dd'
								max={maxToDate}
								min={minToDate}
								disabled={disableFilters}
								width='150px'></DateBox>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='ASN No.'
								elementAttr={{
									id: "shipmentASN"
								}}
								onValueChanged={setReportParams}
								ref={asnSelectBox}
								searchEnabled={true}
								items={ASNNum}
								disabled={disableFilters}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder={partTitle}
								elementAttr={{
									id: "partNumberSelect"
								}}
								ref={partNumSelectBox}
								searchEnabled={true}
								items={custPartNumList}
								disabled={disableFilters}
								onValueChanged={setReportParams}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<ShipmentReportGrid
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
