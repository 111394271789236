import React, {useState, useRef, useEffect, useCallback, useMemo} from "react";
import Form, {Item, Label, SimpleItem} from "devextreme-react/form";
import Button from "devextreme-react/button";
import appInfo from "../../app-info";
import axios from "axios";
import {saveAs} from "file-saver";
import {LoadPanel} from "devextreme-react/load-panel";
import notify from "devextreme/ui/notify";
import {Template} from "devextreme-react/core/template";
import {useAuth} from "../../contexts/auth";
import {Redirect} from "react-router-dom";
import { getPermission, handleErrors } from "../../pages/configuration/helpers/globalFunctions";
import CustomStore from "devextreme/data/custom_store";
import IndicatorIcon from "./AdditionalComponents/indicatorIcon";
import DataSource from "devextreme/data/data_source";
export default () => {
	const {user} = useAuth();
	const rootUrl = {
		url: `${appInfo.shippingURL}ShipmentPlanChangeLog/Export`
	};
	const currentDate = new Date().toISOString().split("T")[0].replace(/-/g, "/");
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	const permission = getPermission(currentUser, "ShippingPlan", "ShipmentPlanChangeLog");
	let token = user.data.token;
	let language = currentUser.data.userData.language;
	let formatDate = currentUser.data.userData.formatDate;
	const changeLogParams = [
		"plantId",
		"sewsPartNo",
		"shipToWhse",
		"customerCode",
		"from",
		"to",
		"exportDate",
		"exportTime"
	];
	const [disableFields, setDisableFields]= useState(true);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [plantDataSource, setPlantDataSource] = useState(null);
	const [loadPanelVisible, setLoadPanelVisible] = useState(false);
	const [minDate, setMinDate] = useState(new Date());
	const [maxDate, setMaxDate] = useState();
	const [disableExportBtn, setdisableExportBtn] = useState(false);
	const [fromDateVal, setFromDateVal] = useState(currentDate);
	const [toDateVal, setToDateVal] = useState(currentDate);
	const [sewsPartSelected, setSewsPartSelected] = useState("");
	const [customerCodeSelected, setCustomerCodeSelected] = useState("");
	const [whseSelected, setWhseSelected] = useState("");

	/* Instance components in order to reset them when necessary */
	const plant = useRef(null);
	const [isLoadedCustPart, setIsLoadedCustPart] = useState(true);
	const [isLoadedSewsPart, setIsLoadedSewsPart] = useState(true);
	const [isLoadedShipToWhse, setIsLoadedShipToWhse] = useState(true);
	const custCode_renderLoadIndicator = useCallback(() => <IndicatorIcon isLoaded={isLoadedCustPart} />, [isLoadedCustPart]);
	const sewsPartNo_renderLoadIndicator = useCallback(() => <IndicatorIcon isLoaded={isLoadedSewsPart} />, [isLoadedSewsPart]);
	const shipToWhse_renderLoadIndicator = useCallback(() => <IndicatorIcon isLoaded={isLoadedShipToWhse} />, [isLoadedShipToWhse]);

	const [planData, setData] = useState({
		labelLocation: "top",
		readOnly: false,
		showColon: true,
		minColWidth: 300,
		colCount: 4
	});
	const ItemPlant = (args) => {
		return (
			<div>
				{args.plantId} - {args.value}
			</div>
		);
	};
	const selectEditorOptions = {
		dataSource: plantDataSource,
		valueExpr: "plantId",
		value: plantIDSelected,
		displayExpr: "plantId",
		searchEnabled: true,
		searchExpr: ["plantId", "value"],
		searchMode: "contains",
		itemTemplate: "ItemPlant",
		onSelectionChanged: (e) => {
			setPlantIDSelected(e.selectedItem.plantId);
			sessionStorage.setItem("plantIDSelected", e.selectedItem.plantId);
		}
	};

	useEffect(() => {
		(async () => {
			let getPlant = `${appInfo.shippingURL}ShipmentPlanChangeLog/Plants`;
			let getActivePlant = `${appInfo.shippingURL}ShipmentPlanChangeLog/ActivePlant`;

			await fetch(getPlant, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((response) => handleErrors(response))
				.then((result) => {
					if (result.ok) {
						return result.json();
					}
					throw new Error('Error getting plants');
				})
				.then((result) => {
					setPlantDataSource(result.data);
				})
				.catch((error) => {
					console.warn(error);
				});

			await fetch(getActivePlant, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					setPlantIDSelected(result[0].plantId);
					setDisableFields(false);
				})
				.catch((error) => {
					if (error.name === "AbortError") {
						console.warn("ShipmentPlanChangeLog fail getting active plant");
					}
				});
		})();
	}, []);

	const onInitialized = (e) => {
		setMinDate(fromDateVal);
		let fromDate, maxTime, maxDate;
		fromDate = new Date(fromDateVal).getTime();
		maxTime = fromDate + 1000 * 3600 * 24 * 365;
		maxDate = new Date();
		maxDate.setTime(maxTime);
		setMaxDate(maxDate);
	};

	const hideLoadPanel = () => {
		setLoadPanelVisible(false);
	};
	const onValueChangedFromDate = (e) => {
		let fromDate, maxTime, maxDate;
			fromDate = new Date(e.value).getTime();
			maxTime = fromDate + 1000 * 3600 * 24 * 365;
			maxDate = new Date();
			maxDate.setTime(maxTime);
			setMaxDate(maxDate);
			if (e.value === toDateVal) {
				setdisableExportBtn(false);
			} else if (e.value > toDateVal) {
				setdisableExportBtn(true);
			} else {
				setdisableExportBtn(false);
			}
			setFromDateVal(e.value);
			setMinDate(e.value);
	}
	const onVal = (e) => {
		setToDateVal(e.value);
		setMinDate(fromDateVal);
	};
	const sumbitAction = async () => {
		/* Get current date and time to send endpoint */
		let today = new Date();
		let fileName;
		let hour = today.getHours();
		let minutes = today.getMinutes();
		let monthReport = today.getMonth() + 1;
		let yearReport = today.getUTCFullYear();
		let dayReport = today.getDate();
		let time = `${hour}:${minutes}`;
		let fullDateReport = `${yearReport}/${monthReport}/${dayReport}`;

		const data = {
			plantId: plantIDSelected,
			sewsPartNo: sewsPartSelected,
			shipToWhse: whseSelected,
			customerCode: customerCodeSelected,
			from: fromDateVal,
			to: toDateVal,
			exportDate: fullDateReport,
			exportTime: encodeURIComponent(time)
		};

		fileName =
			language === "ES"
				? (fileName = `Shipment Plan Change Log ${dayReport}-${monthReport}-${yearReport}.xlsx`)
				: (fileName = `Shipment Plan Change Log ${monthReport}-${dayReport}-${yearReport}.xlsx`);

		/* Build URL before send  */
		const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
		let params = "?";
		changeLogParams.forEach(function (i) {
			if (i in data && isNotEmpty(data[i])) {
				params += `${i}=${JSON.stringify(data[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		let exportURL = `${rootUrl.url}${params}`;
		getDownloadFile(exportURL, token, fileName);
	};
	const getDownloadFile = async (url, token, fileName) => {
		setLoadPanelVisible(true);
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => response.data)
			.then((buffer) => {
				hideLoadPanel();
				if (buffer.size > 0) {
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					return notify("[20024]No records found", "warning", 2000);
				}
			})
			.catch(async (e) => {
				hideLoadPanel();
				let statusCode = e.response.status;
				let responseObj = await e.response.data.text();
				let filteredMessage = responseObj.replace(/{"message":"/g, "");
				return alert(filteredMessage.replace(/"}/g, ""));
			});
	};
	const onOptionChangedDateTo = (e) => {
		if (e.name === "validationErrors") {
			setdisableExportBtn(true);
		} else {
			setdisableExportBtn(false);
		}
	};
	/*  BoxCustomer Events  */
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const customerCode_store = new CustomStore({
		key:"id",
		load: async(loadOptions) => {
			console.log('render store');
			let params = "&";
			if(loadOptions.searchValue){
				loadOptions.customerCode=loadOptions.searchValue;
				setIsLoadedCustPart(false);
			}
			["skip", "take","customerCode"].forEach(function (i) {
				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
			return fetch(
				encodeURI(`${appInfo.shippingURL}ShipmentPlanChangeLog/CustomerCodes?plantId=${plantIDSelected}&sewsPartNo=${sewsPartSelected}&shipToWhse=${whseSelected}${params}`),
				{
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				}
			)
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((response) => {
					setIsLoadedCustPart(true);
					return response.data;
				})
				.catch((error) => {
					if (error) {
						console.warn(error);
					}
					if (error.name) {
						console.warn(
							"Shipment Plan Change Log fail getting SEWS Part No at Dropdown Box ",
							error.name
						);
					}
				});
				
		},byKey:(key)=>{
			console.log('key', key);
			return key;
		}
	});

	const customerCode_onValueChanged = (e) => {
		if(e.value){
			setCustomerCodeSelected(e.value);
		}else{
			setCustomerCodeSelected("");
			return () => {
				customerCode_ds.dispose();
			};
		}
	};
	/* SEWS Part No Events */
	
	const sews_store =  new CustomStore({
		key: "id",
		load: async(loadOptions) => {
			let params = "&";
			if(loadOptions.searchValue){
				loadOptions.sewsPartNo=loadOptions.searchValue;
				console.log(loadOptions);
				setIsLoadedSewsPart(false);
			}
			["skip", "take","sewsPartNo"].forEach(function (i) {
				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
			
			return fetch(
				encodeURI(`${appInfo.shippingURL}ShipmentPlanChangeLog/SewsPartNumbers?plantId=${plantIDSelected}&customerCode=${customerCodeSelected}&shipToWhse=${whseSelected}${params}`),
				{
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				}
			)
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((response) => {
					setIsLoadedSewsPart(true);
					return response.data;
				})
				.catch((error) => {
					if (error) {
						console.warn(error);
					}
					if (error.name) {
						console.warn(
							"Shipment Plan Change Log fail getting Cust part No. at Dropdown Box ",
							error.name
						);
					}
				});
		},byKey:(key)=>{
			console.log('key',key);
			return key;
		}
	});
	const shipToWh_store = new CustomStore({
		key: "id",
		load: async(loadOptions) => {
			let params = "&";
			if(loadOptions.searchValue){
				loadOptions.shipToWhse=loadOptions.searchValue;
				setIsLoadedShipToWhse(false);
			}
			[ "skip", "take","shipToWhse"].forEach(function (i) {
				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
			return fetch(
				encodeURI(`${appInfo.shippingURL}ShipmentPlanChangeLog/ShipToWhse?plantId=${plantIDSelected}&customerCode=${customerCodeSelected}&sewsPartNo=${sewsPartSelected}${params}`),
				{
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				}
			)
				.then((response) => handleErrors(response))
				.then((response) => response.json())
				.then((response) => {
					setIsLoadedShipToWhse(true);
					return response.data;
				})
				.catch((error) => {
					if (error) {
						console.warn(error);
					}
					if (error.name) {
						console.warn("Shipment Plan Change Log fail getting Whse at Dropdown Box", error.name);
					}
				});
		}, byKey:(key)=>{
			return key;
		}
	});
	const shipToWh_ds = useMemo(()=>
		new DataSource({
			store: shipToWh_store,
		}), 
	[plantIDSelected,sewsPartSelected,customerCodeSelected])
	const customerCode_ds =  useMemo(() => 
		new DataSource({
		 store: customerCode_store,
		}),
	 [plantIDSelected,sewsPartSelected,whseSelected],
);
	const shipToWh_onValueChanged = (e) => {
		if(e.value){
			setWhseSelected(e.value);
		}else{
			setWhseSelected("");
			return () => {
				shipToWh_ds.dispose();
			};
		}
	}
const sews_onValueChanged = (e) => {
	if(e.value){
		setSewsPartSelected(e.value);
	}else{
		setSewsPartSelected("");
		return () => {
			sews_ds.dispose();
		};
	}
};
	const sews_ds = useMemo(()=> 
		new DataSource({
			store:sews_store,
		}),[plantIDSelected,customerCodeSelected,whseSelected]);
	/* Improve EditorOptions and Template tag for better performance in form */
	const dateFrom_editorOptions = {
		value: fromDateVal,
		type: 'date',
		id: "dateFrom",
		displayFormat: formatDate,
		pickerType: 'calendar',
		useMaskBehavior: true,
		placeholder: 'Date From',
		dateSerializationFormat: 'yyyy/MM/dd',
		onValueChanged: onValueChangedFromDate
	};
	const dateTo_editorOptions = {
		value: toDateVal,
		type: 'date',
		id: "dateTo",
		displayFormat: formatDate,
		placeholder: 'To',
		dateSerializationFormat: 'yyyy/MM/dd',
		min: minDate,
		max: maxDate,
		onOptionChanged: onOptionChangedDateTo,
		onValueChanged: onVal,
		onInitialized: onInitialized,
		pickerType: 'calendar'
	}
	const customerCode_editorOptions = {
		dataSource: customerCode_ds,
		className:'whsShipmentForm',
		searchExpr: 'value',
		displayExpr: 'value',
		valueExpr: 'value',
		searchEnabled: true,
		showClearButton: true,
		disabled: disableFields,
		onValueChanged: customerCode_onValueChanged,
		dropDownButtonTemplate: "loadIndicator1"
	}
	const sewsPartNo_editorOptions = {
		dataSource: sews_ds,
		className: 'sewsShipmentForm',
		displayExpr: 'value',
		searchExpr: 'value',
		valueExpr:'value',
		searchEnabled: true,
		showClearButton: true,
		searchTimeout: 1000,
		disabled: disableFields,
		dropDownButtonTemplate:'loadIndicatorSews',
		onValueChanged:sews_onValueChanged
	}
	const shipToWh_editorOptions = {
		dataSource: shipToWh_ds,
		className: 'whsShipmentForm',
		searchExpr: 'value',
		valueExpr: 'value',
		displayExpr:'value',
		disabled: disableFields,
		searchEnabled: true,
		showClearButton: true,
		dropDownButtonTemplate: 'loadIndicator2',
		onValueChanged: shipToWh_onValueChanged,
	}

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div className={"content-block"}>
				<h2>Shipment Plan Change Log</h2>
				<Button icon='export' text='  Export' disabled={disableExportBtn} onClick={sumbitAction} />
				<span className={"total-label-shipment"}> Mode: </span>
				<label className={"total-label-shipment"} id='modeLabel'>View</label>
				<div id='form-container'>
					<div className='widget-container'>
						<Form
							id='formPlantMaster'
							labelLocation={planData.labelLocation}
							minColWidth={planData.minColWidth}
							colCount={planData.colCount}
							width={planData.width}
							validationGroup='PlantData'>
							<Item
								dataField='plantId'
								colSpan={2}
								ref={plant}
								editorType='dxSelectBox'
								editorOptions={selectEditorOptions}>
								<Template name='ItemPlant' render={ItemPlant} />
							</Item>
							<Item dataField={'SEWS Part No'} 
								editorOptions={sewsPartNo_editorOptions} 
								editorType="dxSelectBox" colSpan={2}>
									<Template
										name="loadIndicatorSews"
										render={sewsPartNo_renderLoadIndicator}/>
							</Item>
							<Item dataField='Customer Code' 
								editorType="dxSelectBox" 
								editorOptions={customerCode_editorOptions} 
								colSpan={2}>
									<Template
										name="loadIndicator1"
										render={custCode_renderLoadIndicator}/>
							</Item>
							<Item dataField='Ship to Whse' 
								editorType="dxSelectBox"
								editorOptions={shipToWh_editorOptions}
								colSpan={2}>
								<Template name="loadIndicator2"
									render={shipToWhse_renderLoadIndicator}
								/>
							</Item>
							<Item dataField='date_from' editorType="dxDateBox" 
								editorOptions={dateFrom_editorOptions} colSpan={2}>
								<Label text='Date From' />
							</Item>
							<Item dataField='date_to' editorType="dxDateBox" editorOptions={dateTo_editorOptions} colSpan={2}>
								<Label text='To' />
							</Item>
						</Form>
					</div>
				</div>
			</div>
			<LoadPanel
				visible={loadPanelVisible}
				onHiding={hideLoadPanel}
				showIndicator={true}
				shading={true}
				showPane={true}
			/>
		</React.Fragment>
	);
};
