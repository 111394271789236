import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';


export default function DeleteButtonCell(cellData){
    let deleteActive = true;
    const deleteAction = () => {
        deleteActive= false;
        cellData.component.deleteRow(cellData.row.rowIndex);
    }
    const unDeleteAction = () => {
        deleteActive= true;
        cellData.component.undeleteRow(cellData.row.rowIndex);
    }
  
    return(
        <span>
            <Button elementAttr={{id:'deleteBtn'}} className="deleteBtn" icon='trash' visible={deleteActive} onClick={deleteAction}/>
            <Button elementAttr={{id:'unDeleteBtn'}} className="deleteBtn" icon="undo" visible={deleteActive} onClick={unDeleteAction}/>
        </span>
        
    )
}