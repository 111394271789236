import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import INCOMPLETESHIPMENTREPORTGrid from "./INCOMPLETESHIPMENTREPORTGrid";
import DataSource from "devextreme/data/data_source";
import {alert} from "devextreme/ui/dialog";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	const [showSelectBox, setShowSelectBox] = useState(true);
	let token = user ? user.data.token : '';
	let formatDate = user ? user.data.userData.formatDate : '';
	const permission = getPermission(user, "MISReports", "INCOMPLETESHIPMENTREPORT");

	/* Instance components in order to reset them when necessary */

	const dateFromBox = useRef(null);
	const dateToBox = useRef(null);
	const asnSelectBox = useRef(null);
	const sewsSelectBox=useRef(null);
	const partNumSelectBox = useRef(null);
	const partNumRadioBtn = useRef(null);

	const partNumber = ["Customer", "SEWS"];

	const [maxToDate, setMaxToDate] = useState();
	const [minToDate, setMinToDate] = useState(new Date(2000, 0, 1));
	const currentDate = new Date();
	const [partNumSelected, setPartNumberSelected] = useState(partNumber[0]);
	const [ASNNum, setASSNum] = useState(null);
	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState();

	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		custPartNo: "",
		ASN: "",
		partNumberOption: "C",
		dateFrom: "",
		dateTo: ""
	});
	const reportParamsArr = [
		"plantID",
		"partNumberOption",
		"custPartNo",
		"ASN",
		"dateFrom",
		"dateTo"
	];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getASNnum = `${appInfo.reportURL}IncompleteShipmentReport/ASNNumbers`;
		let getActivePlant = `${appInfo.reportURL}IncompleteShipmentReport/ActivePlant`;
		(async () => {
	
				await fetch(getASNnum, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then(result => handleErrors(result))
					.then(response => response.json())
					.then(result => {
						let customASN = result.data.map(asn => {
							return asn.Value;
						});
						setASSNum(customASN);
					})
					.catch(error => {
						console.warn(error);
					});
			
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then(result => result.json())
					.then(result => {
						setReportDetail(oldValues => {
							return {...oldValues, plantId: result.Id};
						});
						setcurrentPlant(result.Id);
					})
					.catch((error) => {
				console.error(error, " Over shipment trace report fail getting active plant");
			});
			await fetch(`${appInfo.reportURL}IncompleteShipmentReport/Plants`, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then(result => result.json())
				.then(result => {
					let customPlant = result.map(plant => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch(err => console.warn(err));
		})();
	}, []);

	const partNumOnValueChange = e => {
		e.promise = setPartNumberSelected(e.value);
		partNumSelectBox.current.instance.reset();
		setShowTooltip(true);

		if (e.value == "SEWS") {
			setShowSelectBox(false);
			setReportDetail(oldValues => {
				return {...oldValues, partNumberReport: "S"};
			});
		} else {
			setShowSelectBox(true);
			setReportDetail(oldValues => {
				return {...oldValues, partNumberReport: "C"};
			});
		}
	};
	const custPartNoStore = new DataSource({
		key: "Id",
		load: loadOptions => {
			let params = "&";
			["skip", "take", "searchExpr", "searchValue"].forEach(function (i) {
				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
			return fetch(`${appInfo.reportURL}IncompleteShipmentReport/CustPartNumbers?${params}`, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then(response => response.json())
				.then(response => {
					return response.data;
				})
				.catch(err => console.warn(err));
		}
	});
	const sewsPartNoStore = new DataSource({
		key: "Id",
		load: loadOptions => {
			let params = "&";
			["skip", "take", "searchExpr", "searchValue"].forEach(function (i) {
				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
			return fetch(`${appInfo.reportURL}IncompleteShipmentReport/SEWSNumbers?${params}`, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then(response => response.json())
				.then(response => {
					return response.data;
				})
				.catch(err => console.warn(err));
		}
	});

	const isNotEmpty = value => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		
		if (
			(reportDetail.dateFrom === null ||
			reportDetail.dateFrom === "" ||
			reportDetail.dateTo === null ||
			reportDetail.dateTo === "") && (reportDetail.ASN===""||reportDetail.ASN===null)
		) {
			alert("[20265] Date from and Date to Cannot Be Blank.");
			return;
		} else {
			let params = "?";
			reportParamsArr.forEach(function (i) {
				if (i in reportDetail && isNotEmpty(reportDetail[i])) {
					params += `${i}=${JSON.stringify(reportDetail[i])}&`;
				}
			});
			params = params.replace(/"/g, "");
			params = params.slice(0, -1);

			setCustomDataSource(
				store({
					url: `${appInfo.reportURL}IncompleteShipmentReport${params}`
				})
			);
			setShowTooltip(false);
			setfirstTimeLoading(false);
			setDisableFilters(true);
		}
	};
	const setReportParams = e => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "inconmpleteShipmentDateFrom":
				/* set logic for getting datebox Rule not grather than 90 days */
				let fromDate = new Date(currentFieldVal).getTime();
				let maxTime = fromDate + ((1000 * 3600 * 24 )* 89);
				let maxDate = new Date();
				maxDate.setTime(maxTime);
				setMaxToDate(maxDate);
				setMinToDate(currentFieldVal);
				/* Validate the following scenarios: 
            - FROM date lower than TO date
            - FROM date <= maxDate
            - Date range not grather than 90 days
        */
				let toDateVal = dateToBox.current.instance.option("value");
				//calculate how many days has between from date and the current TO date selected
				let toDateTime = new Date(toDateVal).getTime();
				let differenceITime = toDateTime - fromDate; // consider from date can be too older date
				let daysPast = differenceITime / (1000 * 3600 * 24);

				if (daysPast < 0 || daysPast > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail(oldValues => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				} else {
					setReportDetail(oldValues => {
						return {...oldValues, dateFrom: currentFieldVal};
					});
				}
				break;
			case "incompleteShipmentDateTo":
				/* Validate TO date is not grather than FROM */
				let toDate = currentFieldVal;
				let fromDateVal = dateFromBox.current.instance.option("value");

				/* Validate the following scenarios: 
            - TO date grather than FROM date
            - TO date >= minDate
            - Date range not grather than 90 days
        */
				let toDateTime_ = new Date(toDate).getTime();
				let fromDateTime = new Date(fromDateVal).getTime();
				let differenceITime_ = toDateTime_ - fromDateTime; // consider from date can be too older date
				let daysPast_ = differenceITime_ / (1000 * 3600 * 24);

				if (daysPast_ < -1 || daysPast_ > 91) {
					setdisableSearchBtn(true);
				} else {
					setdisableSearchBtn(false);
				}

				if (currentFieldVal !== "") {
					setReportDetail(oldValues => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				} else {
					setReportDetail(oldValues => {
						return {...oldValues, dateTo: currentFieldVal};
					});
				}
				break;
			case "incompleteShipmentASN":
				if (currentFieldVal !== "") {
					setReportDetail(oldValues => {
						return {...oldValues, ASN: currentFieldVal};
					});
				} else {
					setReportDetail(oldValues => {
						return {...oldValues, ASN: currentFieldVal};
					});
				}
				break;
			case "partNumberSelect":
				if (currentFieldVal !== "") {
					setReportDetail(oldValues => {
						return {...oldValues, custPartNo: currentFieldVal};
					});
				} else {
					setReportDetail(oldValues => {
						return {...oldValues, custPartNo: currentFieldVal};
					});
				}
				break;
		}
	};
	const clearFilters = () => {
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		sessionStorage.setItem("totalCount", 0);
		setShowTooltip(true);
		setCustomDataSource(null);
		setDisableFilters(false);
	};
	const onInitialized = () => {
		let fromDate = new Date(currentDate).getTime();
		let maxTime = fromDate +(( 1000 * 3600 * 24) * 89);
		let maxDate = new Date();
		let dateFormatBuild = "";
		dateFormatBuild = `${currentDate.getUTCFullYear()}/${currentDate.getMonth() + 1}/${currentDate.getDate()}`;
		maxDate.setTime(maxTime);
		setMaxToDate(maxDate);
		setMinToDate(currentDate);

		setReportDetail(oldValues => {
			return {...oldValues, dateTo: dateFormatBuild};
		});
		setReportDetail(oldValues => {
			return {...oldValues, dateFrom: dateFormatBuild};
		});
	};
	const onValueChanged = e => {
		setcurrentPlant(e.value);
		setReportDetail(oldValues => {
			return {...oldValues, plantID: e.value};
		});
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Incomplete Shipment Report</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Part Number</div>
							<RadioGroup
								items={partNumber}
								value={partNumSelected}
								onValueChanged={partNumOnValueChange}
								layout='horizontal'
								ref={partNumRadioBtn}
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "incompleteShipmentSelect"
								}}
								placeholder='Plant Code - Plant Name'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={currentPlant}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "inconmpleteShipmentDateFrom"
								}}
								ref={dateFromBox}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='Date From'
								displayFormat={formatDate}
								showClearButton={true}
								width='160px'
								dateSerializationFormat='yyyy/MM/dd'
								defaultValue={currentDate}
								onInitialized={onInitialized}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<DateBox
								elementAttr={{
									id: "incompleteShipmentDateTo"
								}}
								ref={dateToBox}
								defaultValue={currentDate}
								onValueChanged={setReportParams}
								useMaskBehavior={true}
								placeholder='To'
								displayFormat={formatDate}
								dateSerializationFormat='yyyy/MM/dd'
								showClearButton={true}
								max={maxToDate}
								min={minToDate}
								disabled={disableFilters}
								width='160px'
							></DateBox>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='ASN No.'
								elementAttr={{
									id: "incompleteShipmentASN"
								}}
								onValueChanged={setReportParams}
								ref={asnSelectBox}
								searchEnabled={true}
								items={ASNNum}
								showClearButton={true}
								width='160px'
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Cust. Part No.'
								elementAttr={{
									id: "custPartNo"
								}}
								ref={partNumSelectBox}
								dataSource={custPartNoStore}
								displayExpr='Value'
								valueExpr='Value'
								width='180px'
								onValueChanged={setReportParams}
								showClearButton={true}
								searchEnabled={true}
								visible={showSelectBox}
								disabled={disableFilters}
							/>
							<SelectBox
								placeholder='SEWS Part. No.'
								elementAttr={{
									id: "sewsPartNo"
								}}
								valueExpr='Value'
								displayExpr='Value'
								ref={sewsSelectBox}
								dataSource={sewsPartNoStore}
								onValueChanged={setReportParams}
								showClearButton={true}
								searchEnabled={true}
								width='180px'
								visible={!showSelectBox}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<INCOMPLETESHIPMENTREPORTGrid
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
