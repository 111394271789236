import React, {useState, useRef, useEffect} from "react";
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Pager,
	ColumnFixing,
	Toolbar,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export
} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import {exportDataGrid} from "devextreme/excel_exporter";
import {Workbook} from "exceljs";
import saveAs from "file-saver";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, firstTimeLoading}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	const [totalCount, setTotalCount] = useState(0);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let language = currentUser ? currentUser.data.userData.language : '';
	let formatDate = currentUser ? currentUser.data.userData.formatDate :'';
	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});
	useEffect(() => {
		window.addEventListener("cleanFileds", () => {
			setTotalCount(0);
		});
	}, []);
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;

		let dateFormatted;
		language === "ES"
			? (dateFormatted = `${day}-${month}-${year}`)
			: (dateFormatted = `${month}-${day}-${year}`);
		const buildTitle = `BoxHarnessReport_${dateFormatted}.xlsx`;

		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Report");

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 6, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"}
					};
				}
			}
		}).then((cellRange) => {
			/* Header */
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 1, 2, 8);

			headerRow.getCell(1).value = "Box/Harness Report";
			headerRow.getCell(1).font = {name: "Segoe UI Light", size: 20};
			headerRow.getCell(1).alignment = {horizontal: "center"};

			const detailsRow = worksheet.getRow(4);
			detailsRow.getCell(2).value = "Date: " + dateFormatted;
			detailsRow.getCell(3).value = "Time: " + fullTime;
			// Footer
			const footerRowIndex = cellRange.to.row + 2;
			const footerRow = worksheet.getRow(footerRowIndex);
			worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8);

			footerRow.getCell(1).value = "END OF REPORT";
			footerRow.getCell(1).font = {color: {argb: "BFBFBF"}, italic: true, size: 16};
			footerRow.getCell(1).alignment = {horizontal: "center"};

			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	return (
		<DataGrid
			elementAttr={{
				id: "InventoryGridContainer"
			}}
			ref={dataGrid}
			showBorders={true}
			dataSource={customDataSource}
			allowColumnResizing={true}
			columnResizingMode='nextColumn'
			columnAutoWidth={true}
			columnMinWidth={100}
			onExporting={onExporting}
			onContentReady={getTotalCount}>
			<Toolbar>
				<Item location='after'>
					<div className={"total-label"}>
						<span>Total Records: </span>
						<label>{totalCount}</label>
					</div>
				</Item>
				<Item location='after'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>{dataGridSettings.mode}</label>
					</div>
				</Item>
				<Item>
					<Button
						icon={IconFilter}
						className='dx-toolbar-item'
						hint='Clean Filters'
						onClick={clearFiltersDG}
					/>
				</Item>
				<Item name='exportButton' />
			</Toolbar>
			<ColumnFixing enabled={true} />
			<Selection mode='single' />
			<RemoteOperations groupPaging={true} />
			<Export enabled={true} />
			<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
			<Column dataField='id' caption='No.' visible={false} />
			<Column
				dataField='plantId'
				caption='Plant ID'
				alignment='center'
				cssClass='cell-bold'
			/>
			<Column dataField='boxSNO' caption='Box Sno' alignment='center' cssClass='cell-bold' />
			<Column
				dataField='harnessSNO'
				caption='Harness SNo'
				alignment='center'
				cssClass='cell-bold'
			/>
			<Column
				dataField='mfgDate'
				caption='Mfg Date'
				alignment='center'
				dataType='datetime'
				cssClass='cell-bold'
			/>
			<Column
				dataField='shippingDate'
				caption='Shipping Date'
				alignment='center'
				dataType='datetime'
			/>
			<Paging defaultPageSize={10} defaultPageIndex={0} />
			<Pager
				visible={true}
				allowedPageSizes={allowedPageSizes}
				displayMode='full'
				showPageSizeSelector={dataGridSettings.showPageSizeSelector}
				showInfo={dataGridSettings.showInfo}
				showNavigationButtons={dataGridSettings.showNavButtons}
			/>
			<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
		</DataGrid>
	);
};
