import React, {useMemo} from "react";
import {useHistory} from "react-router-dom";
import ContextMenu, {Position} from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import {Button} from "devextreme-react/button";
import {useAuth} from "../../contexts/auth";
import "./user-panel.scss";
import appInfo from "../../app-info";

export default function ({menuMode}) {
	const {user, signOut} = useAuth();
	const history = useHistory();

	let userFullName = user.data.userData
		? user.data.userData.employeeName.split(" ")
		: "";
	let serverName = user.data.userData.serverName;
	let firstName = userFullName[0];
	let groupCode = user.data.userData.groupCode;
	function navigateToProfile() {
		history.push("/profile");
	}
	function navigateToPreferences() {
		history.push("/serverStatus");
	}
	const adminOption = {
		text: "Server Configuration",
		icon: "preferences",
		onClick: navigateToPreferences,
	};

	let menuItems = [
		{
			text: "Profile",
			icon: "user",
			onClick: navigateToProfile,
		},
		{
			text: "Logout",
			icon: "runner",
			onClick: signOut,
		},
	];

	if (user && groupCode === "SUPER") {
		menuItems.unshift(adminOption);
	}
	return (
		<div className={"user-panel"}>
			<div className={"user-info"}>
				<Button className={"user-button"} icon="user" />
				<div className={"user-name"}>
					{firstName} | {appInfo.environment}
				</div>
				<br />
			</div>

			{menuMode === "context" && (
				<ContextMenu
					items={menuItems}
					target={".user-button"}
					showEvent={"dxclick"}
					cssClass={"user-menu, env-label"}>
					<Position my={"top center"} at={"bottom center"} />
				</ContextMenu>
			)}
			{menuMode === "list" && (
				<List className={"dx-toolbar-menu-action"} items={menuItems} />
			)}
		</div>
	);
}
