import React, {useState, useRef,useEffect} from "react";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import appInfo from "../../app-info";
import {getPermission} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import PrintShipmentReportGrid from "./PrintShipmentReportGrid";
import { SelectBox } from "devextreme-react";
import {Redirect} from "react-router-dom";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const asnSelectBox = useRef(null);
	const [disableExport, setDisableExport] = useState(true);
	const [customDataSource, setCustomDataSource] = useState(null);
	const permission = getPermission(user, "MISReports", "PrintShipment");

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let formatDate = currentUser ? currentUser.data.userData.formatDate : '';

	const [disableFilters, setDisableFilters] = useState(false);
	const [asnNumber,setAsnNumber]= useState();
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	
	const [showTootlTip, setShowTooltip] = useState(true);
	useEffect(() => {
		(async () => {
			
			let getPlants = `${appInfo.reportURL}PrintShipmentReport/ASNNumbers`;
			
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customASNnumber = result.data.map((plant) => {
						let plantDate= new Date(plant.DATE_TIME_STAMP);
						let displayDate;
						if(formatDate=== 'dd/MM/yyyy'){
							displayDate= `${plantDate.getDate()}/${plantDate.getMonth()}/${plantDate.getFullYear()}`;
						}else{
							displayDate= `${plantDate.getMonth()}/${plantDate.getDate()}/${plantDate.getFullYear()}`;

						}
						return {
							id: plant.Value,
							value: plant.Value + " - " + displayDate
							
						};
					});
					setAsnNumber(customASNnumber);
				})
				.catch((err) => {
					console.error(err, "Print shipment report fail getting active plant");
				});
		})();
	}, []);
	
	const sendReportRequest = () => {
		let asn = asnSelectBox.current.instance.option("value");
		
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}PrintShipmentReport?ASN=${asn}`
			})
		);
		setShowTooltip(false);
		setDisableFilters(true);
		setDisableExport(false);
		setfirstTimeLoading(false);
	};

	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		setDisableExport(true);
		setDisableFilters(false);
		setCustomDataSource(null);
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Print Shipment</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
						<SelectBox
								elementAttr={{
									id: "printShipmentSelect"
								}}
								placeholder='ASN No.'
								items={asnNumber}
								displayExpr='value'
								valueExpr='id'
								ref={asnSelectBox}
								width='200px'
								disabled={disableFilters}
							/>
							
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableFilters}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<PrintShipmentReportGrid
					showDGColumns={showDGColumns}
					customDataSource={customDataSource}
					token={token}
					firstTimeLoading={firstTimeLoading}
					disableExport={disableExport}
				/>
			</div>
		</React.Fragment>
	);
};
