import React, {useRef, useState, useCallback} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Toolbar,
	ColumnFixing,
	Item,
	Pager,
	Export,
	Scrolling,
	Sorting,
	RequiredRule,
	Lookup
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import saveAs from "file-saver";
import {alert, confirm} from "devextreme/ui/dialog";
import {Redirect} from "react-router-dom";
import appInfo from "../../app-info";
import IconFilter from "../../components/filter.svg";
import {useAuth} from "../../contexts/auth";

export default ({customDataSource, plantId, shortPartNo}) => {
	const [totalCount, setTotalCount] = useState(0);
	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	let finalMsg = "";
	let canceled;
	const {user} = useAuth();
	let token = user ? user.data.token : '';
	let language = user ? user.data.userData.language :'';
	let customParentSewsPart;
	const filterOperations = ["="];
	const permission = getPermission(user, "ConfigurationMasters", "DockMaster");
	let plantIDSrc = [{value: plantId}];
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard"
	});

	/*  Init configuration  */
	const getTotalCount = () => {
		let total = sessionStorage.getItem("totalCount");
		setTotalCount(total);
	};

	const onEditCanceled = (e) => {
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();
		let buildTitle;
		language === "ES"
			? (buildTitle = `Dock Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Dock Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], {type: "application/octet-stream"}), buildTitle);
			});
		});
		e.cancel = true;
	};
	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist.<br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};

	const onRowUpdating = (e) => {
		e.newData.plantid = plantId;
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowInserting = (e) => {
		e.data.plantid = plantId;
		e.data = e.data;
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = e.data;
		e.data.plantId = plantId;
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);
	const onEditorPreparing = (e) => {
		/******* INFO: Insert Parent Short Part No *****/
		if (e.dataField === "parentShortPartNo") {
			e.editorName = "dxSelectBox"; // Changes the editor's type
			Object.assign(e.editorOptions, {
				acceptCustomValue: true,
				onOpened: (args) => {
					args.customItem = new Promise((resolve, reject) => {
						const component = args.component;
						const dataSource = component.getDataSource();
						const dataStore = dataSource.store();
						if (dataSource.items().length == 0) {
							customParentSewsPart.map((sews) => {
								dataStore.insert(sews).then((r) => {
									dataSource.load();
									e.component.columnOption(e.dataField).lookup.update();
								});
								resolve(sews);
							});
						}
					});
				}
			});
		}

		/* ========= Disable cells =============== */
		if (e.dataField === "parentPlantId" && e.parentType === "dataRow" && !e.row.isNewRow) {
			e.editorOptions.disabled = true;
		}
		if (e.dataField === "parentShortPartNo" && e.parentType === "dataRow" && !e.row.isNewRow) {
			e.editorOptions.disabled = true;
		}
		if (e.dataField === "sewsShortPartNo" && e.parentType === "dataRow" && !e.row.isNewRow) {
			e.editorOptions.disabled = true;
		}
	};

	const getParentSewsPartNo = async (sewsPart, shortPart) => {
		sewsPart = sewsPart.trim();
		await fetch(
			`${appInfo.configURL}epbmaster/ParentSewsPartNumbers?sewsPartNo=${sewsPart}&plantId=${plantId}&shortPartNo=${shortPart}`,
			{
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			}
		)
			.then((response) => response.json())
			.then((result) => {
				customParentSewsPart = result;
			});
	};

	/*==== INFO: methods in order to auto-fill columns depending of Parent/Short Part No. selected ====*/
	const getSingleSourceSelected = (sspn) => {
		sspn = sspn.trim();
		return fetch(
			`${appInfo.configURL}epbmaster/ShortPartNumberById?plantid=${plantId}&sewsShortPartNo=${sspn}`,
			{headers: {Authorization: `Bearer ${token}`}}
		)
			.then((result) => result.json())
			.then((result) => {
				return result;
			});
	};

	//INFO: Short Part No - Fill Columns: SEWS Part NO, SEWS Design Level
	async function sourceSelected(newData, value) {
		let localSSPN = await getSingleSourceSelected(value);
		if (localSSPN !== "") {
			newData.sewsPartNo = localSSPN.sewsShortPartNo;
			newData.sewsDesignLevel = localSSPN.sewsDesignLevel;
			newData.parentSewsPartNo = localSSPN.parentSewsPartNo;

			getParentSewsPartNo(localSSPN.sewsShortPartNo, value);
			this.defaultSetCellValue(newData, value);
		} else {
			newData.sewsPartNo = null;
			newData.sewsDesignLevel = null;
			newData.parentSewsPartNo = null;
			this.defaultSetCellValue(newData, value);
		}
	}
	const getSingleParentSourceSelected = (pspn) => {
		pspn = pspn.trim();
		return fetch(
			`${appInfo.configURL}epbmaster/ParentSewsDesignLevel?plantId=${plantId}&parentShortPartNo=${pspn}`,
			{headers: {Authorization: `Bearer ${token}`}}
		)
			.then((result) => result.json())
			.then((result) => {
				return result;
			});
	};

	async function parentSourceSelected(newData, value) {
		let localSSPN = await getSingleParentSourceSelected(value);
		if (localSSPN !== "") {
			newData.parentSewsDesignLevel = localSSPN[0].value;
			this.defaultSetCellValue(newData, value);
		} else {
			newData.parentSewsDesignLevel = null;
			this.defaultSetCellValue(newData, value);
		}
	}

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "dockMasterMainDataGrid"
				}}
				ref={dataGrid}
				dataSource={customDataSource}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				showBorders={true}
				remoteOperations={true}
				onContentReady={getTotalCount}
				onEditorPreparing={onEditorPreparing}
				onExporting={onExporting}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFilters}
						/>
					</Item>
					<Item name='addRowButton' />
					<Item name='revertButton' />
					<Item name='saveButton' />
					<Item name='searchPanel' />
				</Toolbar>
				<Sorting mode='multiple' />
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<SearchPanel visible={true} width={240} placeholder='Search...' />
				<Export enabled={true} />
				<ColumnFixing enabled={true} />
				<Editing
					mode='batch'
					startEditAction={dataGridSettings.startEditAction}
					allowAdding={permission ? permission.create : false}
					allowUpdating={permission ? permission.update : false}
					allowDeleting={permission ? permission.delete : false}
					texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
					refreshMode='full'
				/>
				<Column
					dataField='id'
					caption='No'
					visible={false}
					allowEditing={false}
					allowFiltering={false}
				/>
				<Column
					dataField='sewsShortPartNo'
					caption='Short Part No.'
					allowEditing={true}
					setCellValue={sourceSelected}>
					<RequiredRule />
					<Lookup dataSource={shortPartNo} displayExpr='value' valueExpr='value' />
				</Column>
				<Column dataField='sewsPartNo' caption='SEWS Part No.' allowEditing={false} />
				<Column dataField='sewsDesignLevel' caption='SEWS Design Level' allowEditing={false} />
				<Column
					dataField='parentPlantId'
					caption='Parent Plant ID'
					calculateDisplayValue='parentPlantId'
					selectedFilterOperation={filterOperations}>
					<Lookup displayExpr='value' dataSource={plantIDSrc} valueExpr='value' />
					<RequiredRule />
				</Column>
				<Column
					dataField='parentShortPartNo'
					caption='Parent Short Part No'
					calculateDisplayValue='parentShortPartNo'
					setCellValue={parentSourceSelected}
					allowFiltering={false}>
					<Lookup displayExpr='value' valueExpr='value' />
					<RequiredRule />
				</Column>
				<Column dataField='parentSewsPartNo' caption='Parent SEWS Part No.' allowEditing={false} />
				<Column
					dataField='parentSewsDesignLevel'
					caption='Parent SEWS Design Level'
					allowEditing={false}
				/>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};
