import React, {useState} from "react";
import store from "../../components/hooks/store";
import {getPermission} from "../configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import LinkServerMasterGrid from "./LinkServerMasterGrid";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token: '';

	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"LinkServerMaster"
	);

	const [customDataSource, setCustomDataSource] = useState(
		store({
			url: `${appInfo.configURL}LinkServerMaster/`,
		})
	);

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Link Server Master</h2>
					</div>
				</div>
				<LinkServerMasterGrid customDataSource={customDataSource} />
			</div>
		</React.Fragment>
	);
};
