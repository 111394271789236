import React, {useState, useEffect} from "react";
import SelectBox from "devextreme-react/select-box";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import appInfo from "../../app-info";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import {Redirect} from "react-router-dom";
import OverShipmentTraceReportGrid from "./OverShipmentTraceReportGrid";
import {TextBox} from "devextreme-react";
import {alert} from "devextreme/ui/dialog";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	let token = user ? user.data.token : '';
	const permission = getPermission(user, "MISReports", "OverShipmentTraceReport");

	const [disableFilters, setDisableFilters] = useState(false);
	const [disableSearchBtn, setDisableSearchBtn] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [customDataSource, setCustomDataSource] = useState(null);

	const [years, setYears] = useState(null); //keep the years list
	const [months, setMonths] = useState(null); //keep the month list

	const [currentYear, setCurrentYear] = useState(null); // this one will set the value in the dropdown
	const [currentMonth, setCurrentMonth] = useState(null); // this one will set the value in the dropdown
	const [currentSEWS, setCurrentSEWS] = useState(null);
	const [currentCustGroup, setCurrentCustGroup] = useState(null);
	const [currentCustCode, setCurrentCustCode] = useState(null);
	const [currentWharehouse, setCurrentWharehouse] = useState(null);

	const [initialMonth, setInitialMonth] = useState(null); //this one will be used to reset the initial values
	const [initialYear, setInitialYear] = useState(null); //this one will be used to reset the initial values
	const [plants, setPlants] = useState(null);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [activePlantID, setActivePlantID] = useState();

	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		year: "",
		month: "",
		custCode: "",
		partNumber: "",
		wharehouse: "",
		custGroup: ""
	});
	const reportParamsArr = [
		"plantID",
		"year",
		"month",
		"partNumber",
		"custCode",
		"wharehouse",
		"custGroup"
	];

	useEffect(() => {
		(async () => {
			let getYear = `${appInfo.reportURL}OverShipmentTraceReport/Years`;
			let getMonths = `${appInfo.reportURL}OverShipmentTraceReport/Months`;
			let getPlants = `${appInfo.reportURL}OverShipmentTraceReport/Plants`;
			let getActivePlant = `${appInfo.reportURL}OverShipmentTraceReport/ActivePlant`;
			try {
				await fetch(getYear, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let yearsList = result.map((year) => {
							return year.YEAR;
						});
						let currYear = new Date().getFullYear();
						currYear = currYear.toString();
						let yearArrayPosition = yearsList.indexOf(currYear);
						setCurrentYear(yearsList[yearArrayPosition]);
						setInitialYear(yearsList[yearArrayPosition]);
						setYears(yearsList);
					});
			} catch (error) {
				console.error("Over Shipment Trace Report fail getting year list");
			}
			try {
				await fetch(getMonths, {
					method: "get",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then((response) => response.json())
					.then((result) => {
						let monthList = result.map((month) => {
							return month.MONTH;
						});
						let currMonth = new Date().getMonth();

						setCurrentMonth(monthList[currMonth]);
						setInitialMonth(monthList[currMonth]);
						setMonths(monthList);
					});
			} catch (error) {
				console.error("Over shipment trace report fail getting month list");
			}

			try {
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						setReportDetail((oldValues) => {
							return {...oldValues, plantID: result.Id};
						});
						setActivePlantID(result.Id);
						setPlantIDSelected(result.Id);
					});
			} catch (error) {
				console.error(error, " Over shipment trace report fail getting active plant");
			}
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.error(err, " Over shipment trace report fail getting active plant");
				});
		})();
	}, []);

	const onChangeYear = (e) => {
		setCurrentYear(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, year: e.value};
		});
	};
	const onChangeMonth = (e) => {
		setCurrentMonth(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, month: e.value};
		});
	};
	const onSEWSChange = (e) => {
		setCurrentSEWS(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, partNumber: e.value};
		});
	};
	const onCustGroupChange = (e) => {
		setCurrentCustGroup(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, custGroup: e.value};
		});
	};
	const onCustCodeChange = (e) => {
		setCurrentCustCode(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, custCode: e.value};
		});
	};
	const onWhareHouseChange = (e) => {
		setCurrentWharehouse(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, wharehouse: e.value};
		});
	};
	const setReportParams = () => {
		if (reportDetail.year === null || reportDetail.month === null) {
			alert("Year/Month should be required");
		} else {
			const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
			let params = "?";
			reportParamsArr.forEach(function (i) {
				if (i in reportDetail && isNotEmpty(reportDetail[i])) {
					params += `${i}=${JSON.stringify(reportDetail[i])}&`;
				}
			});
			params = params.replace(/"/g, "");
			params = params.slice(0, -1);
			setCustomDataSource(
				store({
					url: `${appInfo.reportURL}OverShipmentTraceReport${params}`
				})
			);
			setDisableFilters(true);
			setDisableSearchBtn(true);
			setfirstTimeLoading(false);
		}
	};
	const onValueChanged = (e) => {
		setPlantIDSelected(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	const clearFilters = () => {
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		sessionStorage.setItem("totalCount", 0);
		setDisableFilters(false);
		setDisableSearchBtn(false);
		setCustomDataSource(null);
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Over Shipment Trace Report</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "overShipmentPlantSelect"
								}}
								placeholder='Plant Code - Plant Name'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={plantIDSelected}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Month'
								elementAttr={{
									id: "overShipmentMonth"
								}}
								disabled={disableFilters}
								searchEnabled={true}
								items={months}
								onValueChanged={onChangeMonth}
								value={currentMonth}
								width='100px'
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Year'
								elementAttr={{
									id: "overShipmentYear"
								}}
								disabled={disableFilters}
								searchEnabled={true}
								items={years}
								value={currentYear}
								onValueChanged={onChangeYear}
								width='100px'
							/>
						</div>

						<div className='dx-field'>
							<TextBox
								elementAttr={{
									id: "overShipmentSews"
								}}
								placeholder='SEWS Part No.'
								maxLength='18'
								value={currentSEWS}
								onValueChanged={onSEWSChange}
								disabled={disableFilters}
								width='180px'
							/>
						</div>
						<div className='dx-field'>
							<TextBox
								elementAttr={{
									id: "overShipmentCustGroup"
								}}
								placeholder='Cust. Group'
								maxLength='18'
								value={currentCustGroup}
								onValueChanged={onCustGroupChange}
								disabled={disableFilters}
								width='185px'
							/>
						</div>
						<div className='dx-field'>
							<TextBox
								elementAttr={{
									id: "overShipmentCustCode"
								}}
								placeholder='Customer Code'
								maxLength='18'
								value={currentCustCode}
								onValueChanged={onCustCodeChange}
								disabled={disableFilters}
								width='185px'
							/>
						</div>
						<div className='dx-field'>
							<TextBox
								elementAttr={{
									id: "overShipmentwareHouse"
								}}
								placeholder='Wharehouse'
								maxLength='18'
								width='185px'
								value={currentWharehouse}
								onValueChanged={onWhareHouseChange}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "findButton"
								}}
								disabled={disableSearchBtn}
								onClick={setReportParams}
							/>
							<Tooltip target='#findButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button
								icon='clear'
								elementAttr={{
									id: "cancelButton"
								}}
								onClick={clearFilters}
							/>
						</div>
					</div>
				</form>
				<OverShipmentTraceReportGrid
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
