function getIcon(module) {
	switch (module) {
		case "Home":
			return "home";
		case "ConfigurationMasters":
			return "filter";
		case "DemandPrinting":
			return "print";
		case "ImportExport":
			return "export";
		case "MISReports":
			return "columnchooser";
		case "ShippingPlan":
			return "verticalaligntop";
		case "ReworkReinspection":
			return "help";
		case "DOI":
			return "help";
		default:
			break;
	}
}

function buildMenuTree(result) {
	sessionStorage.setItem("currentUser", JSON.stringify(result));
	let user = JSON.parse(sessionStorage.getItem("currentUser"));
	const permissionTree = user.data.permissionTree;

	
	sessionStorage.setItem("currentUser", JSON.stringify(user));
	if (permissionTree) {
		let navigationItem = permissionTree.map((item) => {
			if (item.treeMenuChild) {
				return {
					text: item.prograM_DES,
					path: item.prograM_DES_CLEAN,
					icon: getIcon(item.prograM_DES_CLEAN),

					items: item.treeMenuChild
						.filter((subitem) => subitem.procesS_FLG == "Y")
						.map((subitem) => {
							return {
								text: subitem.prograM_DES,
								path: `/${subitem.prograM_DES_CLEAN}`
							};
						})
				};
			} else {
				if (item.prograM_DES_CLEAN === "Home") {
					return {
						text: item.prograM_DES,
						path: "/Home",
						icon: getIcon(item.prograM_DES_CLEAN)
					};
				}
				return {
					text: item.prograM_DES,
					path: item.prograM_DES_CLEAN,
					icon: getIcon(item.prograM_DES_CLEAN)
				};
			}
		});
		sessionStorage.setItem("navigationItem", JSON.stringify(navigationItem));
	}
}

export {buildMenuTree};
