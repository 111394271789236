import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomStore from "devextreme/data/custom_store";
import SelectBox from "devextreme-react/select-box";
import appInfo from "../../../app-info";
import { useAuth } from "../../../contexts/auth";
import DataSource from "devextreme/data/data_source";
import { handleErrors } from "../../configuration/helpers/globalFunctions";

export default({radioOptionsSelected, callbackDropdownNumber, disableFields})=>{
    const {user} = useAuth();
	const selectBox = useRef();
    const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	//const [selectedNumber,setSelectedNumber]= useState();
	let selectedNumber={};
const store = new CustomStore({
		key: "Id",
		load: async(loadOptions) => {
			
			let asnUrl = `${appInfo.reportURL}ArchiveSummaryReport/ASNNumbers?`;
			let masterLabelUrl = `${appInfo.reportURL}ArchiveSummaryReport/MLNumbers?`;
			let palletUrl = `${appInfo.reportURL}ArchiveSummaryReport/PLTNumbers?`;
			let boxUrl = `${appInfo.reportURL}ArchiveSummaryReport/BOXNumbers?`;
			let harnessUrl = `${appInfo.reportURL}ArchiveSummaryReport/HRNSNumbers?`;
			let url = "";
			switch (radioOptionsSelected) {
				case "ASN":
					url = asnUrl;
					break;

				case "Master Label":
					url = masterLabelUrl;
					break;

				case "Pallet":
					url = palletUrl;
					break;

				case "Box":
					url = boxUrl;
					break;

				case "Harness":
					url = harnessUrl;
					break;

				default:
					break;
			}
			if(loadOptions.searchValue){
				loadOptions.asnNumber=loadOptions.searchValue;
			}
			let params = "&";
			[
				"searchValue",
				"asnNumber",
				"skip",
				"take",
			].forEach(function (i) {
				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);

			let newUrl = url + params;
			let fullUrl = newUrl.replace(/"/gi, "");
			return fetch(fullUrl, {
				method: "get",
				headers: {Authorization: `Bearer ${user.data.token}`}
			})
				.then((response)=> handleErrors(response))
				.then((response) => response.json())
				.then((response) => {
					return response.data;
				});
		},
		byKey:(key)=>{
			const selectedItem = {id:1, value:key};
			return selectedItem;
		}
});
const ds= useMemo(()=>
		new DataSource({
			store: store,
		}))
const onValueChanged = (e) => {
	if(e.selectedItem){
		callbackDropdownNumber(e.selectedItem.value);
	}else{
		
		callbackDropdownNumber("");
	}
	selectedNumber=e.selectedItem;
};
useEffect(() => {
	window.addEventListener("cleanFileds", () => {
		selectBox.current.instance.reset();
	});
	return () => {
		ds.dispose();
	  };
}, []);
    return(
        <SelectBox
		dataSource={ds}
		elementAttr={{
		id: "shipToWhse"
		}}
		ref={selectBox}
		valueExpr='value'
		displayExpr='value'
		searchEnabled={true}
        disabled={disableFields}
		showClearButton={true}
        onSelectionChanged={onValueChanged}
		/>
    )
}