import env from 'react-dotenv';
let environment = env.REACT_APP_ENVIRONMENT,
  build = env.REACT_APP_BUILD_ID;

export default {
  title: 'Finished Goods Shipping Systems',
  environment: environment,
  build: build,
  configURL: env.REACT_APP_CONFIGURATION_URL,
  demandURL: env.REACT_APP_DEMAND_PRINTING_URL,
  authURL: env.REACT_APP_AUTHENTICATION_URL,
  reportURL: env.REACT_APP_INVENTORY_REPORT_URL,
  shippingURL: env.REACT_APP_SHIPPING_PLAN_URL,
  doiURL: env.REACT_APP_DOI_URL,
  reworkURL: env.REACT_APP_REWORK_URL,
  importURL: env.REACT_APP_IMPORT_URL
};
