import React, {useState, useRef, useCallback, useEffect} from "react";
import DataGrid, {
	Column,
	Paging,
	Editing,
	Pager,
	FilterRow,
	ColumnFixing,
	Toolbar,
	Item,
	RemoteOperations,
	RequiredRule,
	Scrolling,
	LoadPanel,
	Lookup,
	PatternRule
} from "devextreme-react/data-grid";
import {Button} from "devextreme-react/button";
import appInfo from "../../app-info";
import FileUploader from "devextreme-react/file-uploader";
import {getPermission, getMode, setMode} from "../configuration/helpers/globalFunctions";
import {alert, confirm} from "devextreme/ui/dialog";
import IconFilter from "../../components/filter.svg";
import axios from "axios";
import {saveAs} from "file-saver";

export default ({
	customDataSource,
	customerGroups,
	customerCodes,
	plantId,
	showDGColumns,
	option,
	year,
	month,
	firstTimeLoading,
	disableAddBtn
}) => {
	const date = new Date();
	const [printDate, setPrintDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
	const [totalCount, setTotalCount] = useState(0);
	const [loadPanelVisible, setLoadPanelVisible] = useState(false);
	const allowedPageSizes = [10, 30, 50, "all"];
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard"
	});
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;
	let language = currentUser.data.userData.language;
	let formatDate = currentUser.data.userData.formatDate;
	let canceled = false;
	const permission = getPermission(currentUser, "ShippingPlan", "ShipmentPlanMaintenanceMaster");
	let finalMsg = "";
	const fileUpload = useRef(null);
	const dataGrid = useRef(null);
	useEffect(() => {
		window.addEventListener("resetFileUploader", () => {
			fileUpload.current.instance.reset();
		});
	}, []);
	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};
	const onEditorPreparing = (e) => {
		if (e.parentType === "dataRow" && e.row.isNewRow === true) {
		}
		if (e.dataField === "plantId" && e.parentType === "dataRow") {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
		if (
			(e.dataField === "sewsPartNo" ||
				e.dataField === "designChange" ||
				e.dataField === "lineCode" ||
				e.dataField === "customerCode" ||
				e.dataField === "custGroup" ||
				e.dataField === "prodDate" ||
				e.dataField === "shipDate" ||
				e.dataField === "shipToWhse" ||
				e.dataField === "begInvent") &&
			e.parentType === "dataRow"
		) {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
	};
	const onRowUpdating = (e) => {
		if (e.newData.shipQty === null) {
			e.newData.shipQty = 0;
		}
		if (e.newData.prodQty === null) {
			e.newData.prodQty = 0;
		}
		e.newData.option = option;
		e.newData = [{...e.oldData, ...e.newData}];
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const onEditCanceled = (e) => {
		setMode("View");
	};
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);
	const onRowInserting = (e) => {
		e.data.option = option;
		if (!e.data.begInvent || e.data.begInvent === null) {
			e.data.begInvent = 0;
		}
		if (!e.data.shipQty || e.data.shipQty === null) {
			e.data.shipQty = 0;
		}
		if (!e.data.prodQty || e.data.prodQty === null) {
			e.data.prodQty = 0;
		}
		if (e.data.prodQty) {
			let prodQ = parseInt(e.data.prodQty);
			e.data.prodQty = prodQ;
		}
		if (e.data.begInvent) {
			let bInven = parseInt(e.data.begInvent);
			e.data.begInvent = bInven;
		}
		if (e.data.shipQty) {
			let sQty = parseInt(e.data.shipQty);
			e.data.shipQty = sQty;
		}
		if (!e.data.designChange || e.data.designChange === null) {
			e.data.designChange = "";
		}
		e.data = [e.data];
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		e.data.Option = option;
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const customizeText = (cellInfo) => {
		let localValue = cellInfo.value;
		if (localValue) {
			localValue = localValue.toUpperCase();
			return localValue;
		}
	};
	async function fillColumns(newData, value) {
		newData.plantId = plantId;
		newData.sewsPartNo = value;
		this.defaultSetCellValue(newData, value);
	}
	const deleteAll = async (e) => {
		const dialogResult = await confirm("Are you sure you want to delete all?");
		if (dialogResult) {
			setLoadPanelVisible(true);
			await fetch(
				`${appInfo.shippingURL}ShipmentPlanMaintenanceMaster/DeleteAll?option=${option}&plantId=${plantId}&year=${year}&month=${month}`,
				{
					method: "delete",
					headers: {Authorization: `Bearer ${token}`}
				}
			)
				.then((res) => res.json())
				.then((res) => {
					let responseMsg = res.message;
					if (responseMsg !== "") {
						responseMsg = responseMsg.replace(/,/g, "<br/>");
						setLoadPanelVisible(false);
					}
					if (responseMsg) {
						dataGrid.current.instance.refresh();
						alert(responseMsg);
						setLoadPanelVisible(false);
					}
				})
				.catch((err) => {
					console.warn(err);
					setLoadPanelVisible(false);
				});
		}
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
	};
	const downloadTemplate = async () => {
		let url = `${appInfo.shippingURL}ShipmentplanMaintenanceMaster/GetTemplate?type=${option}`;
		let fileName = `Import ${option} Shipment Plan Maintenance Template.xlsx`;
		return await axios
			.get(url, {
				responseType: "blob",
				headers: {
					Authorization: `Bearer ${token}`,
					"Accept-Language": `${language}`,
					"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				},
				url: url
			})
			.then((response) => response.data)
			.then((buffer) => {
				if (buffer.size > 0) {
					return saveAs(new Blob([buffer], {type: "application/octet-stream"}), fileName);
				} else {
					return alert("[20024]No records found", "warning", 2000);
				}
			});
	};

	return (
		<React.Fragment>
			<div className="templateContainer"> 
				<div className="fileUploader">
				<FileUploader
					width='auto'
					showFileList={true}
					ref={fileUpload}
					elementAttr={{id:'FileUploaderShipment'}}
					allowedFileExtensions={[".xls", ".xlsx"]}
					uploadMode='instantly'
					multiple={false} 
					uploadHeaders={{Authorization: `Bearer ${token}`}}
					uploadUrl={
						appInfo.shippingURL + "ShipmentPlanMaintenanceMaster/Import?option=" + option
					}
					onUploaded={function (e) {
						let finalMsg = JSON.parse(e.request.responseText).message.replace(/,/g, "<br/>");
								if (finalMsg !== "") {
									dataGrid.current.instance.refresh();
									alert(finalMsg);
								}
						}}
					onUploadError={function (e) {
						const titleError = 'Error Detail - Shipment/Production Plan Maintenance Master';
						let errorMsg = JSON.parse(e.request.responseText).message.replace(/,/g, "\n");
						saveAs(new Blob([errorMsg], { type: "text/plain" }), titleError);
						if (errorMsg !== "") {
							alert('Importing process has failed.<br/> Error Detail file has been downloaded for more details.');
						}
					}}
				/>
			</div>
			<div>
				<Button
					icon='download'
					text={" Download " + option + "  Template"}
					onClick={downloadTemplate}
					className="downloadTemplateBtn"
				/>
				</div>
			</div>
			<DataGrid
				elementAttr={{
					id: "InventoryGridContainer"
				}}
				dataSource={customDataSource}
				ref={dataGrid}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode='nextColumn'
				columnAutoWidth={true}
				columnMinWidth={100}
				onContentReady={getTotalCount}
				onEditorPreparing={onEditorPreparing}
				onOptionChanged={onOptionChanged}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}>
				<FilterRow visible={true} applyFilter={'auto'}/>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFiltersDG}
						/>
					</Item>
					<Item name='addRowButton' disabled={disableAddBtn} />
					<Item name='revertButton' />
					<Item name='saveButton' />
					<Item>
						<Button icon='trash' onClick={deleteAll} hint='Delete All' />
					</Item>
				</Toolbar>
				<LoadPanel enabled={true} visible={loadPanelVisible} />
				<Editing
					mode='batch'
					startEditAction={dataGridSettings.startEditAction}
					allowUpdating={permission.update}
					allowAdding={permission.create}
					allowDeleting={permission.delete}
					texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
					refreshMode='full'
				/>
				<RemoteOperations groupPaging={true} />
				<ColumnFixing enabled={true} />
				<Column
					dataField='plantId'
					caption='Plant ID'
					cssClass='cell-bold'
					allowEditing={false}
				/>
				<Column
					dataField='sewsPartNo'
					caption='SEWS Part No.'
					cssClass='cell-bold'
					customizeText={customizeText}
					setCellValue={fillColumns}
					editorOptions={{maxLength: 40}}>
					<RequiredRule />
				</Column>
				<Column
					dataField='designChange'
					caption='Design Change'
					customizeText={customizeText}
					cssClass='cell-bold'
					editorOptions={{maxLength: 40}}
					allowEditing={true}
				/>
				<Column
					dataField='lineCode'
					caption='Line Code'
					cssClass='cell-bold'
					customizeText={customizeText}
					editorOptions={{maxLength: 25}}>
					<RequiredRule />
				</Column>
				<Column dataField='custGroup' caption='Cust. Group' cssClass='cell-bold'>
					<Lookup dataSource={customerGroups} />
					<RequiredRule />
				</Column>
				<Column dataField='customerCode' caption='Customer Code' cssClass='cell-bold'>
					<Lookup dataSource={customerCodes} />
					<RequiredRule />
				</Column>
				<Column
					dataField='prodDate'
					caption='Prod. Date'
					cssClass='cell-bold'
					visible={showDGColumns}
					dataType='date'
					format={formatDate}
					editorOptions={{
						dateSerializationFormat: "yyyy-MM-dd",
						min: printDate
					}}>
					{showDGColumns ? <RequiredRule /> : null}
				</Column>
				<Column
					dataField='shipDate'
					caption='Ship Date'
					cssClass='cell-bold'
					visible={!showDGColumns}
					dataType='date'
					format={formatDate}
					editorOptions={{
					 min: printDate
					}}>
					{!showDGColumns ? <RequiredRule /> : null}
				</Column>
				<Column
					dataField='shipToWhse'
					caption='Ship To Whse'
					customizeText={customizeText}
					cssClass='cell-bold'
					editorOptions={{maxLength: 5}}>
					<RequiredRule />
				</Column>
				<Column
					dataField='begInvent'
					caption='Beg. Invt'
					cssClass='cell-bold'
					visible={!showDGColumns}
					editorOptions={{maxLength: 5}}>
					<PatternRule
						pattern={
							/\b([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9])\b/
						}
						message={"Beg. Invt should be from 0 to 99999"}
					/>
				</Column>
				<Column
					dataField='shipQty'
					caption='Ship Qty'
					visible={!showDGColumns}
					allowEditing={true}
					editorOptions={{maxLength: 5}}>
					<PatternRule
						pattern={
							/\b([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9])\b/
						}
						message={"Ship Qty should be from 0 to 99999"}
					/>
				</Column>
				<Column
					dataField='prodQty'
					caption='Prod. Qty'
					visible={showDGColumns}
					allowEditing={true}
					editorOptions={{maxLength: 5}}>
					<PatternRule
						pattern={
							/\b([0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9])\b/
						}
						message={"Prod Qty should be from 0 to 99999"}
					/>
				</Column>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};
