import React, {useRef, useState, useCallback, useEffect} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Export,
	ColumnFixing,
	Toolbar,
	Pager,
	Scrolling,
	Sorting,
	RequiredRule,
	Lookup,
} from "devextreme-react/data-grid";
import {confirm, alert} from "devextreme/ui/dialog";
import appInfo from "../../app-info";
import Button from "devextreme-react/button";
import {Redirect} from "react-router-dom";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import store from "../../components/hooks/store";
import saveAs from "file-saver";
import IconFilter from "../../components/filter.svg";
import Box, {Item} from "devextreme-react/box";

export default ({harnessLabel, barcodesType, tracibilityFlags}) => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let language = currentUser ? currentUser.data.userData.language : '';
	let canceled;
	let finalMsg = "";
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"CustomerHrnLblFormatMst"
	);

	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	const [totalCount, setTotalCount] = useState(0);
	const [custGroups, setCustGroups] = useState(null);
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		columnResizingMode: "nextColumn",
		infiniteScroll: "standard",
	});
	const [customDataSource, setCustomDataSource] = useState(
		store({
			url: `${appInfo.configURL}CustomerHarnessLabelFormatMaster`,
		})
	);
	useEffect(() => {
		(async () => {
			let getCustGroups = `${appInfo.configURL}CustomerHarnessLabelFormatMaster/CustGroups`;
			try {
				await fetch(getCustGroups, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((result) => result.json())
					.then((result) => {
						setCustGroups(result.data);
					});
			} catch (error) {
				console.error("Harness Label Format Master fail get cust groups");
			}
		})();
	}, []);
	const onEditCanceled = (e) => {
		setMode("View");
	};
	const onEditCanceling = useCallback((e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			).then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	}, []);
	const onEditorPreparing = (e) => {
		if (e.dataField == "custGroup") {
			e.editorName = "dxSelectBox"; // Changes the editor's type
			Object.assign(e.editorOptions, {
				acceptCustomValue: true,

				onCustomItemCreating: (args) => {
					let groupValue = args.text.toUpperCase();
					const newItem = {
						value: groupValue,
					};
					args.customItem = new Promise((resolve, reject) => {
						const component = args.component;
						const dataSource = component.getDataSource();
						const dataStore = dataSource.store();
						dataStore.insert(newItem).then((r) => {
							dataSource.load();
							e.component.columnOption(e.dataField).lookup.update();
							resolve(newItem);
						});
					});
				},
			});
		}

		if (e.dataField === "harnessLabelFormat" && e.parentType === "dataRow") {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
	};
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const onExporting = (e) => {
		/* Get today Date First */
		let today = new Date();
		let month = today.getMonth() + 1; //months from 1-12
		let day = today.getDate();
		let year = today.getUTCFullYear();

		let hours = today.getHours();
		let minutes = today.getMinutes();
		let ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let fullTime = hours + ":" + minutes + " " + ampm;
		let buildTitle;

		language === "ES"
			? (buildTitle = `Customer Harness Format Master ${day}-${month}-${year}.xlsx`)
			: (buildTitle = `Customer Harness Format Master ${month}-${day}-${year}.xlsx`);
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet(buildTitle);

		exportDataGrid({
			component: e.component,
			worksheet: worksheet,
			topLeftCell: {row: 1, column: 1},
			customizeCell: ({gridCell, excelCell}) => {
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "e8e9eb"},
					};
				}
			},
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], {type: "application/octet-stream"}),
					buildTitle
				);
			});
		});
		e.cancel = true;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onRowInserting = (e) => {
		e.data = e.data;
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowUpdating = (e) => {
		e.newData = {...e.oldData, ...e.newData};
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = e.data;
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const customizeText = (cellInfo) => {
		let localValue = cellInfo.value;
		if (localValue) {
			localValue = localValue.toUpperCase();
			return localValue;
		}
	};
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "customerHrnLabelFormatContainer",
				}}
				dataSource={customDataSource}
				ref={dataGrid}
				remoteOperations={true}
				showBorders={true}
				allowColumnResizing={true}
				columnAutoWidth={true}
				columnResizingMode="nextColumn"
				columnMinWidth={100}
				onContentReady={getTotalCount}
				onOptionChanged={onOptionChanged}
				onEditorPreparing={onEditorPreparing}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onExporting={onExporting}
				onSaving={onSaving}
				onSaved={onSaved}>
				<Export enabled={true} />
				<Toolbar>
					<Item location="after">
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location="after">
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id="modeLabel">View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className="dx-toolbar-item"
							hint="Clean Filters"
							onClick={clearFilters}
						/>
					</Item>
					<Item name="addRowButton" />
					<Item name="revertButton" />
					<Item name="saveButton" />
					<Item name="exportButton" />
					<Item name="searchPanel" />
				</Toolbar>
				<ColumnFixing enabled={true} />
				<Sorting mode="multiple" />
				<SearchPanel visible={true} width={240} placeholder="Search..." />
				<FilterRow visible={true} />
				<Editing
					mode="batch"
					allowUpdating={permission ? permission.update : false}
					allowAdding={permission ? permission.create : false}
					allowDeleting={permission ? permission.delete : false}
					selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
					startEditAction={dataGridSettings.startEditAction}
					texts={{saveAllChanges: "", cancelAllChanges: ""}}
				/>
				<Column
					dataField="id"
					caption="No"
					allowEditing={false}
					visible={false}
					cssClass="cell-bold"
					allowFiltering={false}
				/>
				<Column
					dataField="harnessLabelFormat"
					caption="Label Format"
					cssClass="cell-bold"
					calculateDisplayValue="harnessLabelFormat">
					<Lookup
						dataSource={harnessLabel}
						displayExpr="value"
						valueExpr="value"
					/>
					<RequiredRule />
				</Column>
				<Column
					dataField="labelId"
					caption="Lbl Id"
					customizeText={customizeText}
					cssClass="cell-bold"
					editorOptions={{maxLength: 50}}>
					<RequiredRule />
				</Column>
				<Column
					dataField="custGroup"
					caption="CustGroup"
					editorOptions={{maxLength: 50}}>
					<Lookup
						dataSource={custGroups}
						displayExpr="value"
						valueExpr="value"
					/>
				</Column>
				<Column
					dataField="barCodeType"
					caption="BarcodeType"
					calculateDisplayValue="barCodeType">
					<Lookup
						dataSource={barcodesType}
						displayExpr="value"
						valueExpr="value"
					/>
				</Column>
				<Column dataField="tracebilityFlag" caption="Harness Tracebilty Flag">
					<Lookup
						dataSource={tracibilityFlags}
						displayExpr="value"
						valueExpr="value"
					/>
				</Column>
				<Paging defaultPageSize={10} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode="full"
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling
					mode={dataGridSettings.infiniteScroll}
					rowRenderingMode="virtual"
				/>
			</DataGrid>
		</React.Fragment>
	);
};
