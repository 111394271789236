import React, {useState, useRef} from "react";
import {Redirect} from "react-router-dom";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	Pager,
	Toolbar,
	ColumnFixing,
	Item,
	RemoteOperations,
	Scrolling,
	Selection,
	Export
} from "devextreme-react/data-grid";
import {getPermission, setMode, getMode} from "../configuration/helpers/globalFunctions";
import appInfo from "../../app-info";
import {Button} from "devextreme-react/button";
import {confirm, alert} from "devextreme/ui/dialog";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, firstTimeLoading, params}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	const dataGrid = useRef(null);
	const [totalCount, setTotalCount] = useState(0);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;

	const permission = getPermission(currentUser, "ReworkReinspection", "NoboxRelabeling");
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		mode: "View",
		infiniteScroll: "standard"
	});

	const getTotalCount = () => {
		//this goes in onContentReady
		if (!firstTimeLoading) {
			let total = sessionStorage.getItem("totalCount");
			setTotalCount(total);
		} else {
			setTotalCount(0);
		}
	};

	const updateAction = async () => {
		let dataGridRow = dataGrid.current.instance.getDataSource().items()[0];
		let bodyParams = {
			id: dataGridRow.id.toString(),
			PlantId: params.plantId,
			boxLabelOld: params.boxLabelOld,
			boxLabelNew: params.boxLabelNew
		};
		let dialogResult = await confirm("[20134] Do you want to save?");
		if (dialogResult) {
			await fetch(`${appInfo.reworkURL}DeleteReInspectedBox`, {
				method: "put",
				body: JSON.stringify(bodyParams),
				headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json"}
			})
				.then((result) => result.json())
				.then((result) => {
					let finalMsg = result.message.replace(/,/g, "<br/>");
					alert(finalMsg);
					dataGrid.current.instance.refresh();
				});
		}
	};

	const clearFiltersDG = () => {
		dataGrid.current.instance.clearFilter();
	};
	if (!permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<DataGrid
			elementAttr={{
				id: "InventoryGridContainer"
			}}
			dataSource={customDataSource}
			ref={dataGrid}
			showBorders={true}
			allowColumnResizing={true}
			columnResizingMode='nextColumn'
			columnAutoWidth={true}
			columnMinWidth={100}
			onContentReady={getTotalCount}>
			<Toolbar>
				<Item location='after'>
					<div className={"total-label"}>
						<span>Total Records: </span>
						<label>{totalCount}</label>
					</div>
				</Item>
				<Item location='after'>
					<div className={"total-label"}>
						<span> Mode: </span>
						<label id='modeLabel'>{dataGridSettings.mode}</label>
					</div>
				</Item>
				<Item>
					<Button
						icon={IconFilter}
						className='dx-toolbar-item'
						hint='Clean Filters'
						onClick={clearFiltersDG}
					/>
				</Item>
				<Item>
					<Button
						icon='save'
						className='dx-toolbar-item'
						hint='Save'
						onClick={updateAction}
						disabled={firstTimeLoading}
					/>
				</Item>
			</Toolbar>
			<Selection mode='single' />
			<RemoteOperations groupPaging={true} />
			<Export enabled={true} />
			<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
			<Editing
				mode='batch'
				startEditAction={dataGridSettings.startEditAction}
				texts={{saveAllChanges: "", cancelAllChanges: "", addRow: ""}}
				refreshMode='full'
			/>
			<Column dataField='id' caption='No.' visible={false} />
			<Column
				dataField='oldHarnessSno'
				caption='Old Harness Sno'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='oldSewsPartNo'
				caption='Old SEWS Part No'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='oldSewsDesign'
				caption='Old SEWS Design'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='newHarnessSno'
				caption='New Harness Sno'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='newSewsPartNo'
				caption='New SEWS Part No'
				alignment='center'
				allowEditing={false}
			/>
			<Column
				dataField='newSewsDesign'
				caption='New SEWS Design'
				alignment='center'
				allowEditing={false}
			/>
			<Paging defaultPageSize={10} defaultPageIndex={0} />
			<Pager
				visible={true}
				allowedPageSizes={allowedPageSizes}
				displayMode='full'
				showPageSizeSelector={dataGridSettings.showPageSizeSelector}
				showInfo={dataGridSettings.showInfo}
				showNavigationButtons={dataGridSettings.showNavButtons}
			/>
			<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
		</DataGrid>
	);
};
