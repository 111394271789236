import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import EPBMasterGrid from "./EPBMasterGrid";
import CustomStore from "devextreme/data/custom_store";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	const permission = getPermission(currentUser, "ConfigurationMasters", "DockMaster");

	const [plants, setPlants] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("0");
	const [customDataSource, setCustomDataSource] = useState(null);
	const [shortPartNo, setShortPartNo] = useState(null);
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";

	useEffect(() => {
		let getPlants = `${appInfo.configURL}epbmaster/Plants`;
		let getActivePlant = `${appInfo.configURL}epbmaster/ActivePlant`;
		(async () => {
			try {
				await fetch(getPlants, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customPlant = result.map((plant) => {
							return {
								id: plant.Id,
								value: plant.Id + " - " + plant.Value
							};
						});
						setPlants(customPlant);
					});
			} catch (error) {
				console.error("Fail in request getting plants");
			}
			try {
				await axios
					.get(getActivePlant, {headers: {Authorization: `Bearer ${token}`}})
					.then((result) => {
						let activePlant = result.data;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
						sessionStorage.setItem("currentPlant", activePlant);
					});
				/*
					await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((result) => result.json())
					.then((result) => {
						console.log(result);
						let activePlant = result.data;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
						sessionStorage.setItem("currentPlant", activePlant);
					}); */
			} catch (error) {
				console.error("Getting active plant failed request:", error);
			}
		})();
	}, []);

	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}epbmaster?plantid=${e.value}`
			})
		);
		setShortPartNo(shortPartNumLookup(e.value));
		sessionStorage.setItem("currentPlant", e.value);
	};
	function shortPartNumLookup(plantId) {
		return {
			paginate: true,
			pageSize: 5,
			store: new CustomStore({
				key: "id",
				load: (loadOptions) => {
					let params = "&";
					[
						"filter",
						"requireTotalCount",
						"searchValue",
						"searchExpr",
						"select",
						"skip",
						"take"
					].forEach(function (i) {
						if (i in loadOptions && isNotEmpty(loadOptions[i])) {
							params += `${i}=${JSON.stringify(loadOptions[i])}&`;
						}
					});
					params = params.slice(0, -1);
					return axios
						.get(`${appInfo.configURL}epbmaster/ShortPartNumbers?plantId=${plantId}${params}`, {
							headers: {Authorization: `Bearer ${token}`}
						})
						.then((result) => {
							return result.data;
						});
				},
				byKey: (key) => {
					//Do Not Delete By Key method. Deve Express need it not sure for what
				},
				insert: (value) => {
					return new Promise(function (resolve, reject) {
						resolve(value);
					});
				},
				errorHandler: (error) => {
					console.warn(error.message);
				}
			})
		};
	}

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>EPB Master</h2>
					</div>
				</div>
				<div className='header-right-side'>
					<SelectBox
						elementAttr={{
							id: "DockMasterSelector"
						}}
						placeholder='Plant Code'
						items={plants}
						displayExpr='value'
						valueExpr='id'
						onValueChanged={onValueChanged}
						value={currentPlant}
					/>
				</div>
				<EPBMasterGrid
					customDataSource={customDataSource}
					plantId={currentPlant}
					shortPartNo={shortPartNo}
				/>
			</div>
		</React.Fragment>
	);
};
