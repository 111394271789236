import React from 'react';

function gridCellData(gridData) {
  return gridData.data.remaining;
}

export default function RemainingColumn(cellData) {
  return (
    <div className={gridCellData(cellData) < 0 ? 'neg': gridCellData(cellData) ===0?''  : 'pos'}>
      <div className="diff">{gridCellData(cellData)}</div>
    </div>
  );
}
