import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission, handleErrors} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import PhysicalInventoryReportGrid from "./PhysicalInventoryReportGrid";
import {useAuth} from "../../contexts/auth";

export default () => {
	const {user} = useAuth();
	const [customDataSource, setCustomDataSource] = useState(null);
	let token = user ? user.data.token : '';
	let language = user ? user.data.userData.language : '';
	const permission = getPermission(user, "MISReports", "InventoryReport");

	/* Instance components in order to reset them when necessary */
	const plantSelectBox = useRef(null);
	const yearSelectBox = useRef(null);
	const monthSelectBox = useRef(null);
	const locationSelectBox = useRef(null);
	const typeSelectBox = useRef(null);
	const reportRadioBtn = useRef(null);
	const partNumRadioBtn = useRef(null);

	const reportOptions = ["Pre Inventory", "Post Inventory"];
	const invOption = ["Active", "Lost", "Found", "All"];
	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [typeList, setTypeList] = useState();
	const [physList, setPhysList] = useState();
	const [invOptionSelected, setInvOptionSelected] = useState(invOption[3]);
	//const [disableLocation, setDisableLocation] = useState(true);
	const [plants, setPlants] = useState(null);

	const [plantIDSelected, setPlantIDSelected] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [disableSearchBtn, setDisableSearchBtn] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [locationCodeList, setlocationCodeList] = useState(null);
	const [years, setYears] = useState(null); //keep the years list
	const [currentYear, setCurrentYear] = useState(null); // this one will set the value in the dropdown
	const [initialYear, setInitialYear] = useState(null); //this one will be used to reset the initial values
	const [months, setMonths] = useState(null); //keep the month list
	const [currentMonth, setCurrentMonth] = useState(null); // this one will set the value in the dropdown
	const [newMonth, setNewMonth] = useState(null); // this one will set the value in the dropdown
	const [initialMonth, setInitialMonth] = useState(null); //this one will be used to reset the initial values
	const [currentMonthNumber, setCurrentMonthNumber] = useState(null);
	const [showDGColumns, setShowDGColumns] = useState(false);
	const [partTitle, setPartTitle] = useState("Cust. Part No.");
	const [activePlant, setActivePlant] = useState(null); // this one will be used in case user remove the filters
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		year: "",
		month: "",
		phyId: "",
		type: ""
	});
	const reportParamsArr = ["plantID", "year", "month", "phyId", "type"];
	const [showTootlTip, setShowTooltip] = useState(true);

	useEffect(() => {
		let getYear = `${appInfo.reportURL}PhysicalInventoryReport/Years`;
		let getMonths = `${appInfo.reportURL}PhysicalInventoryReport/Months`;
		let getLocationCodes = `${appInfo.reportURL}InventoryReport/Locations`;
		let getActivePlant = `${appInfo.reportURL}PhysicalInventoryReport/ActivePlant`;
		let getTypes = `${appInfo.reportURL}PhysicalInventoryReport/Types`;
		let getPlants = `${appInfo.reportURL}PhysicalInventoryReport/Plants`;
		(async () => {
	
				await fetch(getLocationCodes, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customLocation = result.map((location) => {
							return location.value;
						});
						setlocationCodeList(customLocation);
					})
					.catch((error) => {
						console.warn(error);
					});
	
				await fetch(getYear, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let yearsList = result.map((year) => {
							return year.value;
						});
						let currYear = new Date().getFullYear();
						currYear = currYear.toString();
						let yearArrayPosition = yearsList.indexOf(currYear);
						setCurrentYear(yearsList[yearArrayPosition]);
						setInitialYear(yearsList[yearArrayPosition]);
						setYears(yearsList);
					})
					.catch(err => console.warn(err));
		
				await fetch(getMonths, {
					method: "get",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
					.then((response) => response.json())
					.then((result) => {
						let monthList = result.map((month) => {
							return month.value;
						});
						let currMonth = new Date().getMonth();
						setCurrentMonth(monthList[currMonth]);
						setInitialMonth(monthList[currMonth]);
						currMonth = currMonth + 1;
						setCurrentMonthNumber(currMonth);
						setMonths(monthList);
						setNewMonth(currMonth < 10 ? "0" + currMonth : currMonth);
					})
					.catch((error) => {
				console.error(error, "PhysicalInventoryReport fail getting month list");
			});
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						setReportDetail((oldValues) => {
							return {...oldValues, plantId: result.Id};
						});
						setPlantIDSelected(result.Id);
						setActivePlant(result.Id);
					})
					.catch((error) => {
				console.error(error ,"PhysicalInventoryReport fail getting active plant");
			});
		
				await fetch(getTypes, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						setTypeList(result);
					})
					.catch(error => {
				console.error(error, "PhysicalInventoryReport fail getting Types");
			});
			await fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch(error => console.warn(error));
		})();
	}, []);

	const reportOptionOnChange = (e) => {
		setPhysList(null);
		e.promise = setReportOptionSelected(e.value);
		if (e.value == "Pre Inventory") {
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "s"};
			});
			setShowDGColumns(false);
			setInvOptionSelected(invOption[3]);
		} else if (e.value == "Post Inventory") {
			//turn on flag to show extra columns
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, reportOption: "d"};
			});
		}
	};
	const invOptionOnChange = (e) => {
		setPhysList(null);
		e.promise = setInvOptionSelected(e.value);
		if (e.value == "Active") {
			setReportDetail((oldValues) => {
				return {...oldValues, invOption: "A"};
			});
			setShowDGColumns(false);
		} else if (e.value == "Lost") {
			//turn on flag to show extra columns
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, invOption: "L"};
			});
		} else if (e.value == "Found") {
			//turn on flag to show extra columns
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, invOption: "F"};
			});
		} else if (e.value == "All") {
			//turn on flag to show extra columns
			setShowDGColumns(true);
			setReportDetail((oldValues) => {
				return {...oldValues, invOption: "ALL"};
			});
		}
	};

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		let repOpt = "";
		if (reportOptionSelected == "Pre Inventory") {
			repOpt = "PRE";
		} else if (reportOptionSelected == "Post Inventory") {
			repOpt = "POST";
		}

		let invOpt = "";
		if (invOptionSelected == "Active") {
			invOpt = "A";
		} else if (invOptionSelected == "Lost") {
			invOpt = "L";
		} else if (invOptionSelected == "Found") {
			invOpt = "F";
		} else if (invOptionSelected == "All") {
			invOpt = "ALL";
		}
		let params = "?";
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.reportURL}PhysicalInventoryReport${params}&reportOption=${repOpt}&invOption=${invOpt}`
			})
		);
		setShowTooltip(false);
		setfirstTimeLoading(false);
		setDisableFilters(true);
		setDisableSearchBtn(true);
	};
	const onChangeMonth = (e) => {
		setPhysList(null);
		let monthNum;
		switch (e.value) {
			case "JAN":
				monthNum = "01";
				break;
			case "FEB":
				monthNum = "02";
				break;
			case "MAR":
				monthNum = "03";
				break;
			case "APR":
				monthNum = "04";
				break;
			case "MAY":
				monthNum = "05";
				break;
			case "JUN":
				monthNum = "06";
				break;
			case "JUL":
				monthNum = "07";
				break;
			case "AUG":
				monthNum = "08";
				break;
			case "SEP":
				monthNum = "09";
				break;
			case "OCT":
				monthNum = "10";
				break;
			case "NOV":
				monthNum = "11";
				break;
			case "DEC":
				monthNum = "12";
				break;
		}
		if (months !== null) {
			let positonMonth = monthNum;
			positonMonth = positonMonth - 1;
			setCurrentMonthNumber(positonMonth);
			setCurrentMonth(months[positonMonth]);
			setNewMonth(monthNum);
		}

		setReportDetail((oldValues) => {
			return {...oldValues, month: monthNum};
		});
	};
	const onChangeYear = (e) => {
		setCurrentYear(e.value);
		setPhysList(null);
		setReportDetail((oldValues) => {
			return {...oldValues, year: e.value};
		});
	};
	const setReportParams = (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;
		switch (fieldName) {
			case "phySelector":
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, phyId: currentFieldVal};
					});
				}
				break;
			case "typeSelect":
				
				if (currentFieldVal !== "") {
					setReportDetail((oldValues) => {
						return {...oldValues, type: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, type: currentFieldVal};
					});
				}
				break;
		}
	};
	const onOpenedDropDown = async () => {
		let repOpt = "";
		if (reportOptionSelected == "Pre Inventory") {
			repOpt = "PRE";
		} else if (reportOptionSelected == "Post Inventory") {
			repOpt = "POST";
		}

		let invOpt = "";
		if (invOptionSelected == "Active") {
			invOpt = "A";
		} else if (invOptionSelected == "Lost") {
			invOpt = "L";
		} else if (invOptionSelected == "Found") {
			invOpt = "F";
		} else if (invOptionSelected == "All") {
			invOpt = "ALL";
		}

	
		let typeName = typeSelectBox.current.instance.option("value");

		const phyRequestURL = `${appInfo.reportURL}PhysicalInventoryReport/Phys?skip=0&take=10&requireTotalCount=true&plantID=${plantIDSelected}&year=${currentYear}&month=${newMonth}&reportOption=${repOpt}&invOption=${invOpt}&type=${typeName}`;

		await fetch(phyRequestURL, 
			{	
				method:'get',
				headers: {Authorization: `Bearer ${token}`}
			})
			.then((result) => result.json())
			.then((result) => {
				setPhysList(result.data);
			})
			.catch((error) => {
				console.warn(error);
			});
	};
	const onValueChanged = (e) => {
		setPlantIDSelected(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		setDisableSearchBtn(false);
		setDisableFilters(false);
		setShowTooltip(true);
		setCustomDataSource(null);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Physical Inventory Report</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<div className='dx-labels'>Report Option</div>
							<RadioGroup
								items={reportOptions}
								value={reportOptionSelected}
								layout='horizontal'
								onValueChanged={reportOptionOnChange}
								ref={reportRadioBtn}
								disabled={disableFilters}
							/>
						</div>
						<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						<div className='dx-field'>
							<div className='dx-labels'>Inv Option</div>
							<RadioGroup
								items={invOption}
								value={invOptionSelected}
								onValueChanged={invOptionOnChange}
								layout='horizontal'
								ref={partNumRadioBtn}
								disabled={disableFilters}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<SelectBox
								elementAttr={{
									id: "plantInventorySelect"
								}}
								placeholder='Mfg. Plant'
								items={plants}
								displayExpr='value'
								valueExpr='id'
								onValueChanged={onValueChanged}
								disabled={disableFilters}
								value={plantIDSelected}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Year'
								elementAttr={{
									id: "yearSelect"
								}}
								ref={yearSelectBox}
								searchEnabled={true}
								width='160px'
								items={years}
								onValueChanged={onChangeYear}
								value={currentYear}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Month'
								elementAttr={{
									id: "monthSelect"
								}}
								width='160px'
								ref={monthSelectBox}
								searchEnabled={true}
								onValueChanged={onChangeMonth}
								items={months}
								value={currentMonth}
								disabled={disableFilters}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='PHY Id'
								elementAttr={{
									id: "phySelector"
								}}
								width='160px'
								ref={locationSelectBox}
								items={physList}
								onValueChanged={setReportParams}
								showClearButton={true}
								disabled={disableFilters}
								onOpened={onOpenedDropDown}
							/>
						</div>
						<div className='dx-field'>
							<SelectBox
								placeholder='Type'
								elementAttr={{
									id: "typeSelect"
								}}
								width='160px'
								ref={typeSelectBox}
								disabled={disableFilters}
								onValueChanged={setReportParams}
								items={typeList}
								valueExpr='value'
								displayExpr='value'
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' visible={showTootlTip} position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button
								icon='clear'
								elementAttr={{
									id: "ANScancelButton"
								}}
								onClick={clearFilters}
							/>
						</div>
					</div>
				</form>
				<PhysicalInventoryReportGrid
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
