import React, {useRef, useState, useEffect, useCallback} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	Pager,
	Scrolling,
	ColumnFixing,
	Toolbar,
	Item,
	FilterRow,
	SearchPanel,
	RequiredRule,
	Lookup,
	PatternRule,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import {
	getPermission,
	getMode,
	setMode,
	handleErrors,
} from "./helpers/globalFunctions";
import {alert, confirm} from "devextreme/ui/dialog";
import {Redirect} from "react-router-dom";
import appInfo from "../../app-info";
import IconFilter from "../../components/filter.svg";

export default ({customDataSource, showModelType, currentPrinter}) => {
	const dataGrid = useRef(null);
	let finalMsg = "";
	let canceled;
	const [extraValidationMsg, setExtraValidationMsg] = useState("");
	const allowedPageSizes = [10, 30, 50, "all"];
	const [totalCount, setTotalCount] = useState(0);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';
	let getModels = `${appInfo.configURL}LabelPrinterMaster/ModelTypes?printerType=`;

	const [commTypes, setCommTypes] = useState(null);
	const [modelsList, setModelsList] = useState(null);
	const [transmitModeList, setTransmitModeList] = useState(null);
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"LabelPrinterMaster"
	);

	const [dataGridSettings, setdataGridSettings] = useState({
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		viewmode: true,
		infiniteScroll: "standard",
	});

	useEffect(() => {
		let getTypes = `${appInfo.configURL}LabelPrinterMaster/CommunicationTypes`;
		let getTransmitMode = `${appInfo.configURL}LabelPrinterMaster/TransmitModes`;
		(async () => {
			try {
				await fetch(getTypes, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => response.json())
					.then((result) => {
						setCommTypes(result);
					});
			} catch (error) {
				console.error("Label Printer Master fail getting types request");
			}
			try {
				await fetch(getTransmitMode, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => response.json())
					.then((result) => {
						setTransmitModeList(result);
					});
			} catch (error) {
				console.error(
					"Label Printer Master fail getting transmit model request"
				);
			}
		})();
	}, []);
	useEffect(() => {
		getModels += currentPrinter;
		(async () => {
			try {
				await fetch(getModels, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
				})
					.then((response) => response.json())
					.then((result) => {
						setModelsList(result);
					});
			} catch (error) {
				console.error("Label Printer Master fail getting models request");
			}
		})();
	}, [currentPrinter]);
	/*------- Initial Set Up -----*/
	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};
	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};
	const onEditCanceled = (e) => {
		setMode("View");
	};

	/*-----Functional Settings ----------- */
	const onRowUpdating = (e) => {
		e.oldData.PrinterType = currentPrinter;
		let tempData = {...e.oldData, ...e.newData};
		if (e.newData.port == null) {
			e.newData.port = "";
		}
		let allOK = true;
		Object.keys(tempData).map((key) => {
			if (
				key === "communicationType" &&
				tempData[key] === "TCP/IP" &&
				(tempData["port"] == "" || tempData["port"] == null)
			) {
				setExtraValidationMsg("Port number field is required,");
				allOK = false;
			}
		});
		if (allOK) {
			e.newData = [tempData];
		} else {
			e.newData = [];
		}
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
		if (message == "") {
			finalMsg += extraValidationMsg;
		}
	};
	const onRowInserting = (e) => {
		e.data.PrinterType = currentPrinter;
		let tempData = e.data;
		if (!e.data.port || e.data.port == null) {
			e.data.port = "";
		}
		let allOK = true;
		Object.keys(tempData).map((key) => {
			if (
				key === "communicationType" &&
				tempData[key] === "TCP/IP" &&
				!tempData["port"]
			) {
				setExtraValidationMsg("Port number field is required,");
				allOK = false;
			}
		});
		if (allOK) {
			e.data = [e.data];
		} else {
			//e.cancel = true;
			e.data = [];
		}
	};
	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
		if (message == "") {
			finalMsg += extraValidationMsg;
		}
	};
	const onRowRemoving = (e) => {
		e.data.PrinterType = currentPrinter;
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" &&
				finalMsg.indexOf(message) !== -1 &&
				message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		setExtraValidationMsg("");
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		setMode("View");
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	const onEditorPreparing = (e) => {
		if (e.dataField === "printerName" && e.parentType === "dataRow") {
			e.editorOptions.disabled = !e.row.isNewRow;
		}
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "labelPrinterDataGrid",
				}}
				ref={dataGrid}
				showBorders={true}
				allowColumnResizing={true}
				columnResizingMode="nextColumn"
				columnMinWidth={100}
				columnAutoWidth={true}
				remoteOperations={true}
				dataSource={customDataSource}
				onContentReady={getTotalCount}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}
				onEditorPreparing={onEditorPreparing}>
				<Toolbar>
					<Item location="after">
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location="after">
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id="modeLabel">View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className="dx-toolbar-item"
							hint="Clean Filters"
							onClick={clearFilters}
						/>
					</Item>
					<Item name="addRowButton" />
					<Item name="revertButton" />
					<Item name="saveButton" />
					<Item name="searchPanel" />
				</Toolbar>
				<ColumnFixing enabled={true} />
				<FilterRow
					visible={true}
					applyFilter={{key: "auto", name: "Immediately"}}
				/>
				<SearchPanel visible={true} width={240} placeholder="Search..." />
				<Editing
					mode="batch"
					allowUpdating={true}
					texts={{saveAllChanges: "", cancelAllChanges: ""}}
					allowAdding={permission ? permission.create : false}
					allowDeleting={permission ? permission.delete : false}
				/>
				<Column
					dataField="id"
					caption="No"
					visible={false}
					cssClass="cell-bold"
					allowEditing={false}
					allowFiltering={false}
				/>
				<Column
					dataField="printerName"
					caption="Printer Name"
					cssClass="cell-bold"
					editorOptions={{maxLength: 30}}
					allowEditing={true}>
					<RequiredRule />
				</Column>
				<Column dataField="model" caption="Model">
					<RequiredRule />
					<Lookup
						dataSource={modelsList}
						displayExpr="modelType"
						valueExpr="modelType"
					/>
				</Column>
				<Column dataField="communicationType" caption="Type">
					<RequiredRule />
					<Lookup
						dataSource={commTypes}
						displayExpr="communicationType"
						valueExpr="communicationType"
					/>
				</Column>
				<Column
					dataField="hostName"
					caption="Host/ID"
					editorOptions={{maxLength: 20}}>
					<RequiredRule />
				</Column>
				<Column
					dataField="port"
					caption="Port #"
					editorOptions={{maxLength: 10}}
					alignment="left">
					<PatternRule
						pattern={/[0-9]/}
						message={"Port number should be a numeric value"}
					/>
				</Column>
				<Column
					dataField="transmitMode"
					caption="Model Type"
					visible={showModelType}>
					{showModelType ? <RequiredRule /> : null}
					<Lookup
						dataSource={transmitModeList}
						displayExpr="transmitMode"
						valueExpr="transmitMode"
					/>
				</Column>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode="full"
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};
