import React, {useState, useEffect, useRef, useCallback} from "react";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Tooltip} from "devextreme-react/tooltip";
import ArchiveSummaryGrid from "./ArchiveSummaryGrid";
import {useAuth} from "../../contexts/auth";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import {getPermission} from "../configuration/helpers/globalFunctions";
import ArchiveDropdown from "./additionalComponents/ArchiveDropdown";

export default () => {
	const {user} = useAuth();
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	const radioOptions = ["ASN", "Master Label", "Pallet", "Box", "Harness"];
	const [radioOptionsSelected, setRadioOptionsSelected] = useState(radioOptions[0]);
	const permission = getPermission(user, "MISReports", "ArchiveSummary");
	const [disableFields, setDisableFields] = useState(false);
	const [exportedVal, setExportedVal] = useState();
	let number;
	// /* Instance components in order to reset them when necessary */
	const radioBtn = useRef();
	const clearFilters = () => {
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		setDisableFields(false);
		setdisableSearchBtn(false);
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
	};

	const changeRadioOptionsSelected = (e) => {	
		if(e.value !== radioOptionsSelected)
		{
			setRadioOptionsSelected(e.value);
		}
	};
	const callbackDropdownNumber=(num)=>{
		//setNumberSelected(number);
		number=num;
	}
	const sendReportRequest = () => {
		
		let reportOption = "";
		switch (radioOptionsSelected) {
			case "ASN":
				reportOption = "ASN";
				break;

			case "Master Label":
				reportOption = "ML";
				break;

			case "Pallet":
				reportOption = "PLT";
				break;

			case "Box":
				reportOption = "BOX";
				break;

			case "Harness":
				reportOption = "HRNS";
				break;

			default:
				break;
		}
		// let searchFilter = number.current.instance.option("value");
		let url = `${appInfo.reportURL}ArchiveSummaryReport?searchFilter=${number}&reportOption=${reportOption}`;
		
		setCustomDataSource(
			store({
				url: url
			})
		);
		setDisableFields(true);
		setdisableSearchBtn(true);
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Archive Summary</h2>
					</div>
				</div>
				<form id='PrintingDetails' className={"filter-form"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-field'>
							<RadioGroup
								ref={radioBtn}
								items={radioOptions}
								layout='horizontal'
								disabled={disableFields}
								value={radioOptionsSelected}
								onValueChanged={changeRadioOptionsSelected}
							/>
						</div>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
						<ArchiveDropdown radioOptionsSelected={radioOptionsSelected} disableFields={disableFields} callbackDropdownNumber={callbackDropdownNumber}></ArchiveDropdown>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								onClick={sendReportRequest}
								disabled={disableSearchBtn}
							/>
							<Tooltip target='#ANSfindButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<ArchiveSummaryGrid
					customDataSource={customDataSource}
					asnVal={exportedVal}
					radioOptionsSelected={radioOptionsSelected}
				/>
			</div>
		</React.Fragment>
	);
};
