import React, {useState, useEffect} from "react";
import store from "../../components/hooks/store";
import {getPermission, handleErrors} from "./helpers/globalFunctions";
import SelectBox from "devextreme-react/select-box";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import BarCodeRuleMasterGrid from "./BarCodeRuleMasterGrid";

export default () => {
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token :'';
	const permission = getPermission(
		currentUser,
		"ConfigurationMasters",
		"BarcodeRuleMaster"
	);

	const [barcodeType, setBarcodeType] = useState(null);
	const [barcodeTypeSelected, setBarcodeTypeSelected] = useState(null);
	const [customDataSource, setCustomDataSource] = useState(null);
	const [itemDesc, setItemDesc] = useState(null);
	const [dataFormatField, setDataFormatField] = useState(null);
	const [disableAddBtn, setDisableAddBtn] = useState(true);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	useEffect(() => {
		let getBarcodeTypes = `${appInfo.configURL}BarcodeRuleMaster/BarCodeType2D`;
		let getItemDesc = `${appInfo.configURL}BarcodeRuleMaster/ItemDescription`;
		let getDataFormField = `${appInfo.configURL}BarcodeRuleMaster/DataFormField`;
		const abortController = new AbortController();
		(async () => {
			try {
				await fetch(getBarcodeTypes, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
					signal: abortController.signal,
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let customBarcode = result.map((barcode) => {
							return barcode.value;
						});
						setBarcodeType(customBarcode);
					});
			} catch (error) {
				console.error("Fail in request getting barcode types");
			}

			try {
				await fetch(getItemDesc, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
					signal: abortController.signal,
				})
					.then((response) => response.json())
					.then((result) => {
						setItemDesc(result);
					});
			} catch (error) {
				console.error("Fail in request getting barcode types");
			}
			try {
				await fetch(getDataFormField, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`},
					signal: abortController.signal,
				})
					.then((response) => response.json())
					.then((result) => {
						setDataFormatField(result);
					});
			} catch (error) {
				console.error("Fail in request getting barcode types");
			}
			return () => {
				// Abort the request when the component unmounts or when a dependency changes
				abortController.abort();
			};
		})();
	}, []);

	const onValueChanged = (e) => {
		setDisableAddBtn(false);
		setBarcodeTypeSelected(e.value);
		setfirstTimeLoading(false);
		setCustomDataSource(
			store({
				url: `${appInfo.configURL}BarcodeRuleMaster?barCodeType2D=${e.value}`,
			})
		);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div>
				<div className="header-left-side">
					<div className="header">
						<h2>Bar Code Rule Master</h2>
					</div>
				</div>
				<div className="header-right-side">
					<SelectBox
						elementAttr={{
							id: "DockMasterSelector",
						}}
						placeholder="BAR CODE TYPE 2D"
						items={barcodeType}
						onValueChanged={onValueChanged}
					/>
				</div>
				<BarCodeRuleMasterGrid
					customDataSource={customDataSource}
					dataFormatField={dataFormatField}
					itemDesc={itemDesc}
					disableAddBtn={disableAddBtn}
					barcodeTypeSelected={barcodeTypeSelected}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
