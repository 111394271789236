import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import {Button} from "devextreme-react/button";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import {getPermission} from "../configuration/helpers/globalFunctions";
import {Tooltip} from "devextreme-react/tooltip";
import BoxHarnessReportGrid from "./BoxHarnessReportGrid";
import {TextBox} from "devextreme-react";
import {alert} from "devextreme/ui/dialog";
import { useAuth } from "../../contexts/auth";

export default () => {
	const [disableSearchBtn, setdisableSearchBtn] = useState(false);
	const [customDataSource, setCustomDataSource] = useState(null);
	const [currentPlantID, setCurrentPlantID] = useState(null);
	const [currentHarness, setCurrentHarness] = useState(null);
	const [currentBoxNo, setCurrentBoxNo] = useState(null);
	const {user} = useAuth();
	
	const permission = getPermission(user, "MISReports", "ShipmentReport");

	/* Instance components in order to reset them when necessary */ const plantID = useRef(null);
	const harnessNo = useRef(null);
	const boxNo = useRef(null);

	const [firstTimeLoading, setfirstTimeLoading] = useState(true);

	const [showSummaryColumns, setShowSummaryColumns] = useState(true);
	const [reportDetail, setReportDetail] = useState({
		plantID: "",
		harnessSNO: "",
		boxSNO: ""
	});
	const reportParamsArr = ["plantID", "harnessSNO", "boxSNO"];
	const [showTootlTip, setShowTooltip] = useState(true);

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = () => {
		//validate first if harness or box are not empty
		if (reportDetail.boxSNO === "" && reportDetail.harnessSNO === "") {
			alert("[20265] Harness Sno or Box Sno Cannot Be Blank.");
			return;
		} else {
			let params = "?";
			reportParamsArr.forEach(function (i) {
				if (i in reportDetail && isNotEmpty(reportDetail[i])) {
					params += `${i}=${JSON.stringify(reportDetail[i])}&`;
				}
			});
			params = params.replace(/"/g, "");
			params = params.slice(0, -1);
			setCustomDataSource(
				store({
					url: `${appInfo.reportURL}BoxHarnessReport${params}`
				})
			);
			setShowTooltip(false);
			setfirstTimeLoading(false);
		}
	};
	const onPlantIDChange = (e) => {
		setCurrentPlantID(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, plantID: e.value};
		});
	};
	const onHarnessChange = (e) => {
		setCurrentHarness(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, harnessSNO: e.value};
		});
	};
	const onBoxChange = (e) => {
		setCurrentBoxNo(e.value);
		setReportDetail((oldValues) => {
			return {...oldValues, boxSNO: e.value};
		});
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		const event = new CustomEvent("cleanFileds");
		window.dispatchEvent(event);
		setCustomDataSource(null);
		setShowTooltip(true);
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>Box/Harness Report</h2>
					</div>
				</div>
				<form className={"filter-form"}>
					<br />
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<TextBox
								placeholder='Plant ID'
								value={currentPlantID}
								onValueChanged={onPlantIDChange}
								showClearButton={true}
								maxLength='12'
								ref={plantID}
							/>
						</div>
						<div className='dx-field'>
							<TextBox
								placeholder='Harness SNo'
								elementAttr={{
									id: "cycleCountId"
								}}
								ref={harnessNo}
								value={currentHarness}
								maxLength='25'
								onValueChanged={onHarnessChange}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<TextBox
								placeholder='Box Sno'
								ref={boxNo}
								elementAttr={{
									id: "cycleCountId"
								}}
								value={currentBoxNo}
								maxLength='15'
								onValueChanged={onBoxChange}
								showClearButton={true}
							/>
						</div>
						<div className='dx-field'>
							<Button
								icon='find'
								elementAttr={{
									id: "ANSfindButton"
								}}
								disabled={disableSearchBtn}
								onClick={sendReportRequest}
							/>
							<Tooltip target='#ANSfindButton' position='bottom'>
								<div>* Click on Search Icon to get Results </div>
							</Tooltip>
						</div>
						<div className='dx-field'>
							<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
						</div>
					</div>
				</form>
				<BoxHarnessReportGrid
					showSummaryColumns={showSummaryColumns}
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
				/>
			</div>
		</React.Fragment>
	);
};
