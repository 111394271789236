import React from "react";
import {DataGrid, Column} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import appInfo from "../../app-info";

export default (props) => {
	const shipmentRecord = props.data.data;
	const shipmentParams = {
		plantID: shipmentRecord.plantId,
		partNumber: shipmentRecord.sewsPartNo,
		custGroup: shipmentRecord.custGroup,
		customerID: shipmentRecord.customerID,
		wharehouse: shipmentRecord.wharehouse,
		sewsDesignNo: shipmentRecord.sewsDesignLevel,
		month: shipmentRecord.month,
		year: shipmentRecord.year
	};
	const reportParamsArr = [
		"plantID",
		"month",
		"year",
		"partNumber",
		"customerID",
		"custGroup",
		"wharehouse",
		"sewsDesignNo"
	];
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser.data.token;
	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";

	const shipmentDetails = new DataSource({
		key: "id",
		load: () => {
			let params = "";
			reportParamsArr.forEach(function (i) {
				if (i in shipmentParams && isNotEmpty(shipmentParams[i])) {
					params += `${i}=${JSON.stringify(shipmentParams[i])}&`;
				}
			});
			params = params.replace(/"/g, "");
			params = params.slice(0, -1);

			return fetch(`${appInfo.reportURL}OverShipmentTraceReport/ShipmentDetails?${params}`, {
				method: "get",
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((response) => response.json())
				.then((response) => {
					return response.data;
				});
		},
		byKey: (key) => {
			//Do Not Delete By Key method. Deve Express need it not sure for what
		}
	});
	return (
		<React.Fragment>
			<DataGrid
				dataSource={shipmentDetails}
				showBorders={true}
				columnAutoWidth={true}
				allowColumnResizing={true}
				columnResizingMode='widget'
				columnMinWidth={100}>
				<Column dataField='id' visible={false} caption='ID' cssClass='cell-bold' />
				<Column dataField='asnNumber' caption='ASN NUMBER' cssClass='cell-bold' />
				<Column dataField='shipDate' dataType='datetime' caption='Ship Date' cssClass='cell-bold' />
				<Column dataField='sewsPartNo' caption='SEWS Part No.' cssClass='cell-bold' />
				<Column dataField='sewsDesignLevel' caption='SEWS Design Level' cssClass='cell-bold' />
				<Column dataField='custGroup' caption='Cust. Group' cssClass='cell-bold' />
				<Column dataField='customerID' caption='Customer ID' cssClass='cell-bold' />
				<Column dataField='warehouse' caption='Warehouse' cssClass='cell-bold' />
				<Column dataField='totalQty' caption='Total Qty' cssClass='cell-bold' />
				<Column dataField='reOpen' caption='Re-Open' cssClass='cell-bold' />
			</DataGrid>
		</React.Fragment>
	);
};
