import React, {useState, useEffect, useRef} from "react";
import store from "../../components/hooks/store";
import SelectBox from "devextreme-react/select-box";
import {Button} from "devextreme-react/button";
import axios from "axios";
import RadioGroup from "devextreme-react/radio-group";
import appInfo from "../../app-info";
import {Tooltip} from "devextreme-react/tooltip";
import NoBoxRelabelingGrid from "./noBoxRelabelingGrid";
import {TextBox} from "devextreme-react";
import {alert, confirm} from "devextreme/ui/dialog";
import {Redirect} from "react-router-dom";
import { getPermission, handleErrors } from "../configuration/helpers/globalFunctions";

export default () => {
	const [disableSearchBtn, setdisableSearchBtn] = useState(true);
	const [customDataSource, setCustomDataSource] = useState(null);
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	const permission = getPermission(
		currentUser,
		"ReworkReinspection",
		"NoboxRelabeling"
	);
	//   // /* Instance components in order to reset them when necessary */
	const plantSelectBox = useRef(null);
	const optionHarnessSelectBox = useRef(null);
	const boxLabel = useRef(null);

	const reportOptions = ["Mexico", "GPP"];
	const optionHarness = ["WITH HARNESS", "WITHOUT HARNESS"];
	const [activePlant, setActivePlant] = useState(null); // this one will be used in case user remove the filters

	const [reportOptionSelected, setReportOptionSelected] = useState(reportOptions[0]);
	const [params, setParams] = useState({sourceHarness: "Mexico", HarnessOption: "WITH HARNESS"});
	const [plants, setPlants] = useState(null);
	const [plantIDSelected, setPlantIDSelected] = useState();
	const [disableFilters, setDisableFilters] = useState(false);
	const [firstTimeLoading, setfirstTimeLoading] = useState(true);
	const [reportDetail, setReportDetail] = useState({
		plantId: "",
		boxSno: "",
		harnessOption: "WITH HARNESS",
		sourceHarness: "Mexico"
	});
	const reportParamsArr = ["plantId", "boxSno", "harnessOption", "sourceHarness"];
	const [quantity, setQuantity] = useState();
	const [DGContainsHno, setDGContainsHno] = useState(false);
	const [withHasness, setWithHarness]= useState(optionHarness[0])
	const [disableWithHarness, setDisableWithHarness]= useState(false);
	useEffect(() => {
		if (quantity !== "" && quantity !== undefined) setdisableSearchBtn(false);
	}, [quantity]);

	useEffect(() => {
		let getPlants = `${appInfo.reworkURL}NoBoxRelabeling/Plants`;
		let getActivePlant = `${appInfo.reworkURL}NoBoxRelabeling/ActivePlant`;
		(async () => {
			fetch(getPlants, {
				headers: {Authorization: `Bearer ${token}`}
			})
				.then((response) => handleErrors(response))
				.then((result) => result.json())
				.then((result) => {
					let customPlant = result.map((plant) => {
						return {
							id: plant.Id,
							value: plant.Id + " - " + plant.Value
						};
					});
					setPlants(customPlant);
				})
				.catch((err) => {
					console.warn(err);
				});
		
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((result) => result.json())
					.then((result) => {
						setPlantIDSelected(result[0].Id);
						setReportDetail((oldValues) => {
							return {...oldValues, plantId: result[0].Id};
						});
						setParams((oldParams) => {
							return {...oldParams, plantId: result[0].Id};
						});
						setPlantIDSelected(result[0].Id);
						setActivePlant(result[0].Id);
					})
			.catch((error) =>{
				console.warn("NoBoxRelabeling fail getting active plant");
			});
		})();
	}, []);

	const reportOptionOnChange = (e) => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		e.promise = setReportOptionSelected(e.value);
		if (e.value == "Mexico") {
			setReportDetail((oldValues) => {
				return {...oldValues, sourceHarness: "Mexico"};
			});
			setParams((oldParams) => {
				return {...oldParams, sourceHarness: "Mexico"};
			});
			setDisableWithHarness(false);
		} else {
			//turn on flag to show extra columns
			setReportDetail((oldValues) => {
				return {...oldValues, sourceHarness: "GPP"};
			});
			setParams((oldParams) => {
				return {...oldParams, sourceHarness: "GPP"};
			});
			setWithHarness(optionHarness[0]);
			setDisableWithHarness(true);
		}
	};

	const isNotEmpty = (value) => value !== undefined && value !== null && value !== "";
	const sendReportRequest = async () => {
		let params = "?";
		setDisableFilters(true);
		if (!quantity) {
			alert("The value Quantity in Box is required.");
			return;
		}
		if (!reportDetail.harnessOption) {
			alert("The Harness Option field is required.");
			return;
		}
		reportParamsArr.forEach(function (i) {
			if (i in reportDetail && isNotEmpty(reportDetail[i])) {
				params += `${i}=${JSON.stringify(reportDetail[i])}&`;
			}
		});
		params = params.replace(/"/g, "");
		params = params.slice(0, -1);
		setCustomDataSource(
			store({
				url: `${appInfo.reworkURL}NoBoxRelabeling${params}&quantity=${quantity}`
			})
		);
		
		setfirstTimeLoading(false);
	};

	const stateUp = (value) => {
		setDGContainsHno(value);
	};

	const deleteAll = async () => {
		const dialogResult = await confirm(
			"[20129] Do you want to modify option with harness/without harness?"
		);
		if (dialogResult) {
			setDGContainsHno(false);
			await axios
				.delete(`${appInfo.reworkURL}NoBoxRelabeling/`, {
					headers: {Authorization: `Bearer ${token}`},
					data: {
						PlantId: params.plantId,
						BoxSNO: params.boxSno,
						SourceHarness: params.sourceHarness,
						HarnessOption: params.HarnessOption
					}
				})
				.then((res) => {
					let responseMsg = res.data;
					if (responseMsg) {
						//alert(responseMsg);
						setCustomDataSource(null);
						sessionStorage.setItem("totalCount", 0);
					}
				});
		}
	};
	const clearFilters = () => {
		sessionStorage.setItem("totalCount", 0);
		setCustomDataSource(null);
		boxLabel.current.instance.reset();
		setPlantIDSelected(activePlant);
		setDisableFilters(false);
		setReportOptionSelected(reportOptions[0]);
		setQuantity("");
	};
	const setReportParams = async (e) => {
		let fieldName = e.element.id;
		let currentFieldVal = e.value;

		switch (fieldName) {
			case "boxLabel":
				if (currentFieldVal !== "" && currentFieldVal !== null) {
					let params = `?plantId=${reportDetail.plantId}&boxSNO=${currentFieldVal}&harnessOption=${reportDetail.harnessOption}&sourceHarness=${reportDetail.sourceHarness}`;
					try {
						await fetch(`${appInfo.reworkURL}NoBoxRelabeling/Quantity${params}`, {
							method: "get",
							headers: {Authorization: `Bearer ${token}`}
						})
							.then((result) => result.json())
							.then((result) => {
								if (typeof result != "number") {
									alert(result);
									setQuantity("");
									e.component.focus();
								} else {
									setQuantity(result.toString());
									setParams((oldParams) => {
										return {...oldParams, quantity: result.toString()};
									});
								}
							});
					} catch (error) {
						console.error(error, "NoBoxRelabeling fail getting Quantity");
					}
					setReportDetail((oldValues) => {
						return {...oldValues, boxSno: currentFieldVal};
					});
					setParams((oldParams) => {
						return {...oldParams, boxSno: currentFieldVal};
					});
				} else {
					setReportDetail((oldValues) => {
						return {...oldValues, boxSno: currentFieldVal};
					});
					setParams((oldParams) => {
						return {...oldParams, boxSno: currentFieldVal};
					});
					setQuantity("");
				}
				break;
			case "harnessOption":
				setParams((oldParams) => {
					return {...oldParams, harnessOption: currentFieldVal};
				});
				setWithHarness(currentFieldVal);
				if (currentFieldVal === "WITHOUT HARNESS" && DGContainsHno === true) {
					await deleteAll();
				}
				setReportDetail((oldValues) => {
					return {...oldValues, harnessOption: encodeURIComponent(currentFieldVal)};
				});
				break;
		}
	};
	const onValueChanged = (e) => {
		setReportDetail((oldValues) => {
			return {...oldValues, plantId: e.value};
		});
	};
	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}
	return (
		<React.Fragment>
			<div>
				<div className='header-left-side'>
					<div className='header'>
						<h2>No Box Relabeling</h2>
					</div>
				</div>

				<form id='PrintingDetails' className={"printing-details"}>
					<div className='dx-fieldset'>
						<br />
						<div className='dx-labels'>Source Harness</div>
						<RadioGroup
							items={reportOptions}
							value={reportOptionSelected}
							onValueChanged={reportOptionOnChange}
							layout='horizontal'
						/>
					</div>
					<div className='dx-fieldset'>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<SelectBox
									width='310px'
									valueExpr='id'
									displayExpr='value'
									searchEnabled={true}
									ref={plantSelectBox}
									items={plants}
									value={plantIDSelected}
									elementAttr={{
										id: "mfgPlant"
									}}
									placeholder='Mfg. Plant'
									disabled={disableFilters}
									showClearButton={true}
									onValueChanged={onValueChanged}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Box Label'
									elementAttr={{
										id: "boxLabel"
									}}
									ref={boxLabel}
									onValueChanged={setReportParams}
									showClearButton={true}
									maxLength={25}
									disabled={disableFilters}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<SelectBox
									placeholder='Option'
									elementAttr={{
										id: "harnessOption"
									}}
									value={withHasness}
									disabled={disableWithHarness}
									items={optionHarness}
									ref={optionHarnessSelectBox}
									searchEnabled={true}
									onValueChanged={setReportParams}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<TextBox
									placeholder='Quantity in Box'
									elementAttr={{
										id: "quantityInBox"
									}}
									onValueChanged={setReportParams}
									showClearButton={true}
									readOnly={true}
									value={quantity}
								/>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button
									icon='find'
									elementAttr={{
										id: "ANSfindButton"
									}}
									disabled={disableSearchBtn}
									onClick={sendReportRequest}
								/>
								<Tooltip target='#ANSfindButton' position='bottom'>
									<div>* Click on Search Icon to get Results </div>
								</Tooltip>
							</div>
						</div>
						<div className='dx-field'>
							<div className='dx-field-value'>
								<Button icon='clear' elementAttr={{id: "ANScancelButton"}} onClick={clearFilters} />
							</div>
						</div>
					</div>
				</form>
				<NoBoxRelabelingGrid
					customDataSource={customDataSource}
					firstTimeLoading={firstTimeLoading}
					params={params}
					stateUp={stateUp}
				/>
			</div>
		</React.Fragment>
	);
};
