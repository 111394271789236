import React from 'react';
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Selection,
  FilterRow
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import appInfo from '../../../app-info';
import axios from 'axios';

let token = '';

if (sessionStorage.getItem("currentUser") !== null) {
  let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  token = currentUser.data.token;
}

const dropDownOptions = { width: 500 };
const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
let currentPlant = null;

const ds = new DataSource({

  store: new CustomStore({
    byKey: function (key) {
      return key;
    },
    load: (loadOptions) => {
      let params = '&';
      [
        'filter',
        'requireTotalCount',
        'searchValue',
        'skip',
        'take',
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);
      currentPlant = sessionStorage.getItem('currentPlant');
      return axios.get(
        `${appInfo.configURL}ComponentCodeMaster/SewsPartNumbers?plantid=${currentPlant}${params}`,
        {
          headers: { "Authorization": `Bearer ${token}` }
        })
        .then(result => {
          return result.data;
        });
    },
  })
});

export default class SEWSDropDownBoxComponent extends React.Component {
  constructor(props) {
    super(props);
    let newRow = props.data.row.isNewRow ? false : true;
    this.state = {
      selectedValue: props.data.value,
      isDropDownOpened: false,
      disableDG: newRow,
    };

    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.contentRender = this.contentRender.bind(this);
    this.boxOptionChanged = this.boxOptionChanged.bind(this);
  }

  boxOptionChanged(e) {
    if (e.name === 'opened') {
      this.setState({
        isDropDownOpened: e.value,
      });
    }
  }

  contentRender() {
    return (
      <DataGrid
        dataSource={ds}
        height={300}
        hoverStateEnabled={true}
        onSelectionChanged={this.onSelectionChanged}
      >
        <Column dataField="sewsPartNo" />
        <Column dataField="customrCode" />
        <Column dataField="supplierId" />
        <Paging enabled={true} defaultPageSize={10} />
        <Scrolling mode="virtual" />
        <Selection mode="single" />
        <FilterRow visible={true} applyFilter={{ key: 'auto', name: 'Immediately' }} />
      </DataGrid>
    );
  }

  onSelectionChanged(selectionChangedArgs) {
    this.setState({
      selectedValue: selectionChangedArgs.selectedRowsData[0].sewsPartNo,
      isDropDownOpened: false,
    });

    this.props.data.setValue(this.state.selectedValue);
  }

  render() {
    return (
      <DropDownBox
        onOptionChanged={this.boxOptionChanged}
        opened={this.state.isDropDownOpened}
        disabled={this.state.disableDG}
        dropDownOptions={dropDownOptions}
        dataSource={ds}
        value={this.state.selectedValue}
        displayExpr="sewsPartNo"
        valueExpr="sewsPartNo"
        contentRender={this.contentRender}>
      </DropDownBox>
    );
  }
}