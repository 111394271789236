import React, {useState, useEffect, useRef} from "react";
import Form, {Item, Label} from "devextreme-react/form";
import {Button} from "devextreme-react/button";
import {RequiredRule} from "devextreme-react/validator";
import {confirm} from "devextreme/ui/dialog";
import {processBatchRequest, getPermission, handleErrors} from "./helpers/globalFunctions";
import {alert} from "devextreme/ui/dialog";
import SelectBox from "devextreme-react/select-box";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";

export default () => {
	const params = {
		url: `${appInfo.configURL}PlantMaster`
	};
	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	const permission = getPermission(currentUser, "ConfigurationMasters", "PlantMaster");

	const form = useRef(null);
	const [plantDetails, setPlantDetails] = useState();
	const [plants, setPlants] = useState();
	const [disableFields, setDisableFields] = useState(true);
	const [disableBtns, setDisableBtns] = useState(true);
	const [printers, setPrinters] = useState(null);
	const [currentPlant, setcurrentPlant] = useState("");
	const [planData, setData] = useState({
		labelLocation: "top",
		readOnly: false,
		showColon: true,
		minColWidth: 300,
		colCount: 4
	});

	useEffect(() => {
		let getPlants = `${params.url}/Plants`;
		let getPrinters = `${params.url}/Printers`;
		let getActivePlant = `${appInfo.configURL}DockMaster/ActivePlant`;

		(async () => {
			try {
				await fetch(getActivePlant, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => handleErrors(response))
					.then((response) => response.json())
					.then((result) => {
						let activePlant = result;
						activePlant = activePlant.trim();
						setcurrentPlant(activePlant);
					});
			} catch (error) {
				console.warn("Getting active plant failed request:", error);
			}
			try {
				await fetch(getPlants, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						let customPlant = result.map((plant) => {
							return {
								id: plant.Id,
								value: plant.Id + " - " + plant.Value
							};
						});
						setPlants(customPlant);
					});
			} catch (error) {
				console.error("Plant fail request");
			}
			try {
				await fetch(getPrinters, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						let customPrint = result.map((print) => {
							return print.value;
						});
						setPrinters(customPrint);
					});
			} catch (err) {
				console.error("Harness plant fail request");
			}
		})();
	}, []);
	const onValueChanged = (e) => {
		setcurrentPlant(e.value);
		let getPlantID = e.value;
		getPlantID = getPlantID.split(" - ");
		let selectedPlant = getPlantID[0];
		getPlantMaster(selectedPlant);
		setDisableFields(false);
		setDisableBtns(!permission.update);
	};
	const getPlantMaster = (selectedPlant) => {
		let url = `${params.url}/${selectedPlant}/`;
		(async () => {
			try {
				let plantInfo = "";
				await fetch(url, {
					method: "get",
					headers: {Authorization: `Bearer ${token}`}
				})
					.then((response) => response.json())
					.then((result) => {
						plantInfo = result;
						setPlantDetails(plantInfo);
					});
			} catch (error) {
				console.error("Plant fail request");
			}
		})();
	};
	const sumbitAction = async () => {
		//First validate plant Name is not empty
		let plantName = plantDetails.plant_nm;
		plantName = plantName.trim();

		if (plantName == "") {
			alert("Cannot Be Blank");
		} else {
			const dialogResult = await confirm("Are you sure you want to save?");
			if (dialogResult) {
				processBatchRequest(params.url, token, plantDetails);
			}
		}
	};
	const cancelAction = async (e) => {
		const dialogResult = await confirm(
			"Unsaved data exist. <br/> Are you sure you want to cancel?"
		);
		if (dialogResult) {
			setPlantDetails(null);
			setDisableFields(true);
			setcurrentPlant("");
			setDisableBtns(true);
		}
	};

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div id="plantMasterContainer">
				<h2>Plant Master</h2>
				<SelectBox
					items={plants}
					placeholder='Plant Code - Plant Name'
					value={currentPlant}
					displayExpr='value'
					searchEnabled={true}
					valueExpr='id'
					elementAttr={{
						id: "PlanMasterSelector"
					}}
					onValueChanged={onValueChanged}
				/>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<Button type='normal' disabled={disableBtns} icon='save' onClick={sumbitAction} />
				<span>&nbsp;</span>
				<span>&nbsp;</span>
				<Button icon='undo' type='normal' disabled={disableBtns} onClick={cancelAction} />
				<div id='form-container'>
					<div className='widget-container'>
						<Form
							id='formPlantMaster'
							formData={plantDetails}
							readOnly={planData.readOnly}
							showColonAfterLabel={planData.showColon}
							labelLocation={planData.labelLocation}
							minColWidth={planData.minColWidth}
							colCount={planData.colCount}
							width={planData.width}
							validationGroup='PlantData'>
							<Item dataField='plantId' visible={false} />
							<Item dataField='identIFier' visible={false} />
							<Item
								dataField='plant_nm'
								colSpan={2}
								editorOptions={{
									disabled: disableFields,
									maxLength: 80,
									validationMessageMode: "always"
								}}>
								<Label text='Plant Name' />
								<RequiredRule message='Plant name is required' />
							</Item>
							<Item
								dataField='std_printer'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 80}}>
								<Label text='Default System Printer' />
							</Item>
							<Item dataField='identIFier' editorOptions={{disabled: true}}>
								<Label text='Identifier' />
							</Item>
							<Item
								editorType='dxCheckBox'
								dataField='primary_plant'
								editorOptions={{disabled: disableFields}}>
								<Label text='Primary Plant' />
							</Item>
							<Item
								dataField='planT_ADDRESS1'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 80}}>
								<Label text='Plant Address1' />
							</Item>
							<Item
								dataField='made_in'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 50}}>
								<Label text='Made in Phrase' />
							</Item>
							<Item
								dataField='planT_ADDRESS2'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 80}}>
								<Label text='Plant Address2' />
							</Item>
							<Item
								dataField='printed_in'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 50}}>
								<Label text='Printed in Phrase' />
							</Item>
							<Item
								dataField='planT_ADDRESS3'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 80}}>
								<Label text='Plant Address3' />
							</Item>
							<Item
								dataField='label_printer'
								editorType='dxSelectBox'
								colSpan={2}
								editorOptions={{
									items: printers,
									value: "",
									disabled: disableFields,
									searchEnabled: true
								}}>
								<Label text='Default Label Printer' />
							</Item>
							<Item
								dataField='planT_ADDRESS4'
								colSpan={2}
								editorOptions={{disabled: disableFields, maxLength: 80}}>
								<Label text='Plant Address4' />
							</Item>
						</Form>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
