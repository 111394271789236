import React from "react";
import "./home.scss";

export default () => (
		<img
			src={"../../../FGSS_Image.png"}
			alt="Sumitomo Homepage"
			height={"auto"}
			width={"100%"}
			maxHeight={"550px"}
		/>
);
