import React, {useState, useCallback, useRef, useMemo} from "react";
import DataGrid, {
	Column,
	Editing,
	Paging,
	Pager,
	Scrolling,
	Toolbar,
	ColumnFixing,
	Item,
	Export,
	FilterRow,
	SearchPanel,
	Lookup,
	RequiredRule
} from "devextreme-react/data-grid";
import {getPermission, setMode, getMode} from "./helpers/globalFunctions";
import Button from "devextreme-react/button";
import {alert, confirm} from "devextreme/ui/dialog";
import axios from "axios";
import appInfo from "../../app-info";
import {Redirect} from "react-router-dom";
import IconFilter from "../../components/filter.svg";

let sspExists = false;

export default ({customDataSource, plantId, sourceShortPartNo, destSEWSPartNo}) => {
	const allowedPageSizes = [10, 30, 50, "all"];
	let finalMsg = "";
	let canceled = false;
	const dataGrid = useRef(null);

	let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
	let token = currentUser ? currentUser.data.token : '';

	const permission = getPermission(currentUser, "ConfigurationMasters", "PartMappingMaster");
	const [totalCount, setTotalCount] = useState(0);

	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard"
	});
	const dataGridConfiguration = useMemo(() => {
		return {
			id: "xrefMainDataGrid"
		};
	}, []);

	/* Set up methods */
	/* Source Short Part No. and Destination Part No. Configuration */

	const getSingleSourceSelected = (sspn) => {
		sspn = sspn.trim();
		return axios
			.get(
				`${appInfo.configURL}PartMappingMaster/SourceShortPartNumber?plantId=${plantId}&sourceShortPartNumber=${sspn}`,
				{headers: {Authorization: `Bearer ${token}`}}
			)
			.then((result) => {
				return result.data;
			});
	};

	async function sourceSelected(newData, value) {
		let localSSPN = await getSingleSourceSelected(value);
		let localSSPNHasResult = true;
		if (localSSPN !== "") {
			localSSPNHasResult = false;
			newData.sourceSewsPartNo = localSSPN.sourceSewsPartNo;
			newData.sourceSewsDesignLevel = localSSPN.sourceSewsDesignLevel;
			this.defaultSetCellValue(newData, value);

			sspExists = true;
		} else {
			newData.sourceSewsPartNo = null;
			newData.sourceSewsDesignLevel = null;
			this.defaultSetCellValue(newData, value);
			sspExists = false;
		}
	}

	const getSingleDestinationSelected = (sspn) => {
		sspn = sspn.trim();
		let URL = `${appInfo.configURL}PartMappingMaster/DestinationShortPartNumber?plantId=${plantId}&destinationShortPartNumber=${sspn}`;
		return axios.get(URL, {headers: {Authorization: `Bearer ${token}`}}).then((result) => {
			return result.data;
		});
	};

	async function destinationSelected(newData, value) {
		let localSSPN = await getSingleDestinationSelected(value);
		newData.destSewsPartNo = localSSPN.destSewsPartNo;
		newData.destSewsDesignLevel = localSSPN.destSewsDesignLevel;
		newData.destLabelFormat = localSSPN.destLabelFormat;
		this.defaultSetCellValue(newData, value);
	}

	const onEditorPreparing = (e) => {
		//custom Items
		if (e.dataField === "sourceShortPartNo") {
			e.editorName = "dxSelectBox"; // Changes the editor's type

			Object.assign(e.editorOptions, {
				acceptCustomValue: true,
				onCustomItemCreating: (args) => {
					const newItem = {
						sourceShortPartNo: args.text
					};
					args.customItem = new Promise((resolve, reject) => {
						const component = args.component;
						const dataSource = component.getDataSource();
						const dataStore = dataSource.store();

						dataStore.insert(newItem).then((r) => {
							dataSource.load();
							e.component.columnOption(e.dataField).lookup.update();

							resolve(newItem);
						});
					});
				}
			});
		}

		if (
			(e.dataField === "sourceSewsPartNo" || e.dataField === "sourceSewsDesignLevel") &&
			e.parentType === "dataRow" &&
			sspExists
		) {
			e.editorOptions.disabled = e.row.isNewRow;
		}
	};

	const onEditCanceled = (e) => {
		setMode("View");
	};

	const onEditCanceling = async (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			const dialogResult = await confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			);
			if (dialogResult) {
				canceled = true;
				e.component.cancelEditData();
				canceled = false;
			}
		}
	};

	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};

	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};

	/* Functionality */
	const onRowInserting = (e) => {
		e.data.plantId = plantId;
		e.data = [e.data];
	};

	const onRowInserted = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;

		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message) && message !== undefined
				? (localResultMsg += "" + message)
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onRowRemoving = (e) => {
		var changes = e.component.option("editing.changes");
		changes.find((c) => c.key === e.key).key = [e.data];
		e.component.option("editing.changes", changes);
	};
	const onRowRemoved = (e) => {
		// Customized getDeleteMsg in store in order to get msg from server
		let response = null;
		let localResultMsg = "";
		response = dataGrid.current.instance.getDataSource().store().getDeleteMsg();
		if (response && Object.keys(response).length !== 0) {
			let message = response.data.message;
			if (response.statusText == "OK") {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			} else {
				finalMsg !== "" && finalMsg.indexOf(message) !== -1 && message !== undefined
					? (localResultMsg += "")
					: (localResultMsg = message);
			}
		}
		finalMsg += localResultMsg;
	};
	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
		});
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		if (finalMsg !== "") {
			alert(finalMsg);
		}
		dataGrid.current.instance.getDataSource().store().resetResponseMsg();
		dataGrid.current.instance.refresh();
		setMode("View");
	};

	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		//update mode label with js when first editing.changes event fired
		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);

	if (permission && !permission.read) {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<DataGrid
				elementAttr={{
					id: "partMappingGridContainer"
				}}
				editorOptions={dataGridConfiguration}
				ref={dataGrid}
				dataSource={customDataSource}
				showBorders={true}
				allowColumnResizing={true}
				columnAutoWidth={true}
				columnResizingMode='nextColumn'
				columnMinWidth={100}
				remoteOperations={true}
				onEditorPreparing={onEditorPreparing}
				onEditCanceling={onEditCanceling}
				onEditCanceled={onEditCanceled}
				onContentReady={getTotalCount}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onSaving={onSaving}
				onSaved={onSaved}
				onOptionChanged={onOptionChanged}>
				<Toolbar>
					<Item location='after'>
						<div className={"total-label"}>
							<span>Total Records: </span>
							<label>{totalCount}</label>
						</div>
					</Item>
					<Item location='after'>
						<div className={"total-label"}>
							<span> Mode: </span>
							<label id='modeLabel'>View</label>
						</div>
					</Item>
					<Item>
						<Button
							icon={IconFilter}
							className='dx-toolbar-item'
							hint='Clean Filters'
							onClick={clearFilters}
						/>
					</Item>
					<Item name='addRowButton' />
					<Item name='revertButton' />
					<Item name='saveButton' />

					<Item name='searchPanel' />
				</Toolbar>
				<ColumnFixing enabled={true} />
				<FilterRow visible={true} applyFilter={{key: "auto", name: "Immediately"}} />
				<SearchPanel visible={true} width={240} placeholder='Search...' />
				<Editing
					mode='batch'
					allowUpdating={false}
					allowAdding={permission ? permission.create : false}
					allowDeleting={permission ? permission.delete : false}
					selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
					startEditAction={dataGridSettings.startEditAction}
					texts={{saveAllChanges: "", cancelAllChanges: ""}}
				/>
				<Export enabled={true} />
				<Column
					dataField='id'
					caption='No'
					visible={false}
					cssClass='cell-bold'
					allowEditing={false}
					allowFiltering={false}
				/>
				<Column
					dataField='sourceShortPartNo'
					caption='Source Short Part No'
					editorOptions={{maxLength: 4}}
					setCellValue={sourceSelected}
					cssClass='cell-bold'>
					<RequiredRule />
					<Lookup
						dataSource={sourceShortPartNo}
						displayExpr='sourceShortPartNo'
						valueExpr='sourceShortPartNo'
					/>
				</Column>
				<Column
					dataField='sourceSewsPartNo'
					caption='Source SEWS Part No.'
					cssClass='cell-bold'
					allowEditing={true}
					editorOptions={{maxLength: 40}}>
					<RequiredRule />
				</Column>
				<Column
					dataField='sourceSewsDesignLevel'
					caption='Source SEWS Design Level'
					cssClass='cell-bold'
					allowEditing={true}
					editorOptions={{maxLength: 40}}>
					<RequiredRule />
				</Column>
				<Column
					dataField='destShortPartNo'
					caption='Dest. Short Part No.'
					cssClass='cell-bold'
					allowEditing={true}
					setCellValue={destinationSelected}>
					<RequiredRule />
					<Lookup
						dataSource={destSEWSPartNo}
						displayExpr='destShortPartNo'
						valueExpr='destShortPartNo'
					/>
				</Column>
				<Column
					dataField='destSewsPartNo'
					caption='Dest. SEWS Part No.'
					cssClass='cell-bold'
					allowEditing={false}></Column>
				<Column
					dataField='destSewsDesignLevel'
					caption='Dest. SEWS Design Level'
					cssClass='cell-bold'
					allowEditing={false}></Column>
				<Column
					dataField='destLabelFormat'
					caption='Dest. Label Format'
					cssClass='cell-bold'
					editorOptions={{maxLength: 6}}
					allowEditing={false}
				/>
				<Paging defaultPageSize={10} defaultPageIndex={0} />
				<Pager
					visible={true}
					allowedPageSizes={allowedPageSizes}
					displayMode='full'
					showPageSizeSelector={dataGridSettings.showPageSizeSelector}
					showInfo={dataGridSettings.showInfo}
					showNavigationButtons={dataGridSettings.showNavButtons}
				/>
				<Scrolling mode={dataGridSettings.infiniteScroll} rowRenderingMode='virtual'/>
			</DataGrid>
		</React.Fragment>
	);
};
