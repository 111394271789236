import React, {useState, useCallback} from "react";
import DataGrid, {
	Column,
	Paging,
	Scrolling,
	Selection,
	FilterRow,
} from "devextreme-react/data-grid";
import DropDownBox from "devextreme-react/drop-down-box";
const dropDownOptions = {width: 400};

const SEWSDropDownBoxComponent = (props) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([props.data.value]);
	const [isDropDownOpened, setDropDownOpened] = useState(false);
	const boxOptionChanged = useCallback((e) => {
		if (e.name === "opened") {
			setDropDownOpened(e.value);
		}
	}, []);
	const contentRender = useCallback(() => {
		const onSelectionChanged = (args) => {
			sessionStorage.setItem(
				"FORDSewsPartNo",
				JSON.stringify(args.selectedRowsData[0])
			);
			setSelectedRowKeys(args.selectedRowKeys);
			setDropDownOpened(false);
			props.data.setValue(args.selectedRowsData[0].sewsPartNo);
		};

		return (
			<DataGrid
				dataSource={props.data.column.lookup.dataSource}
				height={300}
				hoverStateEnabled={true}
				onSelectionChanged={onSelectionChanged}>
				<Column dataField="sewsPartNo" />
				<Column dataField="sewsDesignLevel" />
				<Column dataField="customerId" />
				<Paging enabled={true} defaultPageSize={10} />
				<Scrolling mode="virtual" />
				<Selection mode="single" />
				<FilterRow
					visible={true}
					applyFilter={{key: "auto", name: "Immediately"}}
				/>
			</DataGrid>
		);
	}, [props.data, selectedRowKeys]);
	return (
		<DropDownBox
			value={selectedRowKeys[0]}
			opened={isDropDownOpened}
			valueExpr="sewsPartNo"
			dropDownOptions={dropDownOptions}
			deferRendering={false}
			dataSource={props.data.column.lookup.dataSource}
			displayExpr="sewsPartNo"
			onOptionChanged={boxOptionChanged}
			contentRender={contentRender}></DropDownBox>
	);
};
export default SEWSDropDownBoxComponent;
