import React, {useRef, useState, useCallback} from "react";
import store from "../../components/hooks/store";
import appInfo from "../../app-info";
import {setMode, getMode} from "../configuration/helpers/globalFunctions";
import DataGrid, {
	Column,
	Editing,
	Paging,
	FilterRow,
	SearchPanel,
	Toolbar,
	Pager,
	Scrolling,
} from "devextreme-react/data-grid";
import {CheckBox} from "devextreme-react/check-box";
import {confirm, alert} from "devextreme/ui/dialog";
import Button from "devextreme-react/button";
import {Redirect} from "react-router-dom";
import {useAuth} from "../../contexts/auth";
import Box, {Item} from "devextreme-react/box";
import IconFilter from "../../components/filter.svg";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import ArrayStore from "devextreme/data/array_store";

export default () => {
	const {user} = useAuth();
	const customDataSource = store({
		url: `${appInfo.authURL}authentication/EnableServerName`,
	});
	let isAdmin = user.data.userData.groupCode;
	let canceled = false;
	let finalMsg = "";
	const [totalCount, setTotalCount] = useState(0);
	const dataGrid = useRef(null);
	const allowedPageSizes = [10, 30, 50, "all"];
	const [dataGridSettings, setdataGridSettings] = useState({
		selectTextOnEditStart: true,
		startEditAction: "click",
		showHeaderFilter: true,
		showPageSizeSelector: true,
		showFilterRow: true,
		showInfo: true,
		showNavButtons: true,
		infiniteScroll: "standard",
	});

	const onRowUpdating = (e) => {
		e.newData = {...e.oldData, ...e.newData};
	};

	const onSaving = (e) => {
		finalMsg = "";
		e.promise = confirm("Are you sure you want to save?").then((response) => {
			e.cancel = !response;
			canceled = !response;
		});
	};
	const onRowUpdated = (e) => {
		let localResultMsg = "";
		let message = e.data.data.message;
		if (e.data.statusText == "OK") {
			//e.data.status == 200
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		} else {
			finalMsg !== "" && !finalMsg.indexOf(message)
				? (localResultMsg += "")
				: (localResultMsg = message);
		}
		finalMsg += localResultMsg;
	};
	const onSaved = (e) => {
		finalMsg = finalMsg.replace(/,/g, "<br/>");
		alert(finalMsg);
		setMode("View");
	};
	const onEditCanceled = useCallback(() => {
		setMode("View");
	}, []);
	const onEditCanceling = (e) => {
		if (canceled) {
			return;
		} else {
			e.cancel = true;
			confirm(
				"Unsaved data exist. <br/> Are you sure you want to cancel?"
			).then((confirm) => {
				if (confirm) {
					canceled = true;
					e.component.cancelEditData();
					canceled = false;
				}
			});
		}
	};

	const getTotalCount = () => {
		let currentTotal = sessionStorage.getItem("totalCount");
		setTotalCount(currentTotal);
	};

	const clearFilters = () => {
		dataGrid.current.instance.clearFilter();
	};
	const onOptionChanged = useCallback((e) => {
		if (e.fullName !== "editing.changes") {
			return;
		}

		if (getMode() === "View") {
			setMode("Edit");
		}
	}, []);
	if (isAdmin !== "SUPER") {
		return <Redirect to={"/home"} />;
	}

	return (
		<React.Fragment>
			<div id="data-grid-componentCodeMaster" className={"content-block"}>
				<Box direction="row">
					<Item baseSize={400}>
						<h2>Server Status Configuration</h2>
					</Item>
				</Box>
				<DataGrid
					elementAttr={{
						id: "sysParamGridContainer",
					}}
					ref={dataGrid}
					onContentReady={getTotalCount}
					dataSource={customDataSource}
					remoteOperations={true}
					showBorders={true}
					allowColumnResizing={true}
					onOptionChanged={onOptionChanged}
					onEditCanceled={onEditCanceled}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onEditCanceling={onEditCanceling}
					onSaving={onSaving}
					onSaved={onSaved}>
					<Toolbar>
						<Item location="after">
							<div className={"total-label"}>
								<span>Total Records: </span>
								<label>{totalCount}</label>
							</div>
						</Item>
						<Item location="after">
							<div className={"total-label"}>
								<span> Mode: </span>
								<label id="modeLabel">View</label>
							</div>
						</Item>
						<Item>
							<Button
								icon={IconFilter}
								className="send dx-toolbar-item"
								hint="Clean Filters"
								onClick={clearFilters}
							/>
						</Item>
						<Item name="addRowButton" />
						<Item name="revertButton" />
						<Item name="saveButton" />
						<Item name="searchPanel" />
					</Toolbar>
					<SearchPanel visible={true} width={240} placeholder="Search..." />
					<FilterRow visible={true} />
					<Editing
						mode="row"
						allowUpdating={true}
						selectTextOnEditStart={dataGridSettings.selectTextOnEditStart}
						startEditAction={dataGridSettings.startEditAction}
						texts={{saveAllChanges: "", cancelAllChanges: ""}}
					/>
					<Column
						dataField="zone"
						caption="Zone"
						allowEditing={false}
						dataType="string"
						cssClass="cell-bold"
					/>
					<Column dataField="name" caption="Server Name" allowEditing={false} />
					<Column
						dataField="enabled"
						caption="Server Status"
						allowSorting={false}>
						<CheckBox></CheckBox>
					</Column>
					<Paging defaultPageSize={10} />
					<Pager
						visible={true}
						allowedPageSizes={allowedPageSizes}
						displayMode="full"
						showPageSizeSelector={dataGridSettings.showPageSizeSelector}
						showInfo={dataGridSettings.showInfo}
						showNavigationButtons={dataGridSettings.showNavButtons}
					/>
					<Scrolling
						mode={dataGridSettings.infiniteScroll}
						rowRenderingMode="virtual"
					/>
				</DataGrid>
			</div>
		</React.Fragment>
	);
};
